import { Badge } from "antd";
import { saveAs } from "file-saver";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { app_loading } from "../../actions/auth";
import { headerTitles, SideMenu } from "../../actions/masterdata/masterdata";
import { get_all_payments } from "../../actions/z_accounts";
import credit from "../../assets/icons/credit.png";
import debit from "../../assets/icons/debit.png";
import { gridApis } from "../../config/GridApis";
import { currencyMapper } from "../../config/helper";
import usePayAxios from "../../config/useAxios";
import { amountFormat } from "../../utils";
import BankShow from "../Common/BankShow";
import Table from "../Common/Table/Table";
import { p_types } from "../Payments/SendMoney/Recipients";
import SendMoney from "../Payments/SendMoney/SendMoney";
import { AccountDisplay } from "./AccountCard";
import TxnDetailModal from "./TxnDetailModal";

// import Base from "antd/lib/typography/Base";

const AccountTransaction = ({ sendMoney, user, txns }) => {
  const dispatch = useDispatch();
  const bankAcc = useSelector(
    (state) => state?.z_accs?.accounts?.data?.data || []
  );
  const [openAddNew, setOpenAddNew] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [txnDetail, setTxnDetail] = useState({});
  const [txnOpen, setTxnOpen] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState("");
  const [filterDetails, setFilterDetails] = useState({
    date_from: null,
    date_to: null,
  });
  const masterHistoryResponse = useSelector(
    (state) => state?.z_accs?.all_payments?.data?.data ?? []
  );
  const totalRecords = useSelector(
    (state) => state?.z_accs?.all_payments?.data?.total_count ?? []
  );
  const location = useLocation();
  const { onCall, loading: pdfLoading } = usePayAxios({
    type: "grid",
    api: gridApis.fetchExcelTransactions,
    method: "get",
  });
  React.useEffect(() => {
    // dispatch(accountTxns(({ page_number: 1 })));
    dispatch(
      headerTitles(
        sendMoney
          ? {
              title: "Send Money",
              description: "Payments Transfer to Recipients Accounts",
            }
          : {
              title: "account_transactions",
              description: "View all your global account transactions",
            }
      )
    );
    if (!sendMoney) {
      setOpenAddNew(false);
    }
    if (location?.pathname) {
      let locationSplit = location?.pathname?.split("/");
      dispatch({
        type: SideMenu,
        payload: {
          firstNav: "/" + locationSplit?.[1],
          secondNav: "/" + locationSplit?.[2],
          showChildren: true,
          childrenMenu: "/" + locationSplit?.[3],
        },
      });
    }

    if (!openAddNew)
      dispatch(
        get_all_payments({
          params: {
            view: user ? undefined : "admin",
          },
          txns,
        })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendMoney, openAddNew, txns]);
  const { t } = useTranslation();

  const columns = [
    {
      title: t("Date and Time"),
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => <div>{moment(text).format("DD, MMM YY, HH:mm")}</div>,
    },
    {
      title: t("txn_id"),
      dataIndex: "transaction_ref_no",
      key: "transaction_ref_no",
      render: (text, record) => (
        <div title={text}>
          {record?.schedule_payment_date && (
            <div className="base_badge">
              <Badge.Ribbon
                text={`Schedule on ${record?.schedule_payment_date}`}
                placement={"start"}
                color="#3f81f4"
              />
            </div>
          )}
          {text?.substr(0, 7)}...{text?.substr(-4)}
        </div>
      ),
    },
    {
      title: t("From / To"),
      dataIndex: "overparty_account_name",
      key: "overparty_account_name",
    },
    {
      title: t("beneficiary_name"),
      dataIndex: "counterparty_account_name",
      key: "counterparty_account_name",
    },
    {
      title: t("beneficiary_account"),
      dataIndex: "counterparty_account_number",
      key: "counterparty_account_number",
      render: (text, rec) => <AccountDisplay showEye accountNumber={text} />,
    },
    {
      title: t("Amount"),
      dataIndex: "amount",
      key: "amount",
      render: (text, rec) => (
        <div>
          {currencyMapper[rec.currency]}
          {amountFormat(text)}
        </div>
      ),
    },
    {
      title: "Payment Method",
      dataIndex: "transfer_mode",
      key: "transfer_mode",
      render: (text) => p_types?.find((ele) => ele.value === text)?.label,
    },
    {
      title: "Txn Type",
      dataIndex: "transaction_type",
      key: "transaction_type",
      render: (text) => (
        <span
          className="i-flex-center"
          style={{
            color: "#333",
            textTransform: "capitalize",
          }}
        >
          <img
            style={{
              transform: "rotate(180deg)",
            }}
            src={text === "credit" ? credit : debit}
            width={10}
          />{" "}
          {text}
        </span>
      ),
    },
    {
      title: "Purpose",
      dataIndex: "purpose_code",
      key: "purpose_code",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => (
        <span className={`status status_${text?.toLowerCase()}`}>{text}</span>
      ),
    },
  ];

  const ben_columns = [
    {
      title: t("Transaction Id"),
      dataIndex: "order_id",
      key: "order_id",
    },
    {
      title: t("beneficiary_name"),
      dataIndex: "payee_name",
      key: "payee_name",
    },
    {
      title: t("Beneficiary account"),
      dataIndex: "payee_account_no",
      key: "payee_account_no",
      render: (text) => <div>XXXX{text?.substr(4)}</div>,
    },
    {
      title: t("Mode of Payment"),
      dataIndex: "mode_of_payment",
      key: "mode_of_payment",
      render: () => "IMPS / RTGS",
    },

    {
      title: t("dateAndTime"),
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => <div>{moment(text).format("DD, MMM YY, HH:mm")}</div>,
    },
    {
      title: t("Amount"),
      dataIndex: "amount",
      key: "amount",
      render: (text, r) => (
        <div>
          {r.currency_code} {text}
        </div>
      ),
    },
    {
      title: t("status"),
      dataIndex: "role_status",
      key: "role_status",
      render: (text, record) => {
        return user ? (
          <div
            className={`status status_${record?.flow_status?.toLowerCase()}`}
          >
            {record?.flow_status?.replace(/_/g, " ")}
          </div>
        ) : (
          <div className={`status status_${text?.toLowerCase()}`}>
            {text?.replace(/_/g, " ")}
          </div>
        );
      },
    },
    {
      title: t("payment_status"),
      dataIndex: "payment_status",
      key: "payment_status",
      render: (text) => (
        <div className={`status status_${text?.toLowerCase()}`}>
          {text?.replace(/_/g, " ")}
        </div>
      ),
    },
    // {
    //   title: "",
    //   key: "action",
    //   render: (_, record) => (
    //     <Space
    //       size="middle"
    //       className="myspace"
    //       onClick={(e) => {
    //         e.stopPropagation();
    //       }}
    //     >
    //       {record.flow_status === "unsubmitted" && (
    //         <Dropdown
    //           overlay={
    //             <Menu>
    //               <Menu.Item onClick={() => onClickEdit(record)}>
    //                 <img src={EditIcon} alt={""} className="action-logo" />
    //                 {t("edit")}
    //               </Menu.Item>
    //               <Menu.Item
    //                 onClick={() => {
    //                   setDeletePaymentModal(true);
    //                   setRecord(record);
    //                 }}
    //               >
    //                 <img
    //                   src={DeleteIcon}
    //                   alt={"logo"}
    //                   className="action-logo"
    //                 />{" "}
    //                 {t("delete")}
    //               </Menu.Item>
    //             </Menu>
    //           }
    //         >
    //           <Button
    //             shape="default"
    //             icon={<MoreOutlined />}
    //             style={{ border: "none", color: "#5B87F9" }}
    //           />
    //         </Dropdown>
    //       )}
    //     </Space>
    //   ),
    // },
  ];
  return openAddNew ? (
    <>
      <SendMoney
        setOpenAddNew={setOpenAddNew}
        cb={() => {
          dispatch(
            get_all_payments({
              params: {
                view: user ? undefined : "admin",
              },
              txns,
            })
          );
        }}
      />
    </>
  ) : (
    <>
      <BankShow
        noBack={true}
        addBtn={sendMoney}
        setOpenAddNew={setOpenAddNew}
        onDownload={(val) => {
          if (val === "excel") {
            dispatch(app_loading(true));
            onCall({})
              .then(async (res) => {
                if (res?.data?.excelLink) {
                  const blob = await fetch(res?.data?.excelLink).then(
                    (res) => res.blob()
                  );
                  saveAs(blob, res?.data?.excelFileName);
                }
                dispatch(app_loading(false));
              })
              .catch(() => {
                dispatch(app_loading(false));
              });
          }
        }}
        pdfLoading={pdfLoading}
        noPdf={true}
        text={"Send Money"}
        callAction={(acc, s_date, e_date) => {
          if (!acc) {
            setSelectedAccount("");
            setFilterDetails({
              date_from: null,
              date_to: null,
            });
            dispatch(
              get_all_payments({
                params: {
                  view: user ? undefined : "admin",
                },
                txns,
              })
            );
            return;
          }
          if (bankAcc?.find((ele) => ele.id === acc)?.account_name)
            setSelectedAccount(
              `${bankAcc?.find((ele) => ele.id === acc)?.account_name}- ${
                bankAcc?.find((ele) => ele.id === acc)?.account_number
              }`
            );
          else setSelectedAccount("");
          dispatch(
            get_all_payments({
              params: {
                account_id: acc,
                view: user ? undefined : "admin",
              },
              filters: {
                created_at: {
                  start_date: s_date,
                  end_date: e_date,
                },
              },
              txns,
            })
          );
          setFilterDetails({
            date_from: s_date,
            date_to: e_date,
          });
        }}
        account={selectedAccount}
        date={
          filterDetails?.date_from && filterDetails?.date_to
            ? `${moment(filterDetails?.date_from).format(
                "MMM DD,YY"
              )} - ${moment(filterDetails?.date_to).format("MMM DD,YY")}`
            : ``
        }
        handleSearch={(search_key) => {
          setSearch(search_key);
          setPage(1);
          dispatch(
            get_all_payments({
              search_key,
              page_number: 1,
              txns,
            })
          );
        }}
      />
      <br />
      <TxnDetailModal
        open={txnOpen}
        txnDetail={txnDetail}
        onClose={() => {
          setTxnDetail({});
          setTxnOpen(false);
        }}
      />
      <Table
        rowKey="id"
        columns={txns ? columns : ben_columns}
        data={Array.isArray(masterHistoryResponse) ? masterHistoryResponse : []}
        isTopFilter={false}
        className="tableStyled"
        topFilters={[
          { label: t("all_trans"), action: () => {} },
          { label: t("advance_search"), action: () => {} },
        ]}
        scroll={{
          x: 1600,
        }}
        handleRowClick={(_e, rec) => {
          setTxnDetail(rec);
          setTxnOpen(true);
        }}
        pagination={{
          total: totalRecords || 0,
          current: page,
          onChange: (pagee) => {
            dispatch(
              get_all_payments({
                params: {
                  view: user ? undefined : "admin",
                  page_number: pagee,
                },
                txns,
                search_key: search ? search : undefined,
              })
            );

            setPage(pagee);
          },
        }}
      />
    </>
  );
};

export default AccountTransaction;
