import { Modal } from "antd";
import React, { useEffect, useState, useCallback } from "react";
import time from "../../assets/icons/time.png";
import session_time from "../../assets/icons/session_time.png";
import { logout } from "../../actions/auth";
import { useDispatch } from "react-redux";
const IdleLogout = () => {
  const [isIdle, setIsIdle] = useState(false);
  const [modal, setModal] = useState(false);
  const [timer, setTimer] = useState(30);
  const [lastActivityTime, setLastActivityTime] = useState(Date.now());
  const timeout = 15 * 60 * 1000; // 5 minutes in milliseconds
  const dispatch = useDispatch();
  const handleUserActivity = useCallback(() => {
    if (modal) {
      return;
    }
    setLastActivityTime(Date.now());
    setIsIdle(false);
  }, [modal]);

  const handleLogout = () => {
    dispatch(logout());
    setModal(false);
  };

  useEffect(() => {
    // Reset activity time on user interaction
    const activityEvents = ["mousemove", "keydown", "click", "scroll"];
    activityEvents.forEach((event) =>
      window.addEventListener(event, handleUserActivity)
    );

    return () => {
      activityEvents.forEach((event) =>
        window.removeEventListener(event, handleUserActivity)
      );
    };
  }, [handleUserActivity]);

  useEffect(() => {
    const checkIdle = () => {
      const token = localStorage.getItem("token");
      if (!token) {
        return;
      }
      if (Date.now() - lastActivityTime >= timeout - 30 * 1000) {
        setTimer((b) => (b > 0 ? b - 1 : 0));
        if (!modal) setModal(true);
      }
      if (Date.now() - lastActivityTime >= timeout) {
        setIsIdle(true);
      }
    };

    const interval = setInterval(checkIdle, 1000); // Check every second
    return () => clearInterval(interval);
  }, [lastActivityTime, timeout]);

  useEffect(() => {
    if (isIdle) {
      handleLogout();
    }
  }, [isIdle]);

  return (
    <Modal
      visible={modal}
      title={
        <div className="flex-title">
          <img src={time} width={18} /> Session Timeout
        </div>
      }
      onCancel={() => {
        setModal(false);
      }}
      footer={[]}
    >
      <div className="session-timeout">
        <div className="content">
          <div>
            <label>Your session is about to expire!</label>
            <span>
              You will be logged out in <b>{timer}s</b>
            </span>
            <span>Do you want to stay signed in?</span>
          </div>
          <div>
            <img src={session_time} />
          </div>
        </div>
        <div className="action-btns">
          <button
            className="back-btn"
            onClick={() => {
              handleUserActivity();
              setModal(false);
            }}
          >
            Keep me signed in
          </button>
          <button
            className="send-btn"
            onClick={() => {
              handleLogout();
            }}
          >
            Logout
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default IdleLogout;
