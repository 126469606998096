import {
  DownOutlined,
  EyeOutlined
} from "@ant-design/icons";
import React from "react";

import { Button, Col, Dropdown, Input, Menu, message, Row, Space, Steps, Image } from "antd";
import moment from "moment";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Payments from "../../PDFTemplates/Payment";
import PaymentReceived from "../../PDFTemplates/PaymentReceived";
import PaymentReceivedTwo from "../../PDFTemplates/PaymentReceivedTwo";
import {
  CreateBillingComment,
  createBillingComment,
  FetchBillingDetailsById,
  fetchBillingDetailsById
} from "../../../actions/bills";
import Close from "../../../assets/icons/close.png";
import Comment from "../../../assets/icons/comment.png";
import History from "../../../assets/icons/time_icon.png";
import { apis } from "../../../config/APIs";
import useGenPdf from "../../../config/useGenPdf";
import { amountFormat, showFile, stringCapitalize } from "../../../utils";
import NoCommentsComponent from "../../Common/NoComentsComponent";
import NodataComponent from "../../Common/NodataComponent";
import RecordPayment from "../../Payments/RecordPayment";
import BillData from "../Bills/BillData";
import usePayAxios from "../../../config/useAxios";
import PaymentModeDisplayFields from "../../Payments/PaymentModeFieldsDisplay";
import { companyProfile } from "../../../actions/companySettings";
import { defaultTemplate, oldTemplate } from "../../../actions/Templates";
import BaseCurrency from "../../Common/BaseCurrency";


const ViewPaidBills = ({ hasWriteAccess, type, module }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const [invoiceInfo, setInvoiceInfo] = useState({});
  const [rdPayment, setRdPayment] = useState(false);
  const [tab, setTab] = useState("details");
  const inputComment = useRef(null);
  const [input, setInput] = useState("");
  const [file, setFile] = useState([]);
  const [url, setUrl] = useState("");
  const [visible, setVisible] = useState(false);
 
  const { onCall: sendEmail } = usePayAxios({ api: apis.sendEmail, method: "post" });
  const companyProfileResponse = useSelector(state => state.companySetting.companyProfile || {});
  const { data: { response: { company = {} } = {} } = {} } = companyProfileResponse
  const defaultRes = useSelector(state => state.templates.defaultTemplate.data?.data);
  const oldRes = useSelector(state => state.templates.oldTemplate?.data?.data);

  const company_id = localStorage.getItem('company_id')

  React.useEffect(() => {
    if (company_id) {
      dispatch(companyProfile({ id: company_id }));

    }
    // eslint-disable-next-line 
  }, []);

  const TemplateID = invoiceInfo?.template_id;

  React.useEffect(() => {
    if (TemplateID) {
      dispatch(oldTemplate({ module_type: module, TemplateID }));
    } else {
      dispatch(defaultTemplate({ module_type: module }));
    }
  }, [TemplateID])
  const handleSendEmail = () => {
    sendEmail({
      params: {
        module_type: "BILL",
        type: "payment",
      },
      data: {
        order_id: location.state?.bill_id,
        to: invoiceInfo?.beneficiaries?.email,
        cc: [],
        subject: "Payment Link",
        is_payment: false
      },
    }).then((data) => {
      message.success(
        <span className="messageText">{`Email sent succesfully`}</span>
      );
    }).catch((err) => {
      message.error(
        <span className="messageText">{err.message}</span>
      );
    })
  }


  const items = [
    {
      key: "1",
      label: t('details'),
    },
    {
      key: "2",
      label: t('history'),
    },
    {
      key: "3",
      label: t('comments'),
    },
  ];
  const menuProps = (
    <Menu>
      {/* <Menu.Item key="1" onClick={() => {
        window.open(pdfUrl, "_blank")
      }}>Download PDF</Menu.Item> */}
      <Menu.Item key="2" onClick={() => hasWriteAccess && handleSendEmail()}>Send Email</Menu.Item>
      {/* <Menu.Item key="3">Export to Excel</Menu.Item>
      <Menu.Item key="4" onClick={() => {

      }}>Print</Menu.Item> */}
    </Menu>
  );
  const paidViewBillResponse = useSelector(
    (state) => state?.bills?.fetchBillingDetailsById || {}
  );
  const createBillingCommentRes = useSelector(
    (state) => state.bills.createBillingComment || {}
  );

  useEffect(() => {
    if (createBillingCommentRes?.data?.error) {
      message.error(
        <span className="messageText">{createBillingCommentRes?.data?.message}</span>
      );
      dispatch({
        type: CreateBillingComment.RESET,
      });
    }

    if (createBillingCommentRes?.data?.error === false) {
      dispatch({
        type: CreateBillingComment.RESET,
      });
      dispatch(fetchBillingDetailsById({ id: location.state?.id, isReceipt: true }));

    }
  }, [createBillingCommentRes]);

  async function getShowData() {
    const showData = {
      record_type: "bill_receipt",
      record_id: location.state.id,
    };

    try {
      const data = await showFile(showData);
      setFile(data.response);
    } catch (err) {
      console.error(err.message);
    }
  }

  useEffect(() => {
    if (location.state?.id) {
      dispatch(fetchBillingDetailsById({ id: location.state?.id, isReceipt: true }));
    }
    getShowData();
    return () => {
      dispatch({
        type: FetchBillingDetailsById.RESET
      })
    }
  }, []);


  useMemo(() => {
    if (paidViewBillResponse?.success && !paidViewBillResponse?.data?.error) {
      setInvoiceInfo(paidViewBillResponse.data.data);
    }
  }, [paidViewBillResponse]);

  const handleAddComment = (event) => {

    if (input && location?.state?.id) {
      dispatch(
        createBillingComment({
          commentable_id: location?.state?.id,
          body: input,
          commentable_type: "receipt",
        })
      );
      setInput("");
    } else {
      message.error(<span className="messageText">{t('comments_required')}</span>);
    }
  };


  // const related = [
  //   {
  //     step: "Submitted to",
  //     name: invoiceInfo?.submitted_to
  //       ? invoiceInfo?.submitted_to[0]?.name
  //       : null,
  //     email: invoiceInfo?.submitted_to
  //       ? invoiceInfo?.submitted_to[0]?.email
  //       : null,
  //     date: moment(invoiceInfo?.submitted_date).format("DD/MM/YYYY"),
  //     role: invoiceInfo?.submitted_to?.[0]?.role_name,
  //   },
  // ];

  const getTitle = (type) => {
    switch (type) {
      case 'SALES_ORDER':
        return 'Sales Order';
      case 'ESTIMATE':
        return 'ESTIMATE';
      case 'INVOICE':
        return 'INVOICE';
      case 'BILL':
      case 'BILL_RECEIPT':
        return 'BILL';
      case 'PURCHASE_ORDER':
        return 'PURCHASE ORDER';
      default:
        return 'Credit Note';
    }
  };

  const getNoteTitle = (type) => {
    switch (type) {
      case 'SALES_ORDER':
        return 'Sales Order#';
      case 'ESTIMATE':
        return 'Estimate#';
      case 'INVOICE':
        return 'Invoice#';
      case 'BILL':
      case 'BILL_RECEIPT':
        return 'Bill#';
      case 'PURCHASE_ORDER':
        return 'Purchase Order#';
      default:
        return 'Credit Note#';
    }
  };

  const getNumTitle = (type) => {
    switch (type) {
      case 'SALES_ORDER':
        return 'Sales No';
      case 'ESTIMATE':
        return 'Estimate No';
      case 'INVOICE':
        return 'Invoice No';
      case 'BILL':
      case 'BILL_RECEIPT':
        return 'Bill No';
      case 'PURCHASE_ORDER':
        return 'PO No';
      default:
        return 'Invoice No';
    }
  };

  const getDateTitle = (type) => {
    switch (type) {
      case 'SALES_ORDER':
      case 'ESTIMATE':
      case 'INVOICE':
      case 'BILL':
      case 'BILL_RECEIPT':
      case 'PURCHASE_ORDER':
        return 'Date';
      default:
        return 'Invoice Date';
    }
  };


  const getInvoiceData = (type, invoiceInfo) => {
    const commonProperties = {
      CreditNo: invoiceInfo?.invoice_no || invoiceInfo?.billing_no || invoiceInfo?.credit_debit_note_number,
      balanceAmount: amountFormat(invoiceInfo?.due_balance_amount, invoiceInfo?.currency_info?.decimal_scale),
      creditNoteDate: invoiceInfo?.date,
      invoiceNo: invoiceInfo?.invoice_no || invoiceInfo?.billing_no || invoiceInfo?.invoice_ref_number,
      invoiceDate: invoiceInfo?.invoice_date || invoiceInfo?.billing_date,
      SubTotal: invoiceInfo?.sub_total_amount,
      totalDiscount: amountFormat(invoiceInfo?.total_discount, invoiceInfo?.currency_info?.decimal_scale),
      totalTax: amountFormat(invoiceInfo?.total_tax_amount, invoiceInfo?.currency_info?.decimal_scale),
      totalAmount: amountFormat(invoiceInfo?.total_amount, invoiceInfo?.currency_info?.decimal_scale),
      taxRates: getTaxRates(type === 'SALES_ORDER' ? invoiceInfo?.invoice_items : invoiceInfo?.billing_items),
      total_tax: invoiceInfo?.total_tax_amount,
      total_amount: invoiceInfo?.total_amount,
      lineItems: type === 'SALES_ORDER' ? invoiceInfo?.invoice_items : invoiceInfo?.billing_items,
      tdsAmount: invoiceInfo?.tds_amount,
      title: getTitle(type),
      noteTitle: getNoteTitle(type),
      balanceTitle: 'Balance Due',
      numTitle: getNumTitle(type),
      dateTitle: getDateTitle(type),
      gst: invoiceInfo?.company?.gst_no,
      address: invoiceInfo?.company?.address,
      terms: invoiceInfo?.invoice_payment_terms?.term_name || invoiceInfo?.vendor_payment_terms?.term_name,
      poDue: invoiceInfo?.po_due_date || invoiceInfo?.due_date,
      companyName: invoiceInfo?.company?.name,
    };

    return {
      ...commonProperties,
    };
  };

  // Then use the function in your component:
  const invoiceData = getInvoiceData(type, invoiceInfo, company);
  const subTotalAmount = amountFormat(parseFloat(invoiceData?.total_amount) - parseFloat(invoiceData?.total_tax), invoiceInfo?.currency_info?.decimal_scale);
  const TDSAmt = amountFormat(parseFloat(invoiceData?.tdsAmount), invoiceInfo?.currency_info?.decimal_scale);
  const subTds = Number(parseFloat(invoiceData?.total_amount) - parseFloat(invoiceData?.total_tax)) + Number(invoiceData?.tdsAmount);

  const companyAddr = company?.address;
  const companyState = company?.state;
  const companyCity = company?.city;
  const companyCountry = company?.country;
  const companyPin = company?.pincode;

  const billingAddressCity = invoiceInfo?.beneficiary_billing_addresses?.[0]?.city;
  const billingAddressState = invoiceInfo?.beneficiary_billing_addresses?.[0]?.state;
  const billingAddresszip = invoiceInfo?.beneficiary_billing_addresses?.[0]?.zipcode;
  const billingCountry = invoiceInfo?.beneficiary_billing_addresses?.[0]?.country;
  const billPhone = invoiceInfo?.beneficiary_billing_addresses?.[0]?.phone;
  const billAddress = invoiceInfo?.beneficiary_billing_addresses?.[0]?.address;
  const benficiaryGST = invoiceInfo?.beneficiaries?.gstin
  const bankName = invoiceInfo?.beneficiary_bank_accounts?.[0]?.bank_name;
  const ifsc = invoiceInfo?.beneficiary_bank_accounts?.[0]?.ifsc_code;
  const branch = invoiceInfo?.beneficiary_bank_accounts?.[0]?.branch;
  const bankAccount = invoiceInfo?.beneficiary_bank_accounts?.[0]?.account_number;
  const benefiName = invoiceInfo?.beneficiaries?.beneficiary_name
  const benifiEmail = invoiceInfo?.beneficiaries?.email
  const benifiCompany = invoiceInfo?.beneficiaries?.company_name
  const benifiContact = invoiceInfo?.beneficiaries?.primary_contact

  function calculatePercentage(total, taxPrice) {
    let p = taxPrice / total;
    return p * 100;
  }


  function getTaxRates(items) {
    const taxRates = {}
    if (!items?.length) {
      items = []
    }

    for (let i = 0; i < items?.length; i++) {
      let per = calculatePercentage(parseFloat(items[i].total_amount), parseFloat(items[i].tax_amount))
      if (per > 0)
        if (taxRates[per]) {
          taxRates[per] = amountFormat(taxRates[per] + parseFloat(items[i].tax_amount), invoiceInfo?.currency_info?.decimal_scale)

        } else {
          taxRates[per] = amountFormat(parseFloat(items[i].tax_amount), invoiceInfo?.currency_info?.decimal_scale)
        }
    }

    return taxRates;
  }


  const templateComponents = {

    template_06: <PaymentReceivedTwo
      invoiceNo={invoiceData.invoiceNo}
      lineItems={invoiceData.lineItems}
      invoiceDate={invoiceData.invoiceDate}
      currency={invoiceInfo?.currency}
      TDSAmt={TDSAmt}
      type={type}
      totalAmount={invoiceData.totAmt}
      subTotal={invoiceInfo?.tds_amount ? subTds : subTotalAmount}
      discount={invoiceData.totalDiscount}
      taxAmount={invoiceData.totalTax}
      bankName={bankName}
      bankBranch={branch}
      ifsc={ifsc}
      bankAccount={bankAccount}
      primaryEmail={company?.primary_email}
      primaryContact={company?.primary_phone}
      cinNo={benficiaryGST}
      gstNo={company?.gst_no}
      numTitle={invoiceData.numTitle}
      dateTitle={invoiceData.dateTitle}
      benifiContact={benifiContact}
      benifiEmail={benifiEmail}
      benefiCompany={benifiCompany}
      billingAddressCity={billingAddressCity}
      billingAddressState={billingAddressState}
      billingAddresszip={billingAddresszip}
      billPhone={billPhone}
      billAddress={billAddress}
      benefiName={benefiName}
      benficiaryGST={benficiaryGST}
      billingCountry={billingCountry}
      companyName={company?.name}
      logo={company?.logo}
      address={invoiceData.address}
      accountName={company?.account_name}
      accountNumber={company?.account_number}
      bankAccountBranch={company?.bank_branch}
      accountIFSC={company?.account_ifsc}
      companyAddr={companyAddr}
      companyCity={companyCity}
      companyState={companyState}
      companyCountry={companyCountry}
      companyPin={companyPin}
      amountPaid={location?.state?.amountPaid}
      placeOfSupply={location?.state?.placeOfSupply}
      refNo={location?.state?.refNo}
      paymentMode={location?.state?.paymentMode}

    />,
    template_07: <PaymentReceived
      invoiceNo={invoiceData.invoiceNo}
      lineItems={invoiceData.lineItems}
      invoiceDate={invoiceData.invoiceDate}
      TDSAmt={TDSAmt}
      type={type}
      totalAmount={invoiceData.totAmt}
      subTotal={invoiceInfo?.tds_amount ? subTds : subTotalAmount}
      discount={invoiceData.totalDiscount}
      taxAmount={invoiceData.totalTax}
      bankName={bankName}
      bankBranch={branch}
      ifsc={ifsc}
      bankAccount={bankAccount}
      primaryEmail={company?.primary_email}
      primaryContact={company?.primary_phone}
      cinNo={benficiaryGST}
      gstNo={company?.gst_no}
      numTitle={invoiceData.numTitle}
      dateTitle={invoiceData.dateTitle}
      benifiContact={benifiContact}
      benifiEmail={benifiEmail}
      benefiCompany={benifiCompany}
      billingAddressCity={billingAddressCity}
      billingAddressState={billingAddressState}
      billingAddresszip={billingAddresszip}
      billPhone={billPhone}
      billAddress={billAddress}
      benefiName={benefiName}
      benficiaryGST={benficiaryGST}
      billingCountry={billingCountry}
      companyName={company?.name}
      logo={company?.logo}
      address={invoiceData.address}
      accountName={company?.account_name}
      accountNumber={company?.account_number}
      bankAccountBranch={company?.bank_branch}
      accountIFSC={company?.account_ifsc}
      companyAddr={companyAddr}
      companyCity={companyCity}
      companyState={companyState}
      companyCountry={companyCountry}
      refNo={location?.state?.refNo}
      companyPin={companyPin}
      placeOfSupply={location?.state?.placeOfSupply}
      amountPaid={location?.state?.amountPaid != null ? location?.state?.amountPaid : 0}
      currency={invoiceInfo?.currency}
    />,
    template_08: <Payments
      invoiceNo={invoiceData.invoiceNo}
      lineItems={invoiceData.lineItems}
      invoiceDate={invoiceData.invoiceDate}
      TDSAmt={TDSAmt}
      type={type}
      totalAmount={invoiceData.totAmt}
      subTotal={invoiceInfo?.tds_amount ? subTds : subTotalAmount}
      discount={invoiceData.totalDiscount}
      taxAmount={invoiceData.totalTax}
      bankName={bankName}
      bankBranch={branch}
      ifsc={ifsc}
      bankAccount={bankAccount}
      primaryEmail={company?.primary_email}
      primaryContact={company?.primary_phone}
      cinNo={benficiaryGST}
      gstNo={company?.gst_no}
      numTitle={invoiceData.numTitle}
      dateTitle={invoiceData.dateTitle}
      benifiContact={benifiContact}
      benifiEmail={benifiEmail}
      benefiCompany={benifiCompany}
      billingAddressCity={billingAddressCity}
      billingAddressState={billingAddressState}
      billingAddresszip={billingAddresszip}
      billPhone={billPhone}
      billAddress={billAddress}
      benefiName={benefiName}
      benficiaryGST={benficiaryGST}
      billingCountry={billingCountry}
      companyName={company?.name}
      companyAddr={companyAddr}
      companyCity={companyCity}
      companyState={companyState}
      companyCountry={companyCountry}
      companyPin={companyPin}
      logo={company?.logo}
      address={invoiceData.address}
      accountName={company?.account_name}
      accountNumber={company?.account_number}
      bankAccountBranch={company?.bank_branch}
      accountIFSC={company?.account_ifsc}
      currency={invoiceInfo?.currency}
    />

  };

  const renderTemplate = (template) => {
    const Template = templateComponents[template];
    switch (template) {

      case 'template_06':
        return Template ? Template : <p>Select</p>;
      case 'template_07':
        return Template ? Template : <p>Select</p>;
      case 'template_08':
        return Template ? Template : <p>Select</p>;


      default:
        return <p>Select</p>;
    }
  };


  const renderedTemplate = renderTemplate(TemplateID ? oldRes?.template_name : defaultRes?.[0]?.template_name);



  return (
    <>
      <RecordPayment
        open={rdPayment && hasWriteAccess}
        id={invoiceInfo?.id}
        invoiceInfo={invoiceInfo}
        amount={invoiceInfo?.due_balance_amount}
        onClose={() => {
          setRdPayment(false);
        }}
        file_type={"bill_receipt"}
      />
      <div className="report-btns">
        {/* <Dropdown overlay={menuProps}>
          <Button className="pp-secondary-button">
            <Space>
              {t('more')}
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown> */}
        <span
          className="close cursor"
          onClick={() => {
            history.push(location.state?.from);
          }}
        >
          <img src={Close} width={20} alt={"close"} />
        </span>
      </div>
      <Row
        wrap={false}
        className="new-report-detail"
        style={{ flexGrow: 1, display: "flex" }}
      >
        <Col
          flex="auto"
          style={{ width: "100%", display: "flex", flexGrow: "1" }}
        >
          <div style={{ flexGrow: 1 }}>
            <Row>
              <Col span={24}>
                <div className="flex-between">
                  <div className="report-head">
                    <div className="title-sec">
                      <h3>{t('bill_hash')} {invoiceInfo.billing_no}</h3>
                      <span
                        className={`status status_${invoiceInfo?.payment_status?.toLowerCase()}`}
                      >
                        {invoiceInfo?.payment_status?.replace(/_/g, " ")}
                      </span>
                    </div>
                    <p className="duration">
                      {t('creation_date')}:{" "}
                      {moment(invoiceInfo.created_at).format("DD/MM/YYYY")}
                    </p>
                    <p>
                      <strong>
                        {t('created_byy')} {invoiceInfo.created_by?.name}{" "}
                        <span
                          style={{
                            fontSize: "0.9rem",
                            fontWeight: "normal",
                            color: "#1C2B36",
                            opacity: "0.51",
                          }}
                        >
                          ({invoiceInfo.created_by?.email})
                        </span>
                      </strong>
                    </p>
                  </div>
                </div>
              </Col>

              <Col span={12}>
                {/* {pdfUrl && <object
                  data={`${pdfUrl}`}
                  style={{
                    margin: "0px 10px",
                    height: "100vh",
                    width: "96%",
                  }}
                >
                  <param name="view" value={"150%"} />
                </object>} */}
                {renderedTemplate}
              </Col>
              <Col
                span={12}
                style={{
                  paddingRight: "10px",
                }}
              >
                <div>
                  <div className="tabs-sec mb1">
                    <ul>
                      {items.map((ele) => (
                        <li
                          onClick={() => setTab(ele.label.toLowerCase())}
                          className={`${tab.toLowerCase() === ele.label.toLowerCase()
                            ? "active"
                            : ""
                            }`}
                        >
                          {ele.label}
                        </li>
                      ))}
                    </ul>
                  </div>

                  {tab === "details" ? (
                    <div>
                      <div
                        style={{
                          backgroundColor: "#F7F7F7",
                          border: "1px solid #EDF0F5",
                          borderRadius: "9px",
                          display: "flex",
                          flexDirection: "column",
                          padding: "25px 36px",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "1rem",
                            lineHeight: "20px",
                            color: "#58666E",
                            fontWeight: "normal",
                          }}
                        >
                          {t('Amount')}
                        </span>

                        <span
                          style={{
                            fontSize: "1.7rem",
                            lineHeight: "34px",
                            color: "#1C2B36",
                            fontWeight: "600",
                          }}
                        >
                          <BaseCurrency symbol_code={invoiceInfo?.currency} /> {amountFormat(invoiceInfo.total_payable_amount, invoiceInfo?.currency_info?.decimal_scale)}
                        </span>

                        <span
                          style={{
                            fontSize: "1rem",
                            lineHeight: "22px",
                            color: "#333333",
                            fontWeight: "normal",
                          }}
                        >
                          {t('due_amount')} - <BaseCurrency symbol_code={invoiceInfo?.currency} /> {amountFormat(invoiceInfo.due_balance_amount, invoiceInfo?.currency_info?.decimal_scale)}
                        </span>
                      </div>

                      <div
                        style={{
                          backgroundColor: "#F7F7F7",
                          border: "1px solid #EDF0F5",
                          borderRadius: "9px",
                          display: "flex",
                          flexDirection: "column",
                          marginTop: "20px",
                          padding: "25px 36px",
                        }}
                      >
                        {/* <BillData
                          style={{
                            fontSize: "1.1rem",
                          }} label="Tx#" value={invoiceInfo.transaction_ref_details} /> */}
                        <BillData
                          style={{
                            fontSize: "1.1rem",
                          }}
                          label={"Bill#"}
                          value={invoiceInfo.billing_no}
                        />
                        <BillData
                          style={{
                            fontSize: "1.1rem",
                          }}
                          label={t('order_purchase')}
                          value={invoiceInfo.ref_purchase_order_no}
                        />
                        <BillData
                          style={{
                            fontSize: "1.1rem",
                          }}
                          label={t('bill_date')}
                          value={moment(invoiceInfo.billing_date).format(
                            "DD/MM/YYYY"
                          )}
                        />
                        <BillData
                          style={{
                            fontSize: "1.1rem",
                          }}
                          label={t('payment_due_date')}
                          value={moment(invoiceInfo.due_date).format(
                            "DD/MM/YYYY"
                          )}
                        />
                        <BillData
                          style={{
                            fontSize: "1.1rem",
                          }}
                          label={t('payment_term')}
                          value={
                            invoiceInfo.payment_terms
                              ? invoiceInfo.payment_terms
                              : invoiceInfo.payment_terms === 0 ? "Custom" : ""
                          }
                        />
                        {/* <BillData label="Tax" value="GST 5 [5%]" /> */}
                        <BillData
                          style={{
                            fontSize: "1.1rem",
                          }}
                          label={t('Amount')}
                          value={<><BaseCurrency symbol_code={invoiceInfo?.currency} /> {`${amountFormat(invoiceInfo.total_amount, invoiceInfo?.currency_info?.decimal_scale)}`}</>}
                        />
                        {/* <BillData label="CGST %" value="Rs.350.00" />
                          <BillData label="SGST %" value="Rs.350.00" /> */}
                        <BillData
                          style={{
                            fontSize: "1.1rem",
                          }}
                          label="TDS %"
                          value={<><BaseCurrency symbol_code={invoiceInfo?.currency} /> {`${amountFormat(invoiceInfo.tds_amount, invoiceInfo?.currency_info?.decimal_scale)}`}</>}
                        />
                        <PaymentModeDisplayFields data={invoiceInfo} />
                      </div>
                      <div
                        style={{
                          backgroundColor: "#F7F7F7",
                          border: "1px solid #EDF0F5",
                          borderRadius: "9px",
                          display: "flex",
                          flexDirection: "column",
                          marginTop: "20px",
                          padding: "25px 36px",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "1.1rem",
                            lineHeight: "22px",
                            color: "#3C4853",
                            marginBottom: "30px",
                            fontWeight: "600",
                          }}
                        >
                          {t('attachments')}
                        </div>

                        <div>
                          {file?.map((element, index) => {
                            return (
                              <>
                                <div>
                                  {index + 1}. {element?.filename} (
                                  <a
                                    href={element?.file}
                                    target="_blank"
                                    style={{ color: "black" }}
                                  >
                                    {t('download')}
                                  </a>
                                  )
                                  {element.filename
                                    && element.filename
                                      ?.includes && (element.filename
                                        ?.includes(".jpg") || element.filename
                                          ?.includes(".png") || element.filename
                                            ?.includes(".jpeg")) && <EyeOutlined color='#333' onClick={() => {
                                              setUrl(element?.file)
                                              setVisible(true)
                                            }} className='ml1' />}
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </div>

                      {/* <div
                        style={{
                          backgroundColor: "#F7F7F7",
                          border: "1px solid #EDF0F5",
                          borderRadius: "9px",
                          display: "flex",
                          flexDirection: "column",
                          marginTop: "1rem",
                          padding: "25px 36px",
                        }}
                      >
                        <Space direction="vertical">
                          {related.map((data) => (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                marginBottom: "30px",
                              }}
                            >
                              <span
                                style={{
                                  color: "#3C4853",
                                  fontSize: "1rem",
                                  lineHeight: "22px",
                                  fontWeight: "bold",
                                }}
                              >
                                {data.step}
                              </span>

                              <span
                                style={{
                                  color: "#3C4853",
                                  fontSize: "1rem",
                                  lineHeight: "28px",
                                  fontWeight: "normal",
                                }}
                              >
                                {data.name}
                              </span>

                              <span
                                style={{
                                  color: "#8B8B8B",
                                  fontSize: "0.95rem",
                                  lineHeight: "23px",
                                  fontWeight: "normal",
                                }}
                              >
                                {data.email}
                              </span>

                              <span
                                style={{
                                  color: "#8B8B8B",
                                  fontSize: "0.95rem",
                                  lineHeight: "23px",
                                  fontWeight: "normal",
                                }}
                              >
                                {data.date}
                              </span>

                              {data.role && (
                                <span
                                  style={{
                                    color: "#3C4853",
                                    fontSize: "0.8rem",
                                    lineHeight: "28px",
                                    fontWeight: "normal",
                                  }}
                                >
                                  {data.role}
                                </span>
                              )}
                            </div>
                          ))}
                        </Space>
                      </div> */}
                    </div>
                  ) : null}
                  {url && <Image
                    width={200}
                    style={{
                      display: 'none',
                    }}
                    src={url}
                    preview={{
                      visible,
                      scaleStep: 0.5,
                      src: url,
                      onVisibleChange: (value) => {
                        setVisible(value);
                      },
                    }}
                  />}
                  {tab === "comments" ? (
                    <>
                      <div
                        className="addComment mb1"
                        style={{ display: "flex" }}
                      >
                        <Input
                          placeholder={t('enter_comment')}
                          ref={inputComment}
                          value={input}
                          onInput={(e) => setInput(e.target.value.trimStart())}
                        />
                        <Button
                          disabled={!hasWriteAccess}
                          key="1"
                          className="formButton ml1"
                          onClick={() => hasWriteAccess && handleAddComment()}
                        >
                          {t('send')}
                        </Button>
                      </div>

                      {invoiceInfo?.comments?.length ? (
                        <>
                          <div className="commentsSection">
                            <Steps
                              direction="vertical"
                              size="small"
                              current={invoiceInfo.comments.length + 1 || 100}
                            >
                              {invoiceInfo.comments &&
                                invoiceInfo.comments.map(
                                  ({ body, created_at, id, name }) => (
                                    <Steps.Step
                                      key={id}
                                      title={body}
                                      description={`on ${moment(created_at).format(
                                        "DD-MMM-YYYY HH:mm:ss"
                                      )} | ${name}`}
                                      icon={
                                        <img
                                          src={Comment}
                                          alt="comment"
                                          width={18}
                                        />
                                      }
                                    />
                                  )
                                )}
                            </Steps>
                          </div>
                        </>
                      ) : (
                        <NoCommentsComponent />
                      )}
                    </>
                  ) : null}

                  {tab === "history" ? (
                    invoiceInfo?.history?.length ? (
                      <div className="commentsSection">
                        <Steps
                          direction="vertical"
                          size="small"
                          current={invoiceInfo.history.length + 1 || 100}
                        >
                          {invoiceInfo.history &&
                            invoiceInfo.history.map(
                              ({ body, created_at, id, curr_status, name }) => (
                                <Steps.Step
                                  key={id}
                                  title={body}
                                  description={
                                    `on ${moment(created_at).format(
                                      "DD MMM YYYY, HH:mm:ss a"
                                    )} | Action: ${stringCapitalize(curr_status).split("_").join(" ")} by ${name}`
                                  }
                                  icon={
                                    <img
                                      src={History}
                                      alt="history"
                                      width={18}
                                    />
                                  }
                                />
                              )
                            )}
                        </Steps>
                      </div>
                    ) : (
                      <NodataComponent noBtns={true} />
                    )
                  ) : null}
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
};
export default ViewPaidBills;
