import { takeLatest } from "redux-saga/effects";
import { gridApis } from "../../config/GridApis";
import { sagaFunctionsGrid } from "../../utils";
import {
  GetDisputes,
  CreateDisputes,
  UpdateDisputes,
  DisputeReasonList,
} from "./disputes";

function createDispute(req) {
  return sagaFunctionsGrid(
    CreateDisputes,
    "post",
    gridApis.createDispute,
    req.payload
  )();
}

function updateDispute(req) {
  return sagaFunctionsGrid(
    UpdateDisputes,
    "post",
    gridApis.updateDispute,
    req.payload
  )();
}

function getAllDisputes(req) {
  return sagaFunctionsGrid(
    GetDisputes,
    "post",
    gridApis.getAllDisputes,
    req.payload
  )();
}

function getDisputeReasonList(req) {
  return sagaFunctionsGrid(
    DisputeReasonList,
    "get",
    gridApis.getDisputeReasonList,
    req.payload
  )();
}

export function* disputes_watcher() {
  yield takeLatest(GetDisputes.REQUEST, getAllDisputes);
  yield takeLatest(DisputeReasonList.REQUEST, getDisputeReasonList);
  yield takeLatest(CreateDisputes.REQUEST, createDispute);
  yield takeLatest(UpdateDisputes.REQUEST, updateDispute);
}
