import { message } from "antd";
import axios from "axios";
import CryptoJS from "crypto-js";
export const APP_BASE_URL = "https://zenus.purpleplumfi.com";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const endpoint = axios.create({
  baseURL: APP_BASE_URL,
});

const algo = process.env.REACT_APP_ENC_ALGO;
const key = process.env.REACT_APP_ENC_KEY;


const decryptAsync = async (text) => {
  const textParts = text.split(":");
  if (textParts.length !== 2) {
    throw new Error("Invalid encryption data format");
  }

  if (!textParts[0] || !textParts[1]) {
    throw new Error(
      "Invalid encryption data: Initialization vector or encrypted text is missing"
    );
  }

  const iv = CryptoJS.enc.Hex.parse(textParts[0]);
  const encryptedText = CryptoJS.enc.Hex.parse(textParts[1]);

  const decrypted = CryptoJS.AES.decrypt(
    { ciphertext: encryptedText },
    CryptoJS.enc.Utf8.parse(key), // Parse key as UTF-8
    {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }
  );

  let decryptedText = decrypted.toString(CryptoJS.enc.Utf8);

  try {
    const parsedValue = JSON.parse(decryptedText);
    if (typeof parsedValue === "object" && parsedValue !== null) {
      decryptedText = parsedValue;
    }
    return decryptedText;
  } catch (e) {
    console.log(e);
    return decryptedText;
  }
};

export async function encryptAsync(text) {
  const iv = CryptoJS.lib.WordArray.random(16); // Generate a random 16-byte IV

  // Define the encryption key and algorithm details
  const key1 = CryptoJS.enc.Utf8.parse(key);

  // Ensure text is a string, converting objects if needed
  if (typeof text === "object" && text !== null) {
    text = JSON.stringify(text);
  } else if (typeof text !== "string") {
    text = String(text);
  }

  // Encrypt the text
  const encrypted = CryptoJS.AES.encrypt(text, key1, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  // Return the IV and encrypted text in hexadecimal format, separated by ":"
  return `${iv.toString(CryptoJS.enc.Hex)}:${encrypted.ciphertext.toString(
    CryptoJS.enc.Hex
  )}`;
}

endpoint.interceptors.request.use(
  async function (config) {
    // Do something before request is sent
    config.headers.Authorization = "Bearer " + localStorage.getItem("token");
    config.headers.deviceType = "web";
    config.cancelToken = source.token;
    if (process.env.REACT_APP_DEVELOPMENT === "true") {
      config.headers["x-api-encrypt"] = false;
    } else {
      if (config.data && Object.keys(config.data).length > 0) {
        encryptAsync(config.data)
          .then((res) => {
            config.data = { data: res };
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

endpoint.interceptors.response.use(
  async function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data

    if (process.env.REACT_APP_DEVELOPMENT !== "true") {
      if (
        typeof response?.data?.data === "string" &&
        response?.data?.error === false
      )
        response.data.data = await decryptAsync(response.data?.data);
    }
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (
      error?.response?.status === 401 ||
      (error?.response?.status === 422 &&
        error?.response.data?.message === "Signature has expired")
    ) {
      source.cancel("Session Timeout");
      message.error(
        <span className="messageText">
          Session Expired. Please login again.
        </span>
      );
      localStorage.clear();
      // window.location.href = "/";
    }
    return Promise.reject(error);
  }
);

export default endpoint;
