import React, { useState } from "react";
import usePayAxios from "../../../config/useAxios";
import { gridApis } from "../../../config/GridApis";
import { message } from "antd";

const EmailInput = ({
  mainData,
  name,
  address,
  bank_location,
  bank_name,
  routing_code,
  account_number,
  cb
}) => {
  const [emails, setEmails] = useState([]);
  const [input, setInput] = useState("");
  const { onCall, loading } = usePayAxios({
    api: gridApis.paymentReceipt,
    method: "post",
    type: "grid",
  });
  // Add email if valid and unique
  const addEmail = (email) => {
    if (validateEmail(email) && !emails.includes(email)) {
      setEmails([...emails, email]);
      setInput("");
    }
  };

  // Remove email
  const removeEmail = (emailToRemove) => {
    setEmails(emails.filter((email) => email !== emailToRemove));
  };

  // Check if input is a valid email
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Handle input change
  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  // Handle key down (e.g., Enter or comma to add email)
  const handleKeyDown = (e) => {
    if (e.key === "Enter" || e.key === "," || e.key === " ") {
      e.preventDefault();
      addEmail(input.trim());
    }
  };

  return (
    <div className="memo detail-row">
      <div className="email-receipt w80">
        <label>Email receipt to</label>
        <div className="email-tags">
          {emails.map((email, index) => (
            <div key={index} className="email-tag">
              {email}
              <span className="remove-btn" onClick={() => removeEmail(email)}>
                ×
              </span>
            </div>
          ))}
        </div>
        <div className="d-flex">
          <input
            type="email"
            className="w100"
            value={input}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            placeholder="Enter email"
          />
          <button
            className="send-btn ml2"
            onClick={() => {
              if (emails.length || validateEmail(input))
                onCall({
                  data: {
                    payment_id: "",
                    recipient_name: name,
                    amount: mainData?.recipient_amount,
                    bank_name,
                    routing_code,
                    account_number,
                    bank_location: bank_location,
                    recipient_address: address,
                    email: emails.length ? emails : [input],
                  },
                })
                  .then((res) => {
                    if (res?.error) {
                      message.error(
                        <span className="messageText">{res?.message}</span>
                      );
                    } else {
                      message.success(
                        <span className="messageText">
                          Email Sent Successfully.
                        </span>
                      );
                      setEmails([]);
                      setInput("");
                      cb?.()
                    }
                  })
                  .catch((_err) => {
                    message.error(
                      <span className="messageText">Failed to Send Email.</span>
                    );
                  });
              else {
                if (!validateEmail(input)) {
                  message.error(
                    <span className="messageText">Enter Valid Email.</span>
                  );
                  return;
                }
                message.error(
                  <span className="messageText">
                    Alteast one email is required.
                  </span>
                );
              }
            }}
            disabled={loading}
          >
            Send
          </button>
        </div>
      </div>
    </div>
  );
};

export default EmailInput;
