import { Menu } from "antd";
import Sider from "antd/lib/layout/Sider";
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, withRouter } from "react-router-dom";
import { LeftNavContext } from "../../LeftNavProvider";
import { Collapsed } from "../../actions/auth";
import AccountSVG from "../../assets/ff_icons/side-nav/AccountSVG";
import AnalyticsSVG, {
  DownSVG,
} from "../../assets/ff_icons/side-nav/AnalyticsSVG";
import CardSVG from "../../assets/ff_icons/side-nav/CardSVG";
import DashboardSVG from "../../assets/ff_icons/side-nav/DashboardSVG";
import PurchasesSVG from "../../assets/ff_icons/side-nav/PurchasesSVG";
import SalesSVG from "../../assets/ff_icons/side-nav/SalesSVG";
import SettingsSVG from "../../assets/ff_icons/side-nav/SettingsSVG";
import TransfersSVG from "../../assets/ff_icons/side-nav/TransfersSVG";
import StatementsSVG from "../../assets/ff_icons/side-nav/statementsSVG";
import { LOGO, SHORT_LOGO } from "../../config/Images";
import {
  RouteConstants,
  hasAccess,
  isAccount,
  isAnalytics,
  isCustomisation,
  isPurchases,
  isSales,
  isSettings,
  isStatements,
  isTransfers,
} from "../../utils";
import useWindowDimensions from "./../useWindowDimensions";
import "./leftNavigation.css";
import TxnSVG from "../../assets/ff_icons/side-nav/TxnSVG";

const createMenuItem = (
  key,
  label,
  description,
  hasAccess,
  iconComponent,
  activeMenu,
  titleLabel,
  divider = false,
  children = []
) => ({
  key,
  label,
  description,
  divider,
  hasAccess,
  titleLabel,
  icon: React.cloneElement(iconComponent, {
    fill:
      activeMenu !== "/sales" &&
      activeMenu !== "/purchases" &&
      activeMenu !== "/transfers" &&
      activeMenu !== "/cards" &&
      key === activeMenu
        ? "#170A33"
        : "#ffffff",
    scale: 0.7,
    opacity: key === activeMenu ? 1 : null,
  }),
  icon2: React.cloneElement(iconComponent, {
    fill: "#fff",
    scale: 0.7,
    opacity: 1,
  }),
  children,
});

const LeftNavigation = () => {
  // const [isModalVisible, setIsModalVisible] = React.useState(false);
  const {
    collapsed,
    setCollapsed,
    activeMenu,
    setActiveMenu,
    activeSubMenu,
    setActiveSubMenu,
    childrenMenu,
    setChildrenMenu,
    showChildren,
    setShowChildren,
    setNavChildren,
    toggle,
    setToggle,
  } = useContext(LeftNavContext);

  const { width } = useWindowDimensions();
  const dispatch = useDispatch();
  const is_admin = useSelector(
    (state) => state?.auth?.localVariables?.is_admin
  );
  const side_menu = useSelector(
    (state) => state?.auth?.localVariables?.side_menu
  );
  const url = window.location.href;
  const history = useHistory();

  React.useEffect(() => {
    const storedMenu = localStorage.getItem("activeMenu");
    const storeActiveSubMenu = localStorage.getItem("activeSubMenu");
    const storeShowChildren = localStorage.getItem("showChildren");
    const storeChildrenMenu = localStorage.getItem("childrenMenu");
    const navChildren = localStorage.getItem("navChildren");
    setActiveMenu(storedMenu ? storedMenu : activeMenu);
    setActiveSubMenu(storeActiveSubMenu);
    setShowChildren(storeShowChildren);
    setChildrenMenu(storeChildrenMenu);
    if (navChildren) {
      setNavChildren(JSON.parse(navChildren));
    }
  }, []);

  React.useEffect(() => {
    localStorage.setItem("activeMenu", activeMenu);
    localStorage.setItem("activeSubMenu", activeSubMenu);
    localStorage.setItem("showChildren", showChildren);
    localStorage.setItem("childrenMenu", childrenMenu);
    if (["/transfers"]?.includes(activeMenu)) {
      setToggle({
        [activeMenu]: true,
      });
    }
  }, [activeMenu, activeSubMenu, showChildren, childrenMenu]);

  // React.useEffect(() => {
  //   if (width <= 1280) {
  //     setCollapsed(true);
  //   } else {
  //     setCollapsed(false);
  //   }
  // }, [width]);

  React.useEffect(() => {
    const url = window.location.href;
    if (url.includes("expenses/")) {
      setActiveMenu("/expenses");
    }
  }, [url]);

  React.useEffect(() => {
    if (side_menu) {
      setActiveMenu(side_menu?.firstNav);
      setActiveSubMenu(side_menu?.secondNav);
      setShowChildren(side_menu?.showChildren);
      setChildrenMenu(side_menu?.childrenMenu);
      const itm = items?.find((ele) => ele.key === side_menu?.firstNav);
      if (itm) {
        setNavChildren(itm.children?.[0]?.children);
      }
    }
  }, [side_menu]);

  const { t } = useTranslation();
  const submenus = {
    // "/expenses": [
    //   {
    //     label: t("Expenses"),
    //     key: "",
    //     hasAccess: hasAccess("expenses", "read") || true,
    //     titleLabel: t("Expenses"),
    //   },
    //   {
    //     label: t("reports"),
    //     key: "/reports",
    //     altAdminKey: "/my-reports",
    //     hasAccess:
    //       hasAccess("expense_reports", "read") ||
    //       hasAccess("reports_approvals", "read") ||
    //       true,
    //     children: [
    //       {
    //         label: t("Awating Approvals"),
    //         key: "",
    //         hasAccess: hasAccess("reports_approvals", "read"),
    //       },
    //       {
    //         hasAccess: hasAccess("expense_reports", "read") || true,
    //         label: t("my_reports"),
    //         key: "/my-reports",
    //       },
    //     ],
    //   },
    //   {
    //     label: t("trips"),
    //     key: "/trips",
    //     altAdminKey: "/my-trips",
    //     hasAccess:
    //       hasAccess("trips", "read") ||
    //       hasAccess("trips_approvals", "read") ||
    //       true,
    //     children: [
    //       {
    //         label: t("Awating Approvals"),
    //         key: "",
    //         hasAccess: hasAccess("trips_approvals", "read"),
    //       },
    //       {
    //         label: t("my_trips"),
    //         hasAccess: hasAccess("trips", "read") || true,
    //         key: "/my-trips",
    //       },
    //     ],
    //   },
    //   {
    //     label: t("advances"),
    //     key: "/advances",
    //     altAdminKey: "/my-advances",
    //     hasAccess:
    //       hasAccess("advances", "read") ||
    //       hasAccess("advances_approvals", "read") ||
    //       true,
    //     children: [
    //       {
    //         label: t("Awating Approvals"),
    //         key: "",
    //         hasAccess: hasAccess("advances_approvals", "read"),
    //       },
    //       {
    //         label: t("my_advances"),
    //         hasAccess: hasAccess("advances", "read") || true,
    //         key: "/my-advances",
    //       },
    //     ],
    //   },
    // ],
    // "/cards": [
    //   {
    //     label: t("Cards"),
    //     key: "",
    //     titleLabel: t("Cards"),
    //     hasAccess:
    //       hasAccess("all_cards", "read") ||
    //       hasAccess("my_cards", "read") ||
    //       true,
    //     altAdminKey: "/my-cards",
    //     children: [
    //       {
    //         label: t("all_cards"),
    //         key: "",
    //         hasAccess: hasAccess("all_cards", "read"),
    //       },
    //       {
    //         label: t("my_cards"),
    //         hasAccess: hasAccess("my_cards", "read") || true,
    //         key: "/my-cards",
    //       },
    //     ],
    //   },
    //   {
    //     label: t("transfer"),
    //     key: "/transfers",
    //     hasAccess:
    //       hasAccess("quick_transfers", "read") ||
    //       hasAccess("bulk_transfers", "read") ||
    //       hasAccess("transfer_logs", "read"),

    //     children: [
    //       {
    //         label: t("quick_transfers"),
    //         key: "",
    //         hasAccess: hasAccess("quick_transfers", "read"),
    //       },
    //       {
    //         label: t("bulk_transfer"),
    //         key: "/bulk-transfers",
    //         hasAccess: hasAccess("bulk_transfers", "read"),
    //       },
    //       {
    //         label: t("transfer_log"),
    //         key: "/transfers-log",
    //         hasAccess: hasAccess("transfer_logs", "read"),
    //       },
    //     ],
    //   },
    //   {
    //     label: t("transactions"),
    //     key: RouteConstants.CARD_TRANSACTIONS,
    //     hasAccess:
    //       hasAccess("all_card_transactions", "read") ||
    //       hasAccess("my_card_transactions", "read") ||
    //       true,
    //     altAdminKey: "/my-transactions",
    //     children: [
    //       {
    //         label: t("all_trans"),
    //         key: "/all-transactions",
    //         hasAccess: hasAccess("all_card_transactions", "read"),
    //       },
    //       {
    //         label: t("my_trans"),
    //         hasAccess: hasAccess("my_card_transactions", "read") || true,
    //         key: "/my-transactions",
    //       },
    //     ],
    //   },
    //   {
    //     label: t("inventory"),
    //     key: RouteConstants.CARD_INVENTORY,
    //     hasAccess: hasAccess("inventory", "read"),
    //   },
    // ],
    // "/transfers": [
    //   //   {
    //   //   label: "Quick Transfers",
    //   //   key: "/quick-transfers",
    //   //   hasAccess: hasAccess("transfers", "read") && is_admin
    //   // },
    //   // {
    //   //   label: "Bulk Transfers",
    //   //   key: "/bulk-transfers",
    //   //   hasAccess: hasAccess("transfers", "read") && is_admin
    //   // }, {
    //   //   label: "Transfers Log",
    //   //   key: "/transfers-log",
    //   //   hasAccess: hasAccess("transfers", "read") && is_admin
    //   // },
    //   {
    //     label: t("bank_transfers"),
    //     key: "/bank",
    //     titleLabel: "Payments",
    //     hasAccess:
    //       hasAccess("company_accounts", "read") ||
    //       hasAccess("beneficiary_accounts", "read") ||
    //       hasAccess("history", "read"),
    //     children: [
    //       {
    //         label: t("company_accounts"),
    //         hasAccess: hasAccess("company_accounts", "read"),
    //         key: "",
    //       },
    //       // {
    //       //   label: t('history'),
    //       //   hasAccess: hasAccess("history", "read"),
    //       //   key: "/history"
    //       // },
    //     ],
    //   },
    //   {
    //     label: t("benificiery_account"),
    //     hasAccess: hasAccess("beneficiary_accounts", "read"),
    //     key: "/beneficiary",
    //     children: [
    //       {
    //         label: t("approvals"),
    //         hasAccess: hasAccess("beneficiary_accounts", "read"),
    //         key: "",
    //       },
    //       {
    //         label: t("my_payments"),
    //         hasAccess: hasAccess("beneficiary_accounts", "read"),
    //         key: "/payments",
    //       },
    //     ],
    //   },
    //   {
    //     label: t("bulk_transfer"),
    //     key: "/bulk",
    //     altAdminKey: "/my-bulk-payments",
    //     hasAccess:
    //       hasAccess("bulk_transfer_upload_payments", "read") ||
    //       hasAccess("bulk_transfers_history", "read"),
    //     children: [
    //       {
    //         label: t("payment_upload"),
    //         hasAccess: hasAccess("bulk_transfer_upload_payments", "read"),
    //         key: "/uploadpayments",
    //       },
    //       {
    //         label: t("approvals"),
    //         hasAccess: hasAccess("bulk_transfers_history", "read"),
    //         key: "/approvals",
    //       },
    //       {
    //         label: t("bulk_payments"),
    //         hasAccess: hasAccess("bulk_transfers_history", "read"),
    //         key: "/my-bulk-payments",
    //       },
    //     ],
    //   },
    //   {
    //     label: t("Send Money"),
    //     key: "/send_money",
    //     hasAccess: true,
    //   },
    //   {
    //     label: t("manage_beneficial"),
    //     key: "/manage_beneficiary",
    //     hasAccess: hasAccess("manage_beneficiaries", "read"),
    //     divider: true,
    //     navTitle: t("Collect Payments"),
    //   },
    //   {
    //     label: t("payment_links"),
    //     key: "/payment_links",
    //     titleLabel: t("get_paid"),
    //     hasAccess: hasAccess("payment_links", "read"),
    //   },
    //   {
    //     label: t("bulk_payment_links"),
    //     key: "/bulkpayment",
    //     altAdminKey: "/history",
    //     hasAccess:
    //       hasAccess("bulk_upload_payment_links", "read") ||
    //       hasAccess("bulk_payment_history", "read"),
    //     children: [
    //       {
    //         label: t("upload_payment_links"),
    //         hasAccess: hasAccess("bulk_upload_payment_links", "read"),
    //         key: "/uploadpaymentlinks",
    //       },
    //       {
    //         label: t("history"),
    //         hasAccess: hasAccess("bulk_payment_history", "read"),
    //         key: "/history",
    //       },
    //     ],
    //   },
    // ],
    // '/account': [
    //   {
    //     key: '/accs',
    //     label: t('bank_Accounts'),
    //     hasAccess: hasAccess("primary_accounts", "read"),
    //     children: [{
    //       label: t('primary_accounts'),
    //       hasAccess: hasAccess("primary_accounts", "read"),
    //       key: "/primary"
    //     },
    //       //  {
    //       //   label: "Virtual Accounts",
    //       //   hasAccess: true,
    //       //   key: "/virtual"
    //       // },
    //     ],
    //     divider: true
    //   }, {
    //     key: '',
    //     label: t('virtual_Accounts'),
    //     hasAccess: hasAccess("company_master_account", "read"),
    //     titleLabel: t('Accounts'),
    //   },
    //   {
    //     key: RouteConstants.ACCOUNT_TRANSACTIONS,
    //     label: t('transactions'),
    //     hasAccess: hasAccess("account_transactions", "read")
    //   }],
    // "/purchases": [
    //   {
    //     key: "",
    //     label: t("company_master_account"),
    //     hasAccess: true,
    //   },
    //   {
    //     key: RouteConstants.ACCOUNT_TRANSACTIONS,
    //     label: t("transactions"),
    //     hasAccess: true && is_admin,
    //   },
    // ],
    // "/purchases": [
    //   {
    //     label: t("vendor"),
    //     key: "/vendors",
    //     hasAccess: hasAccess("vendors", "read"),
    //     titleLabel: t("Purchases"),
    //   },
    //   {
    //     label: t("purchase_orders"),
    //     key: "/orders",
    //     hasAccess:
    //       hasAccess("purchase_order_approvals", "read") ||
    //       hasAccess("my_purchases", "read"),
    //     altAdminKey: "/my-bills",
    //     children: [
    //       {
    //         label: t("Awating Approvals"),
    //         hasAccess: hasAccess("purchase_order_approvals", "read"),
    //         key: "/approvals",
    //       },
    //       {
    //         label: t("my_purchases"),
    //         hasAccess: hasAccess("my_purchases", "read"),
    //         key: "/my-bills",
    //       },
    //     ],
    //   },
    //   {
    //     label: t("bills"),
    //     key: "/bills",
    //     hasAccess:
    //       hasAccess("bill_approvals", "read") || hasAccess("my_bills", "read"),
    //     altAdminKey: "/my-bills",
    //     children: [
    //       {
    //         label: t("Awating Approvals"),
    //         hasAccess: hasAccess("bill_approvals", "read"),
    //         key: "/approvals",
    //       },
    //       {
    //         label: t("my_bills"),
    //         hasAccess: hasAccess("my_bills", "read"),
    //         key: "/my-bills",
    //       },
    //     ],
    //   },
    //   {
    //     label: t("utility_bill"),
    //     key: "/utility-bills",
    //     hasAccess: hasAccess("utility_bills", "read"),
    //   },
    //   {
    //     label: t("payments_made"),
    //     key: "/payments",
    //     altAdminKey: "/pending",
    //     hasAccess:
    //       hasAccess("payments_made_paid", "read") ||
    //       hasAccess("payments_made_pending", "read"),
    //     children: [
    //       {
    //         label: t("pending"),
    //         hasAccess: hasAccess("payments_made_pending", "read"),
    //         key: "/pending",
    //       },
    //       {
    //         label: t("paid"),
    //         hasAccess: hasAccess("payments_made_paid", "read"),
    //         key: "/completed",
    //       },
    //     ],
    //   },
    // ],
    // "/sales": [
    //   {
    //     label: t("customers"),
    //     key: "/customers",
    //     hasAccess: hasAccess("customers", "read"),
    //     titleLabel: t("Sales"),
    //   },
    //   {
    //     label: t("estimate"),
    //     key: "/estimates",
    //     altAdminKey: "/my-estimates",
    //     hasAccess:
    //       hasAccess("estimates", "read") ||
    //       hasAccess("estimates_approvals", "read"),
    //     children: [
    //       {
    //         label: t("Awating Approvals"),
    //         hasAccess: hasAccess("estimates_approvals", "read"),
    //         key: "/approvals",
    //       },
    //       {
    //         label: t("my_estimates"),
    //         hasAccess: hasAccess("estimates", "read"),
    //         key: "/my-estimates",
    //       },
    //     ],
    //   },
    //   {
    //     label: t("order_sale"),
    //     key: "/orders",
    //     altAdminKey: "/my-sales",
    //     hasAccess:
    //       hasAccess("sales_orders", "read") ||
    //       hasAccess("sales_orders_approvals", "read"),
    //     children: [
    //       {
    //         label: t("Awating Approvals"),
    //         hasAccess: hasAccess("sales_orders_approvals", "read"),
    //         key: "/approvals",
    //       },
    //       {
    //         label: t("my_sales"),
    //         hasAccess: hasAccess("sales_orders", "read"),
    //         key: "/my-sales",
    //       },
    //     ],
    //   },
    //   {
    //     label: t("invoices"),
    //     key: "",
    //     hasAccess:
    //       hasAccess("invoices", "read") ||
    //       hasAccess("invoices_approvals", "read"),
    //     altAdminKey: "/invoices",
    //     children: [
    //       {
    //         label: t("Awating Approvals"),
    //         hasAccess: hasAccess("invoices_approvals", "read"),
    //         key: "/approvals",
    //       },
    //       {
    //         label: t("my_invoices"),
    //         hasAccess: hasAccess("invoices", "read"),
    //         key: "/invoices",
    //       },
    //     ],
    //   },
    //   // {
    //   //   label: t("credit_notes"),
    //   //   key: "/credit-notes",
    //   //   hasAccess: hasAccess("credit_notes", "read")
    //   // },
    //   {
    //     label: t("payment_receive"),
    //     key: "/payments-received",
    //     hasAccess: hasAccess("payments_received", "read"),
    //   },
    // ],
    // "/statements": [
    //   {
    //     label: t("bank"),
    //     hasAccess: hasAccess("banks", "read"),
    //     key: "/banks",
    //     titleLabel: t("statements"),
    //   },
    //   // {
    //   //   label : "Statements",
    //   //   key : "",
    //   //   hasAccess: true,
    //   //   children: [{
    //   //     label : "Banks",
    //   //     hasAccess: true,
    //   //     key : "/banks",
    //   //   },{
    //   //     label : "Cards",
    //   //     hasAccess: true,
    //   //     key:"/cards"
    //   //   }]
    //   // }
    // ],
    "/settings": [
      {
        label: t("Profile"),
        hasAccess: hasAccess("organisation_settings", "read"),
        key: "/profile",
        titleLabel: "Settings",
      },
      {
        label: t("Alerts"),
        hasAccess: true,
        key: "/alerts",
        titleLabel: "Personal",
      },
      {
        label: t("Security"),
        hasAccess: true,
        key: "/security",
      },
    ],
  };

  const items = [
    createMenuItem(
      "/dashboard",
      t("Dashboard"),
      "Overview of the Spends",
      true,
      <DashboardSVG />,
      activeMenu,
      "",
      true
    ),
    createMenuItem(
      "/account",
      t("Accounts"),
      "Connect and Manage Bank Accounts",
      isAccount(),
      <AccountSVG />,
      activeMenu,
      t("Accounts")
    ),
    createMenuItem(
      "/transactions",
      t("Transactions"),
      "View Transcations",
      isAccount(),
      <TxnSVG />,
      activeMenu
    ),
    createMenuItem(
      "/transfers",
      t("Payments"),
      "Bank Transfer, Bulk Transfer & Payment links",
      // isTransfers() && is_admin,
      true,
      <TransfersSVG />,
      activeMenu,
      t("Payments"),
      false,
      [
        {
          label: t("Send Money"),
          key: "/beneficiary",
          altAdminKey: "/payments",
          hasAccess: true,
          children: [
            {
              label: t("my_payments"),
              hasAccess: hasAccess("beneficiary_accounts", "read"),
              key: "/payments",
            },
            // {
            //   label: t("Awaiting Approvals"),
            //   hasAccess: hasAccess("beneficiary_accounts", "read"),
            //   key: "/approvals",
            // },
            {
              label: t("Scheduled"),
              hasAccess: hasAccess("beneficiary_accounts", "read"),
              key: "/scheduled",
            },
          ],
        },
        // {
        //   label: t("Request"),
        //   key: "/payment_links",
        //   hasAccess: true,
        // },
        {
          label: t("Recipients"),
          hasAccess: hasAccess("beneficiary_accounts", "read"),
          key: "/manage_beneficiary",
        },
        // {
        //   label: t("bulk_transfer"),
        //   key: "/bulk",
        //   altAdminKey: "/my-bulk-payments",
        //   hasAccess:
        //     hasAccess("bulk_transfer_upload_payments", "read") ||
        //     hasAccess("bulk_transfers_history", "read"),
        //   children: [
        //     {
        //       label: t("Awaiting Approvals"),
        //       hasAccess: hasAccess("bulk_transfers_history", "read"),
        //       key: "/approvals",
        //     },
        //     {
        //       label: t("bulk_payments"),
        //       hasAccess: hasAccess("bulk_transfers_history", "read"),
        //       key: "/my-bulk-payments",
        //     },
        //   ],
        // },
      ]
    ),
    createMenuItem(
      "/cards",
      t("Cards"),
      "Manage your Cards",
      true,
      <CardSVG />,
      activeMenu,
      t("Cards"),
      false,
      [
        {
          label: t("Debit Card"),
          key: "/debit-card",
          hasAccess: true,
        },
        {
          label: t("Credit Card"),
          key: "/credit-card",
          hasAccess: true,
        },
        {
          label: t("Prepaid Cards"),
          hasAccess: true,
          key: "",
        },
      ]
    ),
    createMenuItem(
      "/statements",
      t("statements"),
      "Review & Analyze and Download reports",
      isStatements(),
      <StatementsSVG />,
      activeMenu,
      t("statements"),
      true
    ),
    // createMenuItem("/loans", t("loan"), "Apply & Manage loans", true && is_admin, <LoanSVG />, activeMenu, t("loan"), true),
    // createMenuItem(
    //   "/sales",
    //   t("Invoicing"),
    //   "Create Estimates, Invoices & Collect Payments",
    //   isSales(),
    //   <SalesSVG />,
    //   activeMenu,
    //   t("Invoicing"),
    //   false,
    //   [
    //     {
    //       label: t("invoices"),
    //       key: "",
    //       hasAccess:
    //         hasAccess("invoices", "read") ||
    //         hasAccess("invoices_approvals", "read"),
    //       altAdminKey: "/invoices",
    //       children: [
    //         {
    //           label: t("my_invoices"),
    //           hasAccess: hasAccess("invoices", "read"),
    //           key: "/invoices",
    //         },
    //         {
    //           label: t("Awating Approvals"),
    //           hasAccess: hasAccess("invoices_approvals", "read"),
    //           key: "/approvals",
    //         },
    //       ],
    //     },
    //     {
    //       label: t("customers"),
    //       key: "/customers",
    //       hasAccess: hasAccess("customers", "read"),
    //       titleLabel: t("Sales"),
    //     },
    //   ]
    // ),
    // createMenuItem(
    //   "/expenses",
    //   t("Expenses"),
    //   "Create and Approve Expenses and more",
    //   true,
    //   <ExpenseSVG />,
    //   activeMenu,
    //   t("Expenses")
    // ),
    // createMenuItem(
    //   "/purchases",
    //   t("Bill Pay"),
    //   "Create Purchase Order, Bill & Initiate payments",
    //   isPurchases(),
    //   <PurchasesSVG />,
    //   activeMenu,
    //   t("Bill Pay"),
    //   false,
    //   [
    //     {
    //       label: t("bills"),
    //       key: "/bills",
    //       hasAccess:
    //         hasAccess("bill_approvals", "read") ||
    //         hasAccess("my_bills", "read"),
    //       altAdminKey: "/my-bills",
    //       children: [
    //         {
    //           label: t("my_bills"),
    //           hasAccess: hasAccess("my_bills", "read"),
    //           key: "/my-bills",
    //         },
    //         {
    //           label: t("Awating Approvals"),
    //           hasAccess: hasAccess("bill_approvals", "read"),
    //           key: "/approvals",
    //         },
    //       ],
    //     },
    //     {
    //       label: t("vendor"),
    //       key: "/vendors",
    //       hasAccess: hasAccess("vendors", "read"),
    //       titleLabel: t("Purchases"),
    //     },
    //   ]
    // ),
    // createMenuItem("/payroll", t("payroll"), "Manage & process payroll.", isPayroll(), <PurchasesSVG />, activeMenu),
    // createMenuItem("/utility-bills", t("utility_bill"), "", hasAccess("bills", "read"), <PurchasesSVG />, activeMenu),
    // createMenuItem(
    //   "/analytics",
    //   t("Reports"),
    //   "Review & Analyze and Download reports",
    //   isAnalytics() && is_admin,
    //   <StatementsSVG />,
    //   activeMenu,
    //   "",
    //   true
    // ),
    // createMenuItem("/services", t("services"), "Manage Cheque Requests, DD, Cash Receipts", true && is_admin, <ServicesSVG />, activeMenu, t("Services")),
    createMenuItem(
      "/settings",
      t("Settings"),
      "Customize and Configure",
      true,
      <SettingsSVG />,
      activeMenu,
      t("Settings")
    ),
  ];

  useEffect(() => {
    dispatch({
      type: Collapsed,
      payload: collapsed,
    });
  }, [collapsed]);

  const handleMenuItemClick = (menuItem) => {
    setActiveMenu(menuItem.key);
    setShowChildren(menuItem.children?.length > 0 ? true : false);
    setChildrenMenu(menuItem.children?.[0]?.key);
    setActiveSubMenu(menuItem.children?.[0]?.key);
    setToggle((b) => ({
      [menuItem.key]: !b[menuItem.key],
    }));
    let subMenuFirstChild;
    if (["/settings"].includes(menuItem.key)) {
      setCollapsed(true);
      subMenuFirstChild = submenus?.[menuItem.key]?.[0];
    } else {
      setCollapsed(false);
    }
    if (menuItem.children?.find((ele) => ele.children)?.children) {
      setNavChildren(menuItem.children?.find((ele) => ele.children)?.children);
      localStorage.setItem(
        "navChildren",
        JSON.stringify(menuItem.children?.find((ele) => ele.children)?.children)
      );
    } else {
      setNavChildren([]);
      localStorage.setItem("navChildren", "[]");
    }
    if (subMenuFirstChild) {
      history.push(menuItem.key + (subMenuFirstChild?.key || ""));
    } else {
      history.push(
        menuItem.key +
          (menuItem.children?.[0]?.key || "") +
          (menuItem.children?.[0]?.altAdminKey || "")
      );
    }
  };

  return (
    <div className="d-flex">
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        className={`left-menu ${collapsed ? "menu-collapsed" : ""}`}
      >
        <div
          className="logo mt2"
          onClick={() => {
            // setCollapsed((b) => !b);
            setToggle(false);
            setActiveMenu("/dashboard");
            history.push("/dashboard");
          }}
        >
          {collapsed ? (
            <img src={SHORT_LOGO} alt="" />
          ) : (
            <img src={LOGO} alt="" />
          )}
        </div>
        <Menu mode="inline" defaultSelectedKeys={["1"]} collapsed={collapsed}>
          {items.map(
            (menuItem) =>
              menuItem.hasAccess && (
                <div key={menuItem.key}>
                  <div
                    className={
                      activeMenu === menuItem.key &&
                      menuItem.children.length === 0
                        ? `menu-item menu-selected `
                        : `menu-item`
                    }
                    onClick={() => handleMenuItemClick(menuItem)}
                  >
                    <span className="icon">{menuItem.icon}</span>
                    {!collapsed && (
                      <div className="flex-between w80">
                        <span className="nav-label">{menuItem.label}</span>
                        {menuItem.children?.length > 0 && (
                          <span
                            className={`svg  ${
                              toggle[menuItem.key] ? "rotate" : ""
                            }`}
                          >
                            <DownSVG fill={"#fff"} />
                          </span>
                        )}
                      </div>
                    )}
                    {collapsed && (
                      <div className="side-nav-hover">
                        <span className="icon">{menuItem.icon}</span>
                        {menuItem.label}
                        <span className="dim-text">{menuItem.description}</span>
                      </div>
                    )}
                  </div>
                  {menuItem.divider && (
                    <div className="sub-menu-item-1">
                      <div className="line" />
                    </div>
                  )}
                  {menuItem.children?.length > 0 ? (
                    <div
                      className={`submenu-wrap ${
                        toggle[menuItem.key] ? "open" : ""
                      }`}
                    >
                      <SubMenuItems
                        activeMenu={menuItem.key}
                        activeSubMenu={activeSubMenu}
                        setActiveSubMenu={setActiveSubMenu}
                        items={items}
                        setNavChildren={setNavChildren}
                      />
                    </div>
                  ) : null}
                  <br />
                </div>
              )
          )}
        </Menu>
      </Sider>
      {collapsed && submenus[activeMenu] && (
        <div className="sub-menu collapse">
          {/* {items.find((item) => item?.key === activeMenu) && (
            <h4 className="titleLabel">
              {items.find((item) => item?.key === activeMenu)?.titleLabel}
            </h4>
          )} */}
          {submenus[activeMenu]?.map(
            (itm) =>
              itm.hasAccess && (
                <>
                  {itm.titleLabel && (
                    <h4 className="titleLabel">{itm.titleLabel}</h4>
                  )}
                  <div
                    key={itm.key}
                    className={
                      activeSubMenu === itm.key
                        ? `sub-menu-item sub-menu-selected ${
                            itm.children ? "has-children" : ""
                          }`
                        : `sub-menu-item ${itm.children ? "has-children" : ""}`
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      setActiveSubMenu(itm.key);
                      if (itm.children) {
                        const indexValue = itm.children.findIndex(
                          (item) => item.hasAccess
                        );
                        setChildrenMenu(itm.children?.[indexValue].key);
                        console.log(
                          "nav",
                          indexValue,
                          activeMenu +
                            itm.key +
                            (is_admin
                              ? itm.children?.[indexValue].key
                              : itm.altAdminKey ?? "")
                        );
                        setShowChildren(true);
                        history.push(
                          activeMenu + itm.key + itm.children?.[indexValue].key
                        );
                        return;
                      } else {
                        setShowChildren(false);
                      }
                      console.log("nav", activeMenu + itm.key);
                      history.push(activeMenu + itm.key);
                    }}
                  >
                    <span className="nav-label">{itm.label}</span>
                  </div>
                  {showChildren &&
                    itm.children &&
                    activeSubMenu === itm.key && (
                      <div className="sub-menu-children">
                        {itm.children?.map(
                          (ele) =>
                            ele.hasAccess && (
                              <div
                                className={
                                  childrenMenu === ele.key
                                    ? `sub-menu-children-item sub-menu-selected`
                                    : `sub-menu-children-item`
                                }
                                onClick={() => {
                                  setChildrenMenu(ele.key);
                                  history.push(
                                    activeMenu + activeSubMenu + ele.key
                                  );
                                }}
                              >
                                <span className="nav-label">{ele.label}</span>
                              </div>
                            )
                        )}
                      </div>
                    )}
                  {itm.divider && (
                    <div className="sub-menu-item-1">
                      {itm.navTitle && (
                        <div className="titleLabel">{itm.navTitle}</div>
                      )}
                      <div className="line" />
                    </div>
                  )}
                </>
              )
          )}
        </div>
      )}
    </div>
  );
};

const SubMenuItems = ({
  items,
  activeMenu,
  activeSubMenu,
  setActiveSubMenu,
  setNavChildren,
}) => {
  const submenuItems = items.find((item) => item.key === activeMenu)?.children
    ? items.find((item) => item.key === activeMenu)?.children
    : [];
  const history = useHistory();
  const [toggle, setToggle] = React.useState({});

  const groupItemsByGroup = (children) => {
    const groupedItems = {};

    children.forEach((item) => {
      const group = item.group;
      if (item.group) {
        if (!groupedItems[group]) {
          groupedItems[group] = [];
        }

        groupedItems[group].push(item);
      } else groupedItems[item.key] = item;
    });

    const result = Object.values(groupedItems);

    return result;
  };
  const newItems = groupItemsByGroup(submenuItems);

  return (
    <ul className={"submenu"}>
      {newItems.map((submenuItem, index) => (
        <React.Fragment key={index}>
          {Array.isArray(submenuItem) ? (
            <>
              {submenuItem[0].titleLabel && (
                <p
                  className="titleLabel"
                  onClick={(e) => {
                    e.stopPropagation();
                    setToggle({
                      [submenuItem[0].group]: !toggle[submenuItem[0].group],
                    });
                  }}
                >
                  {submenuItem[0].titleLabel}
                </p>
              )}
              <div
                className={`${
                  toggle[submenuItem[0].group] ? "view" : "hide"
                } expand collapse`}
              >
                {submenuItem.map(
                  (subItem) =>
                    subItem.hasAccess && (
                      <li
                        key={subItem.key}
                        onClick={(e) => {
                          e.stopPropagation();
                          setActiveSubMenu(subItem.key);
                          history.push(activeMenu + subItem.key);
                        }}
                        className={`${
                          activeSubMenu === subItem.key
                            ? "submenu-item-selected"
                            : ""
                        }`}
                      >
                        {subItem.label}
                      </li>
                    )
                )}
              </div>
            </>
          ) : (
            submenuItem.hasAccess && (
              <li
                key={submenuItem.key}
                onClick={(e) => {
                  e.stopPropagation();
                  setActiveSubMenu(submenuItem.key);
                  if (submenuItem.children) {
                    setNavChildren(submenuItem.children);
                    localStorage.setItem(
                      "navChildren",
                      JSON.stringify(submenuItem.children)
                    );
                  } else {
                    setNavChildren([]);
                    localStorage.setItem("navChildren", "[]");
                  }
                  history.push(
                    activeMenu +
                      submenuItem.key +
                      (submenuItem?.children?.[0]?.key || "")
                  );
                }}
                className={`${
                  activeSubMenu === submenuItem.key
                    ? "submenu-item-selected"
                    : ""
                }`}
              >
                {submenuItem.label}
              </li>
            )
          )}
        </React.Fragment>
      ))}
    </ul>
  );
};
export default withRouter(LeftNavigation);
