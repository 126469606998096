import { PlusOutlined } from "@ant-design/icons";
import { Button, Divider, Select } from "antd";
import React, { useState } from "react";

export default function Dropdown({
  hideCreateOption,
  label,
  placeholder,
  inline,
  onChange,
  onSearch,
  options,
  value,
  onFocus,
  onClick,
  onAddItem,
  mode,
  disabled = false,
  showSearch = true,
  filterKey,
  allowClear,
  name,
  notFoundContent,
  style,
  noDD,
}) {
  const [inputSelect, setInputSelect] = useState();
  return (
    <>
      <div style={{ width: "100%" }}>
        <Select
          style={{ width: "100%" }}
          showSearch={showSearch}
          className={`pp-dropdown ${inline ? "pp-dropdown-inline" : ""}`}
          placeholder={placeholder}
          suffixIcon={noDD ? null : undefined}
          optionFilterProp="children"
          filterOption={(input, option) =>
            filterKey
              ? (option?.[filterKey]?.toLowerCase?.() ?? "")?.includes(
                  input?.toLowerCase?.()
                )
              : (option?.label?.toLowerCase?.() ?? "")?.includes(
                  input?.toLowerCase?.()
                )
          }
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? "")
              ?.toLowerCase?.()
              ?.localeCompare?.((optionB?.label ?? "")?.toLowerCase?.())
          }
          onFocus={onFocus}
          onClick={onClick}
          value={value ? value : null}
          listItemHeight={40}
          onChange={onChange}
          allowClear={allowClear}
          mode={mode}
          onSearch={(value) => {
            onSearch && onSearch(value);
            setInputSelect(value);
          }}
          disabled={disabled}
          notFoundContent={
            notFoundContent ? <>{notFoundContent}</> : <>{label} Not Found</>
          }
          dropdownRender={(menu) => (
            <>
              <div className="pp-dropdown-menu">{menu}</div>
              {!hideCreateOption && (
                <>
                  <Divider className="pp-dropdown-divider" />
                  <Button
                    type="text"
                    htmlType="button"
                    className="pp-dropdown-add-new"
                    icon={<PlusOutlined />}
                    onClick={() => {
                      onAddItem && onAddItem();
                    }}
                  >
                    <span>Add {label}</span>
                  </Button>
                </>
              )}
            </>
          )}
          options={options}
          name={name}
        />
      </div>
    </>
  );
}
