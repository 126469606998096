import { message, Modal } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import copy from "../../assets/icons/copy.png";
import debit from "../../assets/images/req_mon.png";
import { gridApis } from "../../config/GridApis";
import { currencyMapper } from "../../config/helper";
import usePayAxios from "../../config/useAxios";
import { amountFormat } from "../../utils";
import { AccountDisplay } from "../Account/AccountCard";
import eye from "../Payments/SendMoney/assets/eye.png";

const ViewRequest = ({ open, onClose, txnDetail: data }) => {
  const [activeTab, setActiveTab] = useState("details");
  const [isPreviewOpen, setIsPreviewOpen] = useState({
    open: false,
    url: "",
    fileType: "",
  });
  const { onCall, data: attach } = usePayAxios({
    api: gridApis.fetchPaymentLink,
    method: "get",
  });
  useEffect(() => {
    if (data.link_id)
      onCall({
        params: {
          token: data.link_id,
        },
      });
  }, [data]);

  return (
    <>
      <Modal
        visible={isPreviewOpen?.open}
        onCancel={() => {
          setIsPreviewOpen({
            open: false,
            url: "",
            fileType: "",
          });
        }}
        footer={null}
        width="80%"
        centered
        className="preview_section_modal"
      >
        {isPreviewOpen?.fileType == "pdf" ? (
          <div style={{ width: "100%", height: "100vh" }}>
            <iframe
              src={isPreviewOpen?.url}
              allow="fullscreen"
              style={{ width: "100%", height: "100%", margin: "auto" }}
              title="description"
            ></iframe>
          </div>
        ) : (
          <div
            style={{ width: "100%", height: "100vh" }}
            className="d-flex-center"
          >
            <img
              src={isPreviewOpen?.url}
              alt="preview image"
              style={{ maxWidth: "100%", maxHeight: "100%", margin: "auto" }}
            />
          </div>
        )}
      </Modal>
      <Modal
        className="right-alinged-modal alerts txn-dt"
        title={
          <label
            style={{
              color: "#363636",
              paddingBottom: 10,
              fontSize: 21,
            }}
          >
            Payment Request Details
          </label>
        }
        visible={open}
        onCancel={onClose}
      >
        <div className="flex-between txn-head">
          <div className="left">
            <span className="nm-circle">
              <img src={debit} width={15} />{" "}
            </span>
            <label>
              Payment Requested
              <br />
              {data.transaction_type === "credit" ? "from " : "to "}
              <b>
                {data?.counterparty_name} (****
                {data?.counterparty_account_number?.slice?.(-4)})
              </b>
              <br />
              <span className={`status status_${data.status?.toLowerCase()}`}>
                {data?.status?.replace(/_/g, " ")}
              </span>
            </label>
          </div>
          <div className="right">
            <h2>
              {currencyMapper[data?.currency]} {amountFormat(data.amount)}
            </h2>
          </div>
        </div>
        <ul className="tab-menu">
          <li className="cp-link">
            <span className="ellipsis">
              {window.location.origin}/payments-select/{data?.link_id}
            </span>
            <img
              src={copy}
              width={15}
              className="ml2"
              onClick={() => {
                navigator.clipboard
                  .writeText(
                    `${window.location.origin}/payments-select/${data?.link_id}`
                  )
                  .then(
                    () => {
                      message.success(
                        <span className="messageText">Copied</span>
                      );
                    },
                    (err) => console.error("Failed to copy text: ", err)
                  );
              }}
            />
          </li>
        </ul>
        <ul className="tab-menu">
          <li
            className={activeTab === "details" ? "active" : ""}
            onClick={() => setActiveTab("details")}
          >
            Details
          </li>
          {attach?.data?.attachments?.length ? (
            <li
              className={activeTab === "attach" ? "active" : ""}
              onClick={() => setActiveTab("attach")}
            >
              Attachments
            </li>
          ) : null}
          {/* <li
          className={activeTab === "history" ? "active" : ""}
          onClick={() => setActiveTab("history")}
        >
          History
        </li>
        <li
          className={activeTab === "comments" ? "active" : ""}
          onClick={() => setActiveTab("comments")}
        >
          Comments
        </li> */}
        </ul>
        {activeTab === "attach" && (
          <div className="imgs-wrap">
            {attach?.data?.attachments?.map((ele) => {
              return (
                <div
                  style={{
                    position: "relative",
                  }}
                >
                  <div className="img">
                    <img src={ele.url} />
                    <img
                      src={eye}
                      alt=""
                      className="file-eye"
                      onClick={() => {
                        setIsPreviewOpen({
                          open: true,
                          url: ele.url,
                          fileType: ele.content_type?.split("/")[1],
                        });
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {activeTab === "details" && (
          <>
            <div className="company-details txn-details">
              <h3> Request Details </h3>
              <div className="item">
                <label>Send payment request to</label>
                <span>{data.counterparty_name}</span>
              </div>
              <div className="item">
                <label>Requested Account</label>
                <span>
                  <AccountDisplay
                    accountNumber={data.counterparty_account_number}
                    showEye={true}
                  />
                </span>
              </div>
              <div className="item">
                <label>Destination Account Name</label>
                <span>{data.ourparty_name}</span>
              </div>
              <div className="item">
                <label>Destination Account</label>
                <span>
                  <AccountDisplay
                    accountNumber={data.ourparty_account_number}
                    showEye={true}
                  />
                </span>
              </div>

              <div className="item">
                <label>Description</label>
                <span>{data.description}</span>
              </div>
              <div className="item">
                <label>Status</label>
                <span
                  className={`status status_${data.status?.toLowerCase()}`}
                  style={{
                    paddingRight: 0,
                  }}
                >
                  {data?.status?.replace(/_/g, " ")}
                </span>
              </div>
              <div className="item">
                <label>Payment Due Date</label>
                <span>{moment(data.payment_due_date).format("MM/DD/YY")}</span>
              </div>
            </div>
          </>
        )}
      </Modal>
    </>
  );
};

export default ViewRequest;
