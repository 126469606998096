import { DownloadOutlined } from "@ant-design/icons";
import { Steps, message } from "antd";
import { saveAs } from "file-saver";
import { json2csv } from "json-2-csv";
import ExcelRenderer from "papaparse";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import {
  UploadBulk,
  downloadBulkEmployees,
  getUserFields,
} from "../../../../actions/employees/employees";
import BulkBenTemplate from "../../../../assets/bulk_beneficiary_template.csv";
import Close from "../../../../assets/icons/close.png";
import usePayAxios from "../../../../config/useAxios";
import useSettingsAxios from "../../../../config/useSettings";
import UploadMain from "../../../Upload_Components/UploadMain";
import MapFields, { benkeys, user_keys } from "./MapFields";

const Step = Steps.Step;

export const chunk = function (arr, chunkSize) {
  var R = [];
  for (var i = 0; i < arr.length; i += chunkSize)
    R.push(arr.slice(i, i + chunkSize));
  return R;
};

const AddBulkUsers = ({ isBeneficiary }) => {
  const dispatch = useDispatch();
  const [file, setFile] = React.useState(null);
  const [fileData, setFileData] = React.useState(null);
  const [step, setStep] = React.useState(0);
  const [columnRows, setColumnRows] = useState({});
  const { t } = useTranslation();
  const [fileErrs, setFileErrs] = useState({});

  const [columnHeader, setColumnHeader] = useState([]);
  const history = useHistory();

  const userFields = useSelector(
    (state) => state?.users?.user_fields?.data?.data?.user_template_fields || []
  );

  const userApprovalFields = useSelector(
    (state) => state?.users?.user_fields?.data?.data?.approval_flow_roles || []
  );

  const userCustomFields = useSelector(
    (state) => state?.users?.user_fields?.data?.data?.user_custom_fields || []
  );

  const uploadRes = useSelector((state) => state?.users?.upload);

  const validationSchema = isBeneficiary
    ? Yup.object().shape({
        beneficiary_name: Yup.string().required("Beneficiary name is required"),
        // beneficiary_type: Yup.string().required("Beneficiary type is required"),
        primary_contact: Yup.string().required("Primary contact is required"),
        email: Yup.string()
          .email("Invalid email")
          .required("Email is required"),
        account_number: Yup.string().required("Account number is required"),
        ifsc_code: Yup.string().required("BIC code is required"),
        branch: Yup.string().required("Branch is required"),
      })
    : Yup.object().shape({
        "Employee Id": Yup.string().required("Employee Id is required"),
        Grade: Yup.string(),
        Designation: Yup.string(),
        "Card Number": Yup.string(),
        Name: Yup.string().required("Name is required"),
        Email: Yup.string()
          .email("Invalid email")
          .required("Email is required"),
        "Primary Phone": Yup.string().required("Primary Phone is required"),
        Title: Yup.string().required("Title is required"),
      });

  const {
    onCall,
    data: bulkBen,
    loading: blukBenLoading,
  } = usePayAxios({
    api: "/v1/ems/0/beneficiaries/bulkCreateBeneficiary",
    method: "post",
  });
  const {
    onCall: onBulkUsr,
    data: bulkUsr,
    loading: blukUsrLoading,
  } = useSettingsAxios({
    api: "/v1/settings/0/users/uploadUser",
    method: "post",
  });

  const [error, setError] = useState("");

  const [mapFields, setMapFields] = useState(false);

  const [fileContent, setFileContent] = useState({
    name: "",
    columns: [],
    rows: [],
  });
  // eslint-disable-next-line

  const mapToFields = () => {
    let values = Object.values(columnRows);
    if (values.length > 0 && fileContent.name !== "") {
      setMapFields(true);
    }
  };

  const mapToTable = (data) => {
    if (!isBeneficiary) {
      onBulkUsr({
        data: {
          data_list: data.map((ele) => ({
            ...ele,
            Roles: ele?.Roles?.split(","),
          })),
        },
      })
        .then((res) => {
          if (res?.error === false) {
            message.success(
              <span className="messageText">{res?.message}</span>
            );
            history.push("/settings/users");
          } else
            message.error(<span className="messageText">{res?.message}</span>);
        })
        .catch((err) => {
          message.error(
            <span className="messageText">{err?.response?.data?.message}</span>
          );
        });
    } else {
      const newData = data
        .map((ele) => {
          ele.company_id = localStorage.getItem("company_id");
          ele.company_name = "-";
          ele.beneficiary_type = "individual";
          ele.bank_account_details = [
            {
              account_number: parseFloat(ele.account_number).toString(),
              ifsc_code: ele.ifsc_code,
              branch: ele.branch,
            },
          ];
          ele.configurations = {
            currency: ele?.currency,
          };
          delete ele.account_number;
          delete ele.currency;
          delete ele.ifsc_code;
          delete ele.branch;
          return ele;
        })
        ?.filter((ele) => ele.beneficiary_name);
      // ?.filter((ele) => ele.beneficiary_name && ele.beneficiary_type);
      onCall({
        data: newData,
      })
        .then((res) => {
          if (res?.error === false) {
            message.success(
              <span className="messageText">{res?.message}</span>
            );
            history.push("/transfers/manage_beneficiary");
          } else
            message.error(<span className="messageText">{res?.message}</span>);
        })
        .catch((err) => {
          message.error(
            <span className="messageText">{err?.response?.data?.message}</span>
          );
        });
    }
  };

  useEffect(() => {
    if (!isBeneficiary) {
      dispatch(getUserFields());
      dispatch(downloadBulkEmployees());
    } else {
      setColumnHeader([
        {
          Header: "Beneficiary name",
          accessor: "",
        },
        {
          // Header: "Beneficiary type",
          Header: "Currency",
          accessor: "",
        },
        {
          Header: "Phone number",
          accessor: "",
        },
        {
          Header: "Email",
          accessor: "",
        },
        {
          Header: "Account Number",
          accessor: "",
        },
        {
          Header: "IFSC code",
          accessor: "",
        },
        {
          Header: "Bank name",
          accessor: "",
        },
      ]);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (userFields?.length > 0 && !isBeneficiary) {
      let arr = [];
      userFields.forEach((ele) =>
        arr.push({
          Header: ele,
          accessor: "",
        })
      );
      setColumnHeader(arr);
    }
  }, [userFields]);

  useEffect(() => {
    if (file) {
      readFile(file);
    }
    // eslint-disable-next-line
  }, [file]);

  useEffect(() => {
    if (uploadRes?.success && !isBeneficiary) {
      setFile(null);
      setFileData(uploadRes?.data?.response?.response);
      setStep(0);
      setFileContent({
        name: "",
        columns: [],
        rows: [],
      });
      setColumnRows({});
    }
    if (bulkBen) {
      console.log(bulkBen);
    }
  }, [uploadRes]);

  const readFile = (file) => {
    setError("");
    let cols = [];
    let rows = [];
    var keys = Object.keys(columnRows);

    let fileObj = file;
    setFileErrs({});

    if (
      fileObj &&
      (fileObj.type === "application/vnd.ms-excel" ||
        fileObj.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        fileObj.type === "text/csv") &&
      fileObj.size < parseInt("5242880")
    ) {
      setFileContent({ ...fileContent, name: file.name });
      const convertScientificToDecimal = (value) => {
        if (typeof value === "string" && value.includes("E")) {
          return parseFloat(value).toString();
        }
        return value;
      };
      //just pass the fileObj as parameter
      ExcelRenderer.parse(file, {
        complete: (resp) => {
          if (resp && resp.data) {
            let cols = resp.data[0];
            let rows = resp.data.slice(1);
            rows = rows
              ?.filter(
                (ele) =>
                  ele.length > 0 && ele.filter((item) => item !== "").length > 0
              )
              ?.map((row) => {
                return row.map((cell) => {
                  if (typeof cell === "number") {
                    return String(cell); // Convert numbers to strings
                  }
                  return cell;
                });
              });
            let newRows = [];
            rows.map((row) => {
              if (row && row !== "undefined") {
                if (isBeneficiary) {
                  let obj = {};
                  cols?.forEach((col, i) => {
                    obj[benkeys[col]] = convertScientificToDecimal(row[i]);
                  });
                  newRows.push(obj);
                } else {
                  let obj = {};
                  cols?.forEach((col, i) => {
                    obj[user_keys[col] ? user_keys[col] : col] =
                      convertScientificToDecimal(row[i]);
                  });
                  newRows.push(obj);
                }
              }
            });
            setFileContent({
              ...fileContent,
              name: file.name,
              columns: cols,
              rows: rows,
            });

            let obj = {};
            keys.forEach((key, i) => {
              obj[key] = i;
            });
            setColumnRows(obj);
            const validationErrorsFunc = async () => {
              const validationPromises = newRows.map(async (item, index) => {
                try {
                  await validationSchema.validate(item, { abortEarly: false });
                } catch (validationError) {
                  if (validationError.inner) {
                    const errorMessages = validationError.inner.reduce(
                      (acc, error) => {
                        acc[error.path] = error.message;
                        return acc;
                      },
                      {}
                    );
                    return { [index]: errorMessages };
                  }
                }
                return null;
              });

              const validationResults = await Promise.all(validationPromises);
              const validationErrors = validationResults.filter(
                (result) => result !== null
              );

              setFileErrs(
                validationErrors?.reduce((acc, error) => {
                  return { ...acc, ...error };
                }, {})
              );
            };
            validationErrorsFunc();
          }
        },
      });
    }
  };

  return (
    <div className="upload_payment_parent send-payment">
      <div className="flex-between sec-head">
        <div className="tabsSection">
          <span className="active">
            Upload {isBeneficiary ? "Recipients" : "Users"}
          </span>
        </div>
      </div>
      {isBeneficiary ? (
        <UploadMain type={"individual"} />
      ) : (
        <>
          {fileData && (
            <div
              className="success-toast mb2"
              onClick={() => {
                setFileData(null);
                dispatch({
                  type: UploadBulk.RESET,
                });
              }}
            >
              {fileData}
            </div>
          )}
          <div className="bulk-users">
            <div className="steps-align">
              <Steps current={step} className="steps">
                <Step
                  title={t("select_file")}
                  onClick={() => {
                    setStep(0);
                  }}
                />

                <Step
                  title={t("map_files")}
                  // onClick={() => {
                  //   setStep(1);
                  // }}
                />
              </Steps>
            </div>
            {step === 0 ? (
              <>
                <div className="flex-center w100">
                  <div className="showcase-box">
                    <div className="mt1">
                      {isBeneficiary ? (
                        <a href={BulkBenTemplate} download className="mt1">
                          <DownloadOutlined width="2rem" />
                          <br />
                          {t("download_sample_file")}
                          <p>{t("download_csv")}</p>
                        </a>
                      ) : (
                        <div
                          onClick={() => {
                            let usr = {};
                            userFields?.forEach((ele) => {
                              usr[ele] = "";
                            });
                            userCustomFields
                              ?.filter((ele) => ele?.field_type !== "document")
                              ?.forEach((ele) => {
                                usr[ele.field_name] = "";
                              });
                            userApprovalFields
                              ?.filter((ele) => ele !== "Employee")
                              ?.forEach((ele) => {
                                usr[ele] = "";
                              });
                            json2csv([usr]).then((csv) => {
                              var blob = new Blob([csv], {
                                type: "text/csv;charset=utf-8",
                              });
                              saveAs(blob, "User_Template.csv");
                            });
                          }}
                        >
                          <DownloadOutlined width="2rem" />
                          <br />
                          {t("download_sample_file")}
                          <p>{t("download_csv")}</p>
                        </div>
                      )}
                    </div>
                  </div>
                  <input
                    type={"file"}
                    id="file"
                    onChange={(e) => {
                      setFileData(null);
                      console.log(e.target.files[0], "eventDetails===>");
                      if (
                        e.target.files[0]?.type === "text/csv" ||
                        e.target.files[0]?.type ===
                          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      )
                        setFile(e.target.files[0]);
                      else
                        message.error(
                          <span className="messageText">
                            Only CSV files are allowed
                          </span>
                        );
                    }}
                    className="d-none"
                  />
                  <div
                    className="showcase-box ml1"
                    onClick={() => {
                      document.getElementById("file").click();
                      document.getElementById("file").value = null;
                    }}
                  >
                    {file && file.name ? (
                      <div className="mt1">
                        <span
                          className="cursor"
                          onClick={(e) => {
                            e.stopPropagation();
                            setFile(null);
                          }}
                          style={{ position: "absolute", right: 10, top: 10 }}
                        >
                          <img src={Close} width={20} alt={"close"} />
                        </span>
                        <DownloadOutlined width="2rem" />
                        <br />
                        <p className="ellipsis">{file.name}</p>
                      </div>
                    ) : (
                      <div className="mt1">
                        <DownloadOutlined width="2rem" />
                        <br />
                        {t("upload_ur_file")}
                        <p>{t("edit_info")}</p>
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex-center p2 flex-col">
                  <button
                    className="send-btn"
                    disabled={!file || !fileContent?.rows?.length > 0}
                    onClick={() => {
                      setStep(1);
                    }}
                  >
                    {t("proceed")}
                  </button>
                  {!fileContent?.rows?.length > 0 && fileContent?.name ? (
                    <span className="errorMsg">
                      There are no records in excel sheet.
                    </span>
                  ) : null}
                </div>
                <div className="instructions">
                  <h3>{t("instructions")}</h3>
                  <ul>
                    {isBeneficiary ? null : (
                      <>
                        <li>{t("user_grade_match")}</li>
                        <li>{t("unique_id")}</li>
                      </>
                    )}
                    <li>{t("email_format")}</li>
                    <li>{t("mobile_number_should_have")}</li>
                    <li>{t("email_id_approvers")}</li>
                    <li>{t("success_file_upload")}</li>
                    <li>{t("upload_file_format")}</li>
                  </ul>
                </div>
              </>
            ) : (
              <div>
                <MapFields
                  isBeneficiary={isBeneficiary}
                  setColumnRows={setColumnRows}
                  error={error}
                  fileContent={fileContent}
                  mapToTable={mapToTable}
                  columnRows={columnRows}
                  columnHeader={columnHeader}
                  setError={setError}
                  setMapFields={setMapFields}
                  mapToFields={mapToFields}
                  setStep={setStep}
                  uploadLoading={
                    uploadRes?.loading || blukUsrLoading || blukBenLoading
                  }
                  errors={fileErrs}
                />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default AddBulkUsers;
