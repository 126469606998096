import { CloseCircleOutlined, MoreOutlined } from "@ant-design/icons";
import {
  Badge,
  Button,
  Col,
  Dropdown,
  Menu,
  Modal,
  Row,
  Space,
  message,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { headerTitles, SideMenu } from "../../../actions/masterdata/masterdata";
import {
  DELETE_PAYMENT,
  deletePayment,
  get_all_payments,
  REJECT_PAYMENT,
} from "../../../actions/payments";
import DeleteIcon from "../../../assets/icons/delete.png";
import EditIcon from "../../../assets/icons/edit.png";
import TableComponent from "../../Common/TableComp/TableComp";
import TableHeader from "../../Sales/TableHeader";
import SendMoney from "../../Payments/SendMoney/SendMoney";
import RemovePayment from "../../Reports/RemovePayment";
import { currencyMapper } from "../../../config/helper";
import { amountFormat } from "../../../utils";
import { p_types } from "../../Payments/SendMoney/Recipients";
import ViewPaymentModal from "../ViewPayment";
import endpoint from "../../../config/Axios";
import { apis } from "../../../config/APIs";

const BenificiaryTransfers = ({ hasWriteAccess, user, scheduled }) => {
  const [openAddNew, setOpenAddNew] = useState(false);
  const [record, setRecord] = useState({});
  const [edit, setEdit] = useState(false);
  const [removeSch, setRemoveSch] = useState(false);
  const [page, setPage] = useState(1);
  const [deletePaymentModal, setDeletePaymentModal] = useState(false);
  const [search, setSearch] = useState("");

  const [txnDetail, setTxnDetail] = useState({});
  const [txnOpen, setTxnOpen] = useState(false);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const data = useSelector(
    (state) => state?.payments?.payments?.data?.data ?? []
  );
  const loading = useSelector(
    (state) => state?.payments?.payments?.loading || false
  );
  const totalRecords = useSelector(
    (state) => state?.payments?.payments?.data?.total_count ?? []
  );

  const deletePaymentResponse = useSelector(
    (state) => state?.payments?.delete_payment
  );
  const reportsRejectResponse = useSelector(
    (state) => state.payments.reject_payment || {}
  );

  React.useEffect(() => {
    if (reportsRejectResponse?.data?.error === false) {
      message.success(
        <span className="messageText">
          {"Removed payment schedule successfully."}
        </span>
      );
      setRemoveSch(false);
      dispatch(
        get_all_payments({
          scheduled,
          view: user ? "" : "admin",
          type: "COMPANY_PAYMENTS",
          page_number: 1,
        })
      );
      dispatch({ type: REJECT_PAYMENT.RESET });
    } else if (reportsRejectResponse?.data?.error) {
      message.success(
        <span className="messageText">
          {reportsRejectResponse?.data?.message}
        </span>
      );
      dispatch({ type: REJECT_PAYMENT.RESET });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportsRejectResponse]);
  useEffect(() => {
    if (deletePaymentResponse?.data?.error === true) {
      message.error(
        <span className="messageText">
          {deletePaymentResponse?.data?.message}
        </span>
      );
      dispatch({
        type: DELETE_PAYMENT.RESET,
      });
    } else if (deletePaymentResponse?.data?.error === false) {
      message.success(
        <span className="messageText">
          {"Payment Deleted Successfully" ||
            deletePaymentResponse?.data?.message}
        </span>
      );
      setDeletePaymentModal(false);
      dispatch({
        type: DELETE_PAYMENT.RESET,
      });
      dispatch(
        get_all_payments({
          scheduled,
          view: user ? "" : "admin",
          type: "COMPANY_PAYMENTS",
          page_number: 1,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletePaymentResponse]);

  const handleConfirm = () => {
    dispatch(deletePayment({ id: record?.id, type: "COMPANY_PAYMENTS" }));
  };

  const handleCancel = () => {
    setDeletePaymentModal(false);
  };

  const handleAddButton = () => {
    setEdit(false);
    setOpenAddNew(true);
  };

  const onClickEdit = (record) => {
    setOpenAddNew(true);
    setEdit(true);
    setRecord(record);
  };
  const sortOptions = [
    {
      label: t("created_history"),
    },
    {
      label: t("creation_date"),
    },
    {
      label: t("po_number"),
    },
    {
      label: t("vendor_name"),
    },
    {
      label: t("Amount"),
    },
    {
      label: t("expected_delivery_date"),
    },
    {
      label: t("import_purchase_orders"),
    },
    {
      label: t("export_purchase_orders"),
    },
  ];

  const columns = [
    {
      title: t("Transaction Id"),
      dataIndex: "endToEndId",
      key: "endToEndId",
      render: (text, record) => (
        <div title={text}>
          {record?.schedule_payment_date && (
            <div className="base_badge pay">
              <Badge.Ribbon
                text={`Schedule on ${moment(
                  record?.schedule_payment_date
                ).format("DD-MM-YYYY")}`}
                placement={"start"}
                color="#3f81f4"
              />
            </div>
          )}
          {text?.substr(0, 7)}...{text?.substr(-4)}
        </div>
      ),
    },
    {
      title: t("beneficiary_name"),
      dataIndex: "counterparty_account_name",
      key: "counterparty_account_name",
    },
    {
      title: t("beneficiary_account"),
      dataIndex: "counterparty_account_number",
      key: "counterparty_account_number",
      render: (text) => <div>****{text?.substr(4)}</div>,
    },
    {
      title: t("Mode of Payment"),
      dataIndex: "account_transfer_mode_type",
      key: "account_transfer_mode_type",
      render: (text) =>
        [
          ...p_types,
          {
            label: "ACH - Local Transfer",
            value: "ACH_LOCAL",
          },
        ]?.find((ele) => ele.value === text)?.label
          ? [
              ...p_types,
              {
                label: "ACH - Local Transfer",
                value: "ACH_LOCAL",
              },
            ]?.find((ele) => ele.value === text)?.label
          : text,
    },
    {
      title: t("dateAndTime"),
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => <div>{moment(text).format("DD, MMM YY, HH:mm")}</div>,
    },
    {
      title: t("Amount"),
      dataIndex: "recipient_amount",
      key: "recipient_amount",
      render: (text, r) => (
        <div>
          {currencyMapper[r.currency_code]} {amountFormat(text)}
        </div>
      ),
    },
    {
      title: t("payment_status"),
      dataIndex: "status_code",
      key: "status_code",
      render: (text) => (
        <div className={`status status_${text?.toLowerCase()}`}>
          {text?.replace(/_/g, " ")}
        </div>
      ),
    },
    {
      title: "",
      key: "action",
      render: (_, record) => (
        <Space
          size="middle"
          className="myspace"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {scheduled ? (
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item
                    onClick={() => {
                      setRemoveSch(record.id);
                    }}
                  >
                    Remove Schedule
                  </Menu.Item>
                </Menu>
              }
            >
              <Button
                shape="default"
                icon={<MoreOutlined />}
                style={{ border: "none", color: "#5B87F9" }}
              />
            </Dropdown>
          ) : (
            record.flow_status === "unsubmitted" && (
              <Dropdown
                overlay={
                  <Menu>
                    <Menu.Item onClick={() => onClickEdit(record)}>
                      <img src={EditIcon} alt={""} className="action-logo" />
                      {t("edit")}
                    </Menu.Item>
                    <Menu.Item
                      onClick={() => {
                        setDeletePaymentModal(true);
                        setRecord(record);
                      }}
                    >
                      <img
                        src={DeleteIcon}
                        alt={"logo"}
                        className="action-logo"
                      />{" "}
                      {t("delete")}
                    </Menu.Item>
                  </Menu>
                }
              >
                <Button
                  shape="default"
                  icon={<MoreOutlined />}
                  style={{ border: "none", color: "#5B87F9" }}
                />
              </Dropdown>
            )
          )}
        </Space>
      ),
    },
  ];
  const handleGetAllPayments = () => {
    dispatch(
      get_all_payments({
        scheduled,
        view: user ? "" : "admin",
        type: "COMPANY_PAYMENTS",
        page_number: 1,
      })
    );
  };

  useEffect(() => {
    if (!openAddNew) {
      dispatch(
        headerTitles({
          title: user ? "Send Money" : "approvals",
          description: "Payments Transfer to Recipients Accounts",
        })
      );
      handleGetAllPayments();
    }
    if (location?.state?.send) {
      setOpenAddNew(location?.state?.send);
      history?.replace(location?.pathname);
    }
    if (location?.pathname) {
      let locationSplit = location?.pathname?.split("/");
      dispatch({
        type: SideMenu,
        payload: {
          firstNav: "/" + locationSplit?.[1],
          secondNav: "/" + locationSplit?.[2],
          showChildren: true,
          childrenMenu: "/" + locationSplit?.[3],
        },
      });
    }
  }, [location.pathname, openAddNew]);

  const sort_opts = (
    <Menu onClick={() => {}}>
      {sortOptions.map((ele, idx) => (
        <Menu.Item key={idx}>{ele.label}</Menu.Item>
      ))}
    </Menu>
  );

  const callDetails = (recd) => {
    endpoint
      .get(apis.getPaymentDetailsByID, {
        params: {
          id: recd?.id,
          module_type: "COMPANY_PAYMENTS",
        },
      })
      .then((res) => {
        if (res?.data?.error === false) {
          setTxnDetail(res?.data?.data);
          setTxnOpen(true);
        }
      })
      .catch(() => {});
  };

  return (
    <Row wrap={false} className="table-wrapper">
      {txnOpen && (
        <ViewPaymentModal
          open={txnOpen}
          txnDetail={txnDetail}
          onClose={() => {
            setTxnDetail({});
            setTxnOpen(false);
          }}
          user={user}
          callOnMount={() => {
            callDetails(txnDetail);
            handleGetAllPayments();
          }}
        />
      )}
      {removeSch && (
        <RemovePayment
          type={"remove_sch"}
          id={removeSch}
          onClose={() => {
            setRemoveSch("");
          }}
          open={removeSch}
        />
      )}
      <Col flex="auto" className="table-wrapper-col">
        {openAddNew ? (
          <SendMoney
            setOpenAddNew={setOpenAddNew}
            from={location?.state?.from}
          />
        ) : (
          <div className="flex-grow">
            <TableHeader
              noDD={true}
              noFilter={true}
              noAddNewBtn={!user}
              handleAddButton={handleAddButton}
              setOpenAddNew={setOpenAddNew}
              buttonLabel={t("Send Money")}
              sortOptions={sort_opts}
              hasWriteAccess={hasWriteAccess}
              onSearch={(val) => {
                setSearch(val);
                dispatch(
                  get_all_payments({
                    scheduled,
                    view: user ? "" : "admin",
                    type: "COMPANY_PAYMENTS",
                    search_key: val,
                    page_number: 1,
                  })
                );
              }}
            />
            <div
              style={{ paddingTop: "22px" }}
              className={loading ? "shimmer" : ""}
            >
              <TableComponent
                columns={columns}
                data={Array.isArray(data) ? data : []}
                hasCheckbox={false}
                loading={loading}
                rowKey="bill_no"
                pagination={{
                  total: totalRecords || 0,
                  current: page,
                  onChange: (pagee) => {
                    dispatch(
                      get_all_payments({
                        scheduled,
                        view: user ? "" : "admin",
                        type: "COMPANY_PAYMENTS",
                        page_number: pagee,
                        search_key: search,
                      })
                    );
                    setPage(pagee);
                  },
                }}
                scroll={{
                  x: 1300,
                }}
                handleRowClick={(_e, rec) => {
                  setTxnDetail(rec);
                  setTxnOpen(true);
                }}
              />
            </div>
          </div>
        )}
        {deletePaymentModal && (
          <Modal
            title="Delete"
            className="disconnect-modal"
            width={470}
            open={deletePaymentModal}
            onCancel={handleCancel}
            maskClosable={false}
            closeIcon={<CloseCircleOutlined style={{ fontSize: "1.5rem" }} />}
          >
            <>
              <p className="modal-text">{t("deletePayment")}</p>
              <div
                className="form-btm mt3"
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Button
                  type="primary"
                  className="pp-main-button cursor"
                  onClick={handleConfirm}
                  disabled={deletePaymentResponse?.loading}
                >
                  <span>{t("confirm")}</span>
                </Button>
                <Button
                  className="pp-secondary-button cursor"
                  style={{ marginLeft: "2rem" }}
                  onClick={handleCancel}
                >
                  <span>{t("cancel")}</span>
                </Button>
              </div>
            </>
          </Modal>
        )}
      </Col>
    </Row>
  );
};

export default BenificiaryTransfers;
