import { Form, Input, Typography, message } from "antd";
import jwt_decode from "jwt-decode";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { Is_Login, Local_Variables, Login } from "../../actions/auth";
import { RouteConstants } from "../../utils";
import { menu } from "../OnBoarding/Auth/BusinessKYC/steps";
import OTPForm from "../OnBoarding/Auth/SignUpFrom/OTPForm";
import { login } from "./../../actions/auth";
import { LOGO } from "../../config/Images";
import axios from "axios";
const { Text, Title } = Typography;

message.config({
  maxCount: 3,
});

async function loadPublicKey(pem) {
  // Convert PEM to ArrayBuffer
  const binaryDerString = window.atob(pem.split("\n").slice(1, -1).join(""));
  const binaryDer = str2ab(binaryDerString);

  // Import the public key in the correct format
  return window.crypto.subtle.importKey(
    "spki",
    binaryDer,
    {
      name: "RSA-OAEP",
      hash: { name: "SHA-256" }, // Use SHA-256 as the hashing algorithm for OAEP
    },
    true,
    ["encrypt"]
  );
}

// Helper to convert string to ArrayBuffer
function str2ab(str) {
  const buf = new ArrayBuffer(str.length);
  const bufView = new Uint8Array(buf);
  for (let i = 0, strLen = str.length; i < strLen; i++) {
    bufView[i] = str.charCodeAt(i);
  }
  return buf;
}

// Function to encrypt using the public key
async function encryptPassword(password, publicKeyPem) {
  const publicKey = await loadPublicKey(publicKeyPem);
  const enc = new TextEncoder();
  const encrypted = await window.crypto.subtle.encrypt(
    {
      name: "RSA-OAEP",
    },
    publicKey,
    enc.encode(password)
  );

  // Return encrypted data as Base64
  return window.btoa(
    String.fromCharCode.apply(null, new Uint8Array(encrypted))
  );
}

export function isValidEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

const LoginForm = ({ isCorporate }) => {
  // let [isLoading, setIsLoading] = React.useState(false);
  let [isEmp, setIsEmp] = React.useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [otp, setOtp] = React.useState({
    otpSent: false,
    otp: "",
    otp_token: "",
  });
  const loginResponse = useSelector((state) => state.auth.login || {});
  const responseLogin = useSelector((state) => state?.auth?.localVariables);

  React.useEffect(() => {
    if (loginResponse?.data?.error == false) {
      if (
        loginResponse?.data?.data?.auth_token &&
        loginResponse?.data?.data?.web_access_allowed !== false
      ) {
        message.success(
          <span className="messageText">{loginResponse.data.message}</span>
        );
        localStorage.setItem(
          "tokenPayApi",
          loginResponse?.data?.data?.auth_token
        );
        localStorage.setItem("country", loginResponse?.data?.data?.country);
        localStorage.setItem("token", loginResponse?.data?.data?.auth_token);
        localStorage.setItem("name", loginResponse?.data?.data?.name);
        localStorage.setItem("role", loginResponse?.data?.data?.role);
        localStorage.setItem(
          "portal_access",
          loginResponse?.data?.data?.portal_access
        );
        localStorage.setItem("is_admin", loginResponse?.data?.data?.is_admin);
        localStorage.setItem("user", loginResponse?.data?.data?.user);
        localStorage.setItem(
          "employee_id",
          loginResponse?.data?.data?.employee_id
        );
        localStorage.setItem("user_id", loginResponse?.data?.data?.user_id);
        localStorage.setItem(
          "time_format",
          loginResponse?.data?.data?.time_format
        );
        localStorage.setItem(
          "is_super_admin",
          loginResponse?.data?.data?.is_super_admin
        );
        localStorage.setItem(
          "is_kyb_completed",
          loginResponse?.data?.data?.is_kyb_completed
        );
        localStorage.setItem(
          "isSalesOrderView",
          loginResponse?.data?.data?.view_sales_orders
        );
        localStorage.setItem(
          "isCreditNotesView",
          loginResponse?.data?.data?.display_credit_notes
        );

        localStorage.setItem(
          "menu",
          JSON.stringify(
            menu
              ? menu
              : loginResponse?.data?.data?.menu
              ? loginResponse?.data?.data?.menu
              : {}
          )
        );
        localStorage.setItem(
          "base_currency",
          JSON.stringify(loginResponse?.data?.data?.base_currency)
        );
        try {
          const token = jwt_decode(loginResponse?.data?.data?.auth_token);

          localStorage.setItem("user_id_payApi", token.user_id);
          localStorage.setItem("company_id", token.company_id);
          localStorage.setItem("company_name", token.company_name);
        } catch (error) {
          console.error(error);
        }

        dispatch({
          type: Local_Variables,
          payload: {
            is_admin:
              loginResponse?.data?.data?.is_admin ||
              loginResponse?.data?.data?.is_super_admin,
          },
        });
        dispatch({
          type: Is_Login,
          payload: {
            is_login: true,
          },
        });
        window.location.href = "/dashboard";
      } else if (loginResponse?.data?.data?.token) {
        setOtp((dets) => ({
          ...dets,
          otpSent: true,
          otp: "",
          otp_token: loginResponse?.data?.data?.token,
        }));
      } else {
        loginResponse.data.web_access_allowed === false
          ? message.error(
              <span className="messageText">
                {"You are not authorized to access web portal."}
              </span>
            )
          : message.error(
              <span className="messageText">
                {loginResponse?.data?.errors ?? loginResponse?.data?.message}
              </span>
            );
      }

      dispatch({
        type: Login.RESET,
      });
    } else {
      if (
        loginResponse?.data?.errors ||
        loginResponse?.data?.message ||
        loginResponse?.message
      ) {
        message.error(
          <span className="messageText">
            {loginResponse?.data?.errors ??
              loginResponse?.data?.message ??
              loginResponse?.message}
          </span>
        );
        dispatch({
          type: Login.RESET,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginResponse]);
  React.useEffect(() => {
    if (responseLogin?.is_login) {
      // history.push("/two-step-verify");
      dispatch({
        type: Is_Login,
        payload: {
          is_login: false,
        },
      });
    }
  }, [responseLogin?.is_login]);

  const handleSubmit = async (values) => {
    const payload = {};

    ["email", "password"].forEach((item) => {
      payload[item] = values[item];
    });
    if (isEmp) {
      payload.params = {
        otp: true,
      };
      dispatch(login(payload));
    } else {
      if (window.crypto.subtle) {
        const publicKey = `-----BEGIN PUBLIC KEY-----
        MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAxzNtwSrdb2cev3VIDbo6
        s84F+goHm1YqwXdw2ToyI97WETmTgeRVauPZGivjwuRskBGGQwu25G5CfpHLXS3P
        /iC2XsQj1qa+2HzGMwY198IKs0I66LAxYNRo/0ARg4z3ow8X28qLX9E4LnhUqni4
        BNVN0SIOZrMaqrllAEgxZglihBlY3EMVfZG7rsV6Vgz8INKJczCWUbcTg2NHl7r1
        ZF2AACO4guM5oKgf/QKvAxZnc4T+0VhD2rTE2uF3+s9x0TV3UINyw2/rXyF1dW9J
        CG5cE2dzRm45dKVAJUiT85jpEEi4v9DqOqUf6flUpIrA8y6WeDeuWyL3+574CCMy
        WQIDAQAB
        -----END PUBLIC KEY-----`;
        const encrypted = await encryptPassword(payload.password, publicKey);
        const res = await axios.get("https://api.ipify.org/?format=json");
        payload.password = encrypted;
        if (res?.data) {
          payload.ip_address = res?.data?.ip;
          payload.login_country = res?.data?.country_name;
          payload.device_source = navigator.userAgent;
        }
        if (!isValidEmail(payload.email)) {
          payload.username = payload.email;
          delete payload.email;
        }
        setOtp((dets) => ({
          ...dets,
          business_email: payload.email,
        }));
        dispatch(login({ ...payload, isCorporate }));
      }
    }
    // setIsLoading(true);
  };

  return (
    <div className="login-form">
      <div className="head-sec rtl-text-right mb2">
        <img src={LOGO} alt={"logo"} className="logo mb2" />
        {otp.otpSent ? (
          <>
            <Title level={3}>{t("two_step")}</Title>
            <Text>{t("enter_otp")}</Text>
          </>
        ) : (
          <>
            <Title level={3}>{t("welcomeBack")}</Title>
            <Text>{t("signIntoAcc")}</Text>
          </>
        )}
      </div>
      <Form onFinish={handleSubmit} layout="vertical">
        {otp.otpSent ? (
          <OTPForm
            pageCount={2}
            source={"LOGIN"}
            isEmp={isEmp}
            otp={otp}
            userData={otp}
            setOtp={setOtp}
            setPageCount={() => {
              setIsEmp(false);
              setOtp({
                otpSent: false,
                otp: "",
              });
            }}
          />
        ) : isEmp ? (
          <Form.Item
            label={t("emailAddr")}
            name="email"
            rules={[
              {
                type: "email",
                message: t("email_valid"),
                validateTrigger: ["onSubmit"],
              },
              {
                required: true,
                message: t("email_required"),
                validateTrigger: ["onSubmit"],
              },
            ]}
          >
            <Input placeholder={t("enter_email")} autoComplete="off" />
          </Form.Item>
        ) : (
          <>
            <Form.Item
              label={t("emailAddr")}
              name="email"
              rules={[
                // {
                //   type: "email",
                //   message: t("email_valid"),
                //   validateTrigger: ["onSubmit"],
                // },
                {
                  required: true,
                  message: t("email_required"),
                  validateTrigger: ["onSubmit"],
                },
              ]}
            >
              <Input
                placeholder={t("Enter username or email")}
                autoComplete="off"
              />
            </Form.Item>
            <Form.Item
              label={t("password")}
              name="password"
              placeholder={t("enter_pwd")}
              rules={[
                {
                  required: true,
                  message: t("password_required"),
                },
              ]}
            >
              <Input.Password placeholder={t("enter_pwd")} />
            </Form.Item>
          </>
        )}
        {otp.otpSent ? null : (
          <Form.Item>
            <button type="submit" className="send-btn">
              {loginResponse?.loading ? (
                <ClipLoader
                  loading={loginResponse?.loading}
                  size={20}
                  color="#ffffff"
                />
              ) : (
                t("login")
              )}
            </button>
            <Link to={RouteConstants.FORGOTPASSWORD}>
              <span className="text-dim ml2 cursor">
                {t("forgotPassword")}?
              </span>
            </Link>
          </Form.Item>
        )}
      </Form>
      {otp.otpSent ? null : (
        <>
          {/* <div
            className="cursor emp-text"
            onClick={() => {
              setIsEmp((b) => !b);
            }}
          >
            Login with {isEmp ? "Username or Email" : "OTP"}
          </div> */}

          <div className="mt5 d-flex-center mx-auto">
            <div className="text-dim footer-note login-footer t-center">
              {/* <div className="mb2">
                <span className="footer-note">
                  Don't have an account ?{" "}
                  <b
                    className="cursor"
                    style={{ color: "#212121" }}
                    onClick={() => {
                      history.push("/sign-up");
                    }}
                  >
                    Sign Up
                  </b>
                </span>
              </div>
              <div className="mb2">
                <span className="or fw-500">
                  {t("by_logging")}{" "}
                  <span className="cursor login_terms or">
                    {t("terms_conditions")}{" "}
                  </span>{" "}
                  {t("privacy_policy")}
                </span>
              </div> */}
              <div>
                <span className="or dim-login-text fw-500">
                  {t("Zenus_footer_text")}
                </span>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default LoginForm;
