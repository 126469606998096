import { useEffect, useRef } from "react";
import { logout } from "../actions/auth";
import { useDispatch } from "react-redux";

/**
 * React hook that listens to `beforeunload` window event.
 * @function
 * @param {?function(BeforeUnloadEvent): ?string} handler - Event listener
 *   called on `beforeunload` window event. It activates a confirmation dialog
 *   when `event.preventDefault()` is called or a string is returned.
 */
export const useBeforeunload = (handler) => {
  const enabled = typeof handler === "function";
  const dispatch = useDispatch();
  // Persist handler in ref
  const handlerRef = useRef(handler);

  useEffect(() => {
    handlerRef.current = handler;
  });

  useEffect(() => {
    if (enabled && window.location.hostname !== "localhost") {
      const listener = (event) => {
        const returnValue = handlerRef.current(event);

        if (typeof returnValue === "string") {
          // Trigger the confirmation dialog
          event.preventDefault();
          return (event.returnValue = returnValue);
        }

        // Handle default preventions
        if (event.defaultPrevented) {
          return (event.returnValue = "");
        }
      };

      const handleUnload = () => {
        // This runs only when the page is actually unloaded
        dispatch(logout());
        localStorage.clear();
      };

      window.addEventListener("beforeunload", listener);
      window.addEventListener("unload", handleUnload);

      return () => {
        window.removeEventListener("beforeunload", listener);
        window.removeEventListener("unload", handleUnload);
      };
    }
  }, [enabled, dispatch]);
};
