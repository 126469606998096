import { Modal } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { p_types } from "../Payments/SendMoney/Recipients";
import credit from "../../assets/icons/credit.png";
import debit from "../../assets/icons/debit.png";
import eye from "../Payments/SendMoney/assets/eye.png";
import { currencyMapper } from "../../config/helper";
import { AccountDisplay } from "./AccountCard";
import usePayAxios from "../../config/useAxios";
import { gridApis } from "../../config/GridApis";
import { amountFormat } from "../../utils";

const TxnDetailModal = ({ open, onClose, txnDetail: data }) => {
  const [activeTab, setActiveTab] = useState("details");
  const [isPreviewOpen, setIsPreviewOpen] = useState({
    open: false,
    url: "",
    fileType: "",
  });
  const { onCall, data: attach } = usePayAxios({
    api: gridApis.fetchTransactionAttachments,
    method: "get",
  });
  useEffect(() => {
    if (data.fintech_payment_id)
      onCall({
        params: {
          fintech_payment_id: data.fintech_payment_id,
        },
      });
  }, [data]);

  return (
    <>
      <Modal
        visible={isPreviewOpen?.open}
        onCancel={() => {
          setIsPreviewOpen({
            open: false,
            url: "",
            fileType: "",
          });
        }}
        footer={null}
        width="80%"
        centered
        className="preview_section_modal"
      >
        {isPreviewOpen?.fileType == "pdf" ? (
          <div style={{ width: "100%", height: "100vh" }}>
            <iframe
              src={isPreviewOpen?.url}
              allow="fullscreen"
              style={{ width: "100%", height: "100%", margin: "auto" }}
              title="description"
            ></iframe>
          </div>
        ) : (
          <div
            style={{ width: "100%", height: "100vh" }}
            className="d-flex-center"
          >
            <img
              src={isPreviewOpen?.url}
              alt="preview image"
              style={{ maxWidth: "100%", maxHeight: "100%", margin: "auto" }}
            />
          </div>
        )}
      </Modal>
      <Modal
        className="right-alinged-modal alerts txn-dt"
        title={
          <label
            style={{
              color: "#363636",
              paddingBottom: 10,
              fontSize: 21,
            }}
          >
            Transaction Details
          </label>
        }
        visible={open}
        onCancel={onClose}
      >
        <div className="flex-between txn-head">
          <div className="left">
            <span
              className="circle"
              style={{
                "--bg-txn":
                  data.transaction_type === "credit" ? "#EDFFEA" : "#FFEAEA",
              }}
            >
              <img
                src={data.transaction_type === "credit" ? credit : debit}
                width={10}
              />{" "}
            </span>
            <label>
              {data.transaction_type === "credit"
                ? "Recieved Money"
                : "Sent Money"}
              <br />
              {data.transaction_type === "credit" ? "from " : "to "}

              <b>
                {data.sender_account_details?.account_name} (****
                {data?.counterparty_account_number?.slice?.(-4)})
              </b>
              <br />
              <span className={`status status_${data.status?.toLowerCase()}`}>
                {data.status}
              </span>
            </label>
          </div>
          <div className="right">
            <h2>
              {currencyMapper[data?.currency]} {amountFormat(data.amount)}
            </h2>
          </div>
        </div>
        <ul className="tab-menu">
          <li
            className={activeTab === "details" ? "active" : ""}
            onClick={() => setActiveTab("details")}
          >
            Details
          </li>
          {attach?.data?.attachments?.length ? (
            <li
              className={activeTab === "attach" ? "active" : ""}
              onClick={() => setActiveTab("attach")}
            >
              Attachments
            </li>
          ) : null}
          {/* <li
          className={activeTab === "history" ? "active" : ""}
          onClick={() => setActiveTab("history")}
        >
          History
        </li>
        <li
          className={activeTab === "comments" ? "active" : ""}
          onClick={() => setActiveTab("comments")}
        >
          Comments
        </li> */}
        </ul>
        {activeTab === "attach" && (
          <div className="imgs-wrap">
            {attach?.data?.attachments?.map((ele) => {
              return (
                <div
                  style={{
                    position: "relative",
                  }}
                >
                  <div className="img">
                    <img src={ele.url} />
                    <img
                      src={eye}
                      alt=""
                      className="file-eye"
                      onClick={() => {
                        setIsPreviewOpen({
                          open: true,
                          url: ele.url,
                          fileType: ele.content_type?.split("/")[1],
                        });
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {activeTab === "details" && (
          <>
            <div className="company-details txn-details">
              <h3> Transaction Details </h3>
              <div className="item">
                <label>Recipient Account Name</label>
                <span>
                  {data.counterparty_account_name ??
                    data.beneficiary_details?.beneficiary_name}
                </span>
              </div>
              <div className="item">
                <label>Recipient Account</label>
                <span>
                  <AccountDisplay
                    accountNumber={data.overparty_account_number}
                    showEye={true}
                  />
                </span>
              </div>
              <div className="item">
                <label>Sender Account Name</label>
                <span>{data.sender_account_details?.account_name}</span>
              </div>
              <div className="item">
                <label>Sender Account</label>
                <span>
                  <AccountDisplay
                    accountNumber={data.counterparty_account_number}
                    showEye={true}
                  />
                </span>
              </div>

              <div className="item">
                <label>Transfer Mode</label>
                <span>
                  {
                    p_types?.find((ele) => ele.value === data.transfer_mode)
                      ?.label
                  }
                </span>
              </div>
              <div className="item">
                <label>Transaction Reference Number</label>
                <span>{data.transaction_ref_no}</span>
              </div>
              <div className="item">
                <label>Transaction Type</label>
                <span
                  className="i-flex-center"
                  style={{
                    color: "#333",
                    textTransform: "capitalize",
                  }}
                >
                  <img
                    src={data.transaction_type === "credit" ? credit : debit}
                    width={10}
                  />{" "}
                  {data.transaction_type}
                </span>
              </div>
              <div className="item">
                <label>Description</label>
                <span>{data.description}</span>
              </div>
              <div className="item">
                <label>Status</label>
                <span
                  className={`status status_${data.status?.toLowerCase()}`}
                  style={{
                    paddingRight: 0,
                  }}
                >
                  {data.status}
                </span>
              </div>
              <div className="item">
                <label>Transaction Date</label>
                <span>{moment(data.created_at).format("MM/DD/YY")}</span>
              </div>
            </div>
          </>
        )}
      </Modal>
    </>
  );
};

export default TxnDetailModal;
