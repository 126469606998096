import React, { useRef } from "react";
import { Controller } from "react-hook-form";
import Dropdown from "../Dropdown";

const FormSelect = ({
  errors,
  errorMessage,
  label,
  required,
  placeholder,
  inline,
  onCreate,
  onSearch,
  hideCreateOption = false,
  options,
  onFocus,
  onClick,
  allowClear = false,
  onAddItem,
  labelColor,
  mode,
  // onChange : onChangeOutside,
  onChange,
  disabled,
  value: parentVal,
  filterKey,
  noPaddingTop = false,
  notFoundContent,
  style,
  noDD,
  ...restOfProps
}) => {
  const timeoutRef = useRef();
  console.log("form select time out ref", {
    restOfProps,
    errorMessage,
    errors,
  });
  return (
    <div>
      <Controller
        {...restOfProps}
        shouldUnregister={true}
        render={({ field: { onChange, value, name } }) => {
          return (
            <div>
              {label ? (
                <span
                  className="form-label"
                  style={{
                    fontSize: "14px",
                    lineHeight: "24px",
                    color: labelColor
                      ? (errors && errors[restOfProps?.name]) || errorMessage
                        ? "#E0272B"
                        : "#3B3B3B"
                      : "#3B3B3B",
                  }}
                >
                  {label}
                  {required && (
                    <sup
                      style={{
                        color: "red",
                        fontSize: "14px",
                        position: "relative",
                        top: "-1px",
                      }}
                    >
                      *
                    </sup>
                  )}
                </span>
              ) : null}
              <div style={label && !noPaddingTop ? { paddingTop: "10px" } : {}}>
                <Dropdown
                  className="pp-dropdown"
                  inline={inline}
                  label={label}
                  allowClear={allowClear}
                  placeholder={placeholder}
                  filterKey={filterKey}
                  onSearch={(e) => {
                    if (timeoutRef.current) {
                      clearTimeout(timeoutRef.current);
                    }

                    timeoutRef.current = setTimeout(() => {
                      onSearch && onSearch(e);
                    }, 600);
                  }}
                  onChange={onChange}
                  onAddItem={onAddItem}
                  mode={mode}
                  onCreate={onCreate}
                  options={options}
                  onFocus={onFocus}
                  onClick={onClick}
                  value={parentVal ?? value}
                  hideCreateOption={hideCreateOption}
                  disabled={disabled}
                  name={name}
                  notFoundContent={notFoundContent}
                  style={style}
                  noDD={noDD}
                />
              </div>
              {errors?.[restOfProps?.name]?.message || errorMessage ? (
                <div className="errorMsg">
                  {errors?.[restOfProps?.name]?.message || errorMessage}
                </div>
              ) : null}
            </div>
          );
        }}
      />
    </div>
  );
};

export default FormSelect;
