import React, { useEffect, useState } from 'react'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";
import info_icon from "../../assets/icons/Information_icon.svg"
import { Button, Modal, Select } from 'antd';
import { ExcelDateToJSDate } from '../../utils';

const UploadFileMaping = ({
  setColumnRows,
  fileContent,
  columnRows,
  columnHeader,
  setColumnHeader,
}) => {
  const { t } = useTranslation()
  const [formatModal, setFormatModal] = useState(false)

  const handleAddEntityList = (groupIndex) => {
    setColumnHeader((prevHeaders) => {
      const newHeaders = JSON.parse(JSON.stringify(prevHeaders));
      const newEntityList = newHeaders.page_details[groupIndex].entity_columns[0].entity_list
      newHeaders.page_details[groupIndex].entity_columns.push({
        entity_list: newEntityList?.map(entity => ({ ...entity, csv_column: "" })),
      });
      return newHeaders;
    });
  };

  const handleRemoveEntityList = (groupIndex, entityIndex) => {
    setColumnHeader((prevHeaders) => {
      const newHeaders = JSON.parse(JSON.stringify(prevHeaders));
      const entityColumns = newHeaders.page_details[groupIndex].entity_columns;
      if (entityColumns.length > 1) {
        entityColumns.splice(entityIndex, 1);
      }
      return newHeaders;
    });
  };
  return (
    <>
      <Modal
        title={t('data_formats')}
        visible={formatModal}
        onCancel={() => {
          setFormatModal(false)
        }}
        className={"center-aligned-modal"}
      >
        <>
          <div className='decimal_body d-flex flex-col'>
            <div className='table_headers flex-between'>
              <h4 className='w50 border_right p1'>Date Type</h4>
              <h4 className='w50 p1'>Default Format</h4>
            </div>
            <div className='table_body flex-between'>
              <span className='w50 p1 border_right'>Decimal Foramt</span>
              <Select
                className="w50 p1"
                options={[
                  { label: "1234567.89", value: "1" },
                  { label: "1,234,567.89", value: "2" },
                  { label: "1 234 567.89", value: "3" },
                  { label: "1234567,89", value: "4" },
                  { label: "1.234.567,89", value: "5" },
                  { label: "1 234 567,89", value: "6" },
                ]}
                onChange={(e) => {
                  // setDateSelect(e);
                }}
              />
            </div>
          </div>
          <br />
          <Button
            key="1"
            className="formButton mt1"
          // disabled={deleteRoleResponse?.loading}
          >
            {t('save')}
          </Button>
          <Button
            key="2"
            className="cancelButton ml1 mt1"
            onClick={() => {
              setFormatModal(false)
            }}
          >
            {t('cancel')}
          </Button>
        </>
      </Modal>
      <div className='upload_file_mapped_section'>
        <div className="map-fields">
          <div className="w60">
            <div>
              <span className="flex-start">
                <div className='select_file'>{t('ur_selected_file')}</div>
                <div className='select_file_name'>
                  {fileContent.name}
                </div>
              </span>
              <div className='best_match mb1'>
                <img src={info_icon} alt='info icon' className='mr1' />
                <span>{t('best_match_selected')}</span>
              </div>
            </div>
            {/* <div>
              <span className="flex-start">
                <div className='select_file'>{t('ur_selected_file')}</div>
                <div className='select_file_name'>
                  {fileContent.name}
                </div>
              </span>
              <div className='best_match mb1'>
                <img src={info_icon} alt='info icon' className='mr1' />
                <span>{t('best_match_selected')}</span>
              </div>
              <div className='default d-flex flex-col pt1 pb1 pl2 pr2'>
                <div className='default_header flex-between'>
                  <h4 className='default_data'>Default Data Formats</h4>
                  <div className='d-flex edit' onClick={() => {
                    setFormatModal(true)
                  }}>
                    <img src="" alt='' />
                    <span>Edit</span>
                  </div>
                </div>
                <div className='d-flex flex-col decimal_format'>
                  <span className=''>Decimal Format</span>
                  <span>255666</span>
                </div>
              </div>
            </div> */}
            <div>
              {
                columnHeader?.page_details?.map((item, index) => {
                  return (
                    <div className="fields" key={`${item?.group_name}_${index}`}>
                      <div className='fields_header flex-between'>
                        <div>{item?.group_name}</div>
                      </div>
                      <div className="flex-between header">
                        <div>{"Predefined fields"}</div>
                        <div>{"Imported File Headers"}</div>
                      </div>
                      {item?.entity_columns?.map((element, columnsIndex) => {
                        return (
                          <div key={`${item?.group_name}_${index}_${columnsIndex}`}>
                            {columnsIndex > 0 ? <div className='flex-end remove'>
                              <button
                                className="remove_button"
                                onClick={() => {
                                  handleRemoveEntityList(index, columnsIndex)
                                }}
                              >
                                <DeleteOutlined />
                              </button>
                            </div> : null}
                            {element?.entity_list?.map((ele, itemIndex) => (
                              <div className='mbHalf' key={`${ele?.entity_column_label}_${index}`}>
                                <div className="flex-between">
                                  <label htmlFor="grid-state" style={{ color: "#2A254F" }}>
                                    {ele.entity_column_label}
                                    {ele?.is_mandatory && (
                                      <sup style={{
                                        color: "red",
                                        fontSize: "14px",
                                        position: "relative",
                                        top: "-1px"
                                      }}>*</sup>
                                    )}
                                  </label>
                                  <Select
                                    allowClear={true}
                                    id="grid-state"
                                    className="w35"
                                    showSearch
                                    options={fileContent?.columns?.map(
                                      (ele) => ({
                                        label: ele,
                                        value: ele,
                                      })
                                    )}
                                    onChange={(e) => {
                                      setColumnRows({
                                        ...columnRows,
                                        [ele.entity_column]: e,
                                      });
                                      setColumnHeader((prev) => {
                                        const newState = JSON.parse(JSON.stringify(prev));
                                        newState.page_details[index].entity_columns[columnsIndex].entity_list[itemIndex].csv_column = e;
                                        if (newState.page_details[index].entity_columns[columnsIndex].entity_list[itemIndex].data_type === "string") {
                                          newState.data_list.forEach(dl => {
                                            dl[e] = dl[e]?.toString()
                                          })
                                        } else if (newState.page_details[index].entity_columns[columnsIndex].entity_list[itemIndex].data_type === "number") {
                                          newState.data_list.forEach(dl => {
                                            dl[e] = !isNaN(dl[e]) ? Number(dl[e]) : dl[e];
                                          })
                                        } else if (newState.page_details[index].entity_columns[columnsIndex].entity_list[itemIndex].data_type === "date") {
                                          newState.data_list.forEach(dl => {
                                            dl[e] = dl[e] ? ExcelDateToJSDate(dl[e]) : dl[e];
                                          })
                                        }
                                        return newState;
                                      });
                                    }}
                                    placeholder={`select ${ele.entity_column_label}`}
                                    value={columnHeader.page_details[index].entity_columns[columnsIndex].entity_list[itemIndex].csv_column ? columnHeader.page_details[index].entity_columns[columnsIndex].entity_list[itemIndex].csv_column : null}
                                  />
                                </div>
                                {console.log(columnHeader.page_details[index].entity_columns[columnsIndex].entity_list[itemIndex])}
                                {/* {errors[`${ele?.entity_column}_${columnsIndex}`] && submitCount > 1 && <div style={{ color: "#ff0000", fontSize: "1rem" }}>{`${errors[`${ele?.entity_column}_${columnsIndex}`]?.message}`}</div>} */}
                                {/* {ele?.is_mandatory && !columnHeader.page_details[index].entity_columns[columnsIndex].entity_list[itemIndex].csv_column && <div style={{ color: "#ff0000", fontSize: "1rem" }}>{`${ele?.entity_column_label} is required`}</div>} */}
                              </div>
                            ))}
                            {item.is_multiple && (
                              <div className='flex-end add_more mt2'>
                                {item?.entity_columns?.length === columnsIndex + 1 ? (
                                  <button
                                    className="mb1 add_button"
                                    onClick={() => {
                                      handleAddEntityList(index)
                                    }}
                                  >
                                    <PlusOutlined />{t(' Add')}
                                  </button>
                                ) : null
                                }
                              </div>
                            )}
                            {item?.entity_columns?.length > 1 && item?.entity_columns?.length !== columnsIndex + 1 ? <hr className='mb1' /> : null}
                          </div>
                        );
                      })}
                    </div>
                  );
                })
              }


            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadFileMaping