import { Badge, Col, Row } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { headerTitles } from "../../actions/masterdata/masterdata";
import { get_all_payments } from "../../actions/z_accounts";
import credit from "../../assets/icons/credit.png";
import debit from "../../assets/icons/debit.png";
import { gridApis } from "../../config/GridApis";
import usePayAxios from "../../config/useAxios";
import BankShow from "../Common/BankShow";
import TableComponent from "../Common/TableComp/TableComp";
import { p_types } from "../Payments/SendMoney/Recipients";
import CustomStatement from "./StatementSelect";
import { saveAs } from "file-saver";
import { currencyMapper } from "../../config/helper";
import { amountFormat } from "../../utils";
import { app_loading } from "../../actions/auth";

const Banks = ({}) => {
  const dispatch = useDispatch();
  const [isFilterSelected, setIsFilterSelected] = useState(false);
  const { onCall, loading: pdfLoading } = usePayAxios({
    type: "grid",
    api: gridApis.fetchStatementsPDF,
    method: "post",
  });

  const [account, setAccount] = useState("");
  const [accountObj, setAccountObj] = useState({});
  const [filterValue, setFilterValue] = useState("all");
  const [filterDetails, setFilterDetails] = useState({
    date_from: null,
    date_to: null,
  });
  const [page, setPage] = useState(1);
  const { t } = useTranslation();
  const data = useSelector(
    (state) => state?.z_accs?.all_payments?.data?.data ?? []
  );
  const error = useSelector((state) => state?.z_accs?.all_payments?.data ?? []);
  const loading = useSelector((state) => state?.z_accs?.all_payments?.loading);
  const totalRecords = useSelector(
    (state) => state?.z_accs?.all_payments?.data?.total_count ?? []
  );
  useEffect(() => {
    dispatch(
      headerTitles({
        title: t("statements"),
        description: t("find_records"),
      })
    );
    dispatch(
      get_all_payments({
        txns: true,
      })
    );
  }, []);

  const columns = [
    {
      title: t("Date and Time"),
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => <div>{moment(text).format("DD, MMM YY, HH:mm")}</div>,
    },
    {
      title: t("txn_id"),
      dataIndex: "transaction_ref_no",
      key: "transaction_ref_no",
      render: (text) => (
        <div title={text}>
          {text?.substr(0, 7)}...{text?.substr(-4)}
        </div>
      ),
    },
    {
      title: t("beneficiary_name"),
      dataIndex: "beneficiary_details",
      key: "beneficiary_details",
      render: (text, rec) =>
        text?.beneficiary_name
          ? text?.beneficiary_name
          : rec?.counterparty_account_name,
    },
    {
      title: t("beneficiary_account"),
      dataIndex: "counterparty_account_number",
      key: "counterparty_account_number",
      render: (text) => <div title={text}>****{text?.substr(-4)}</div>,
    },
    {
      title: t("Amount"),
      dataIndex: "amount",
      key: "amount",
      render: (text, rec) => (
        <div>
          {currencyMapper[rec.currency]}
          {amountFormat(text)}
        </div>
      ),
    },
    {
      title: "Payment Method",
      dataIndex: "transfer_mode",
      key: "transfer_mode",
      render: (text) => p_types?.find((ele) => ele.value === text)?.label,
    },
    {
      title: "Txn Type",
      dataIndex: "transaction_type",
      key: "transaction_type",
      render: (text) => (
        <span
          style={{
            color: "#333",
            textTransform: "capitalize",
          }}
        >
          <img
            style={{
              transform: "rotate(180deg)",
            }}
            src={text === "credit" ? credit : debit}
            width={10}
          />{" "}
          {text}
        </span>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => (
        <span className={`status status_${text?.toLowerCase()}`}>{text}</span>
      ),
    },
  ];

  const handleBankShowRefresh = () => {};
  return (
    <>
      {!isFilterSelected ? (
        <CustomStatement
          setSelectedAccount={setIsFilterSelected}
          account={account}
          setAccount={setAccount}
          setAccountObj={setAccountObj}
          setFilterDetails={setFilterDetails}
        />
      ) : (
        <>
          {error?.error && <div className="strip red">{error?.message}</div>}
          <Row wrap={false} className="table-wrapper">
            <Col flex="auto" className="table-wrapper-col">
              <div className="flex-grow">
                {filterValue === "all" ? (
                  <div className="mt1">
                    <BankShow
                      handleBankShowRefresh={handleBankShowRefresh}
                      handleBack={() => {
                        setIsFilterSelected(false);
                      }}
                      handleSearch={(val) => {
                        dispatch(
                          get_all_payments({
                            txns: true,
                            search_key: val ? val : undefined,
                          })
                        );
                      }}
                      setAccountObj={setAccountObj}
                      showClear={true}
                      dates={[filterDetails?.date_from, filterDetails?.date_to]}
                      accountId={accountObj?.id}
                      setFilterDetails={setFilterDetails}
                      callAction={(acc, s_date, e_date) => {
                        dispatch(
                          get_all_payments({
                            params: { account_id: acc },
                            txns: true,
                            filters: {
                              created_at: {
                                start_date: s_date,
                                end_date: e_date,
                              },
                            },
                          })
                        );
                        setFilterDetails({
                          date_from: s_date,
                          date_to: e_date,
                        });
                      }}
                      account={`${
                        accountObj?.account_name?.length > 8
                          ? `${accountObj?.account_name?.substr(0, 8)}...`
                          : accountObj?.account_name
                      }
                      - ****${accountObj?.account_number?.slice(-4)}`}
                      date={`${moment(filterDetails?.date_from).format(
                        "MMM DD,YY"
                      )} - ${moment(filterDetails?.date_to).format(
                        "MMM DD,YY"
                      )}`}
                      pdfLoading={pdfLoading}
                      onDownload={(type) => {
                        dispatch(app_loading(true));
                        onCall({
                          params: {
                            account_id: accountObj?.id,
                            format: type,
                          },
                          data: {
                            account_id: accountObj?.id,
                            statement_start_date: moment(
                              filterDetails?.date_from
                            ).format("YYYY-MM-DD"),
                            statement_end_date: moment(
                              filterDetails?.date_to
                            ).format("YYYY-MM-DD"),
                          },
                        }).then(async (res) => {
                          if (res?.data?.statement_file_url) {
                            const blob = await fetch(
                              res?.data?.statement_file_url
                            ).then((res) => res.blob());
                            saveAs(blob, res?.data?.file_name);
                          }
                          dispatch(app_loading(false));
                        });
                      }}
                    />
                  </div>
                ) : null}

                <div
                  className={loading ? "shimmer" : ""}
                  style={{ paddingTop: "15px" }}
                >
                  <TableComponent
                    columns={columns}
                    loading={loading}
                    data={Array.isArray(data) ? data : []}
                    hasCheckbox={false}
                    rowKey="bill_no"
                    pagination={{
                      total: totalRecords || 0,
                      current: page,
                      onChange: (pagee) => {
                        dispatch(
                          get_all_payments({
                            page_number: pagee,
                            txns: true,
                          })
                        );
                        setPage(pagee);
                      },
                    }}
                    callAction={(acc, s_date, e_date) => {
                      dispatch(
                        get_all_payments({
                          params: { account_id: acc },
                          txns: true,
                          filters: {
                            created_at: {
                              start_date: s_date,
                              end_date: e_date,
                            },
                          },
                        })
                      );
                      setFilterDetails({
                        date_from: s_date,
                        date_to: e_date,
                      });
                    }}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};
export default Banks;
