import { Col, Input, Modal, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { SideMenu } from "../../actions/masterdata/masterdata";
import plus_b from "../../assets/images/plus_circle_b.png";
import search from "../../assets/ippo_icons/search.png";
import { gridApis } from "../../config/GridApis";
import usePayAxios from "../../config/useAxios";
import { hasAccess } from "../../utils";
import AccTxnTable from "../Dashboard2/AccTxnTable";
import BankAccount from "../Dashboard2/BankAccountSec";
import back_arrow from "../Payments/SendMoney/assets/back_arrow.png";
import Transfer from "./Transfer";
import { get_all_payments } from "../../actions/z_accounts";

const AccountDetail = () => {
  const location = useLocation();
  const history = useHistory();
  const [openTransfer, setOpenTransfer] = useState(false);
  const [detailView, setDetailView] = useState(false);
  const dispatch = useDispatch();
  const { onCall } = usePayAxios({
    api: gridApis.getByID,
    method: "get",
  });

  useEffect(() => {
    const locationSplit = location.pathname?.split("/");
    dispatch({
      type: SideMenu,
      payload: {
        firstNav: "/" + locationSplit?.[1],
        secondNav: "",
        showChildren: false,
        childrenMenu: "",
      },
    });
    onCall({
      params: {
        account_id: location?.state?.account?.id,
      },
    }).then((res) => {
      setDetailView(res?.data);
    });
  }, []);

  return (
    <div className="account-details">
      <Modal
        visible={openTransfer}
        className="right-alinged-modal"
        onCancel={() => {
          setOpenTransfer(false);
        }}
        title={"Transfer"}
      >
        {openTransfer && (
          <Transfer
            setOpenAddNew={setOpenTransfer}
            from={detailView?.id}
            cb={() => {
              onCall({
                params: {
                  account_id: location?.state?.account?.id,
                },
              }).then((res) => {
                setDetailView(res?.data);
              });
              dispatch(
                get_all_payments({
                  params: {
                    account_id: location?.state?.account?.id,
                  },
                  txns: true,
                })
              );
            }}
          />
        )}
      </Modal>
      <div style={{ padding: "20px" }}>
        <Row justify="space-between" align="middle">
          <Col>
            <span
              className="back-link cursor"
              onClick={() => {
                history.goBack();
              }}
            >
              <img src={back_arrow} width={10} className="mrHalf" />
              Back
            </span>
          </Col>
          <Col>
            <Row gutter={16} align="middle">
              <Col>
                <div>
                  <Input
                    className="pp-input"
                    size="large"
                    id="search-input"
                    placeholder="Search..."
                    prefix={
                      <img src={search} alt="" style={{ height: "14px" }} />
                    }
                    style={{
                      backgroundColor: "#FAFAFA",
                      border: "1px solid #EAEAEA",
                      borderRadius: "5px",
                      minWidth: "22rem",
                    }}
                  />
                </div>
              </Col>
              <Col>
                <button
                  className="send-btn"
                  type="primary"
                  onClick={() => {
                    setOpenTransfer(true);
                  }}
                >
                  Transfer Funds
                </button>
              </Col>
              <Col>
                <button
                  className="send-btn"
                  onClick={() => {
                    history.push("/transfers/beneficiary/payments", {
                      from: location?.state?.account?.id,
                      send: true,
                    });
                  }}
                >
                  <img src={plus_b} width={15} />
                  Send Money
                </button>
              </Col>

              {/* Grid Icon */}
              {/* <Col style={{ paddingRight: 0 }}>
                <button
                  className="sec-btn"
                  onClick={() => {
                    setShowList((b) => !b);
                  }}
                >
                  {showList ? <UnorderedListOutlined /> : <AppstoreOutlined />}
                </button>
              </Col> */}
            </Row>
          </Col>
        </Row>
      </div>
      {/* {detailView ? ( */}
      <>
        <BankAccount acc={detailView} fromDetail={true} />
        <div className="dashboard-title">
          <span className="card-title">Recent Transactions</span>
        </div>
        <AccTxnTable
          hasWriteAccess={hasAccess("beneficiary_accounts", "write")}
          account_number={detailView?.id}
          fromDetail
        />
      </>
      {/*  ) : (
         <>
           {account?.balances?.map((balance) => (
             <div className="bal card w100 mb2">
               <Row
                 style={{
                   gap: "4rem",
                   alignItems: "center",
                 }}
                 className="bal-details"
               >
                 <Col
                   style={{
                     position: "relative",
                     marginRight: 40,
                   }}
                 >
                   <div className="bal-sec">
                     <Text className="avl-bal">Available Balance</Text>
                     <h3>
                       {`${
                         currencyMapper[balance?.currencyCode] ?? "$"
                       } ${amountFormat(balance?.availableBalanceAmount)}`}
                     </h3>
                     <Text className="avl-bal">USD • Main</Text>
                   </div>
                   <div className="v-line" />
                 </Col>
                 <Col>
                   <Text className="avl-bal">Type</Text>
                   <div className="value">{account?.accountTypeCode}</div>
                 </Col>
                 <Col>
                   <Text className="avl-bal">Balance</Text>
                   <div className="value">
                     {`${currencyMapper[balance?.currencyCode] ?? "$"}
                   ${amountFormat(balance?.balanceAmount)}`}
                   </div>
                 </Col>
                 <Col>
                   <Text className="avl-bal">Reserved Amount</Text>
                   <div className="value">{`${
                     currencyMapper[balance?.currencyCode] ?? "$"
                   }
                   ${amountFormat(balance?.reservedAmount)}`}</div>
                 </Col>
               </Row>
             </div>
           ))}
           {showList ? (
             <AccountCard
               onClick={(data) => setDetailView(data)}
               data={bankAcc}
             />
           ) : (
             <div className="paycraft-table">
               <Table
                 columns={columns}
                 dataSource={bankAcc?.filter(
                   (ele) =>
                     (ele.masterAccountId === account?.accountId ||
                       ele.accountId === account?.accountId) &&
                     ele.statusCode === "ACTIVE"
                 )}
                 pagination={false}
                 onRow={(data) => {
                   return {
                     onClick: (_event) => setDetailView(data),
                   };
                 }}
               />
             </div>
           )}
         </>
       )} */}
    </div>
  );
};

export default AccountDetail;
