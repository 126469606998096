import moment from "moment";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { handleCopy } from "../../../utils";
import { FormInput, FormSelect } from "../../inputs";

const CopyLink = ({ back, mainData, close, cAcc }) => {
  const {
    register,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      link: "",
    },
  });

  const BeneficiaryBankDetails = useSelector((state) =>
    state?.customers?.all?.data?.data?.map((ele) => ({
      ...ele,
      label:
        ele?.beneficiary_name + "-" + ele?.bank_accounts?.[0]?.account_number,
      value: ele?.bank_accounts?.[0]?.beneficiary_id,
    }))
  );

  useEffect(() => {
    if (mainData?.ourparty_account_id) {
      setValue("link", mainData?.link);
      setValue("send_to", mainData?.beneficiary_id);
      setValue("amount", mainData?.amount);
    }
  }, [mainData]);

  return (
    <div>
      <form>
        <div className="contact-details">
          <h4>You’ve emailed a payment request to {cAcc?.beneficiary_name}</h4>
          <p>
            <span className="helper-text" style={{ marginTop: 0 }}>
              ({cAcc?.email})
            </span>
          </p>
        </div>
        <div className="form-body">
          <div className="pp-form-item">
            <FormSelect
              inline
              {...register("send_to")}
              label={"Deposit to"}
              control={control}
              options={BeneficiaryBankDetails}
              hideCreateOption={true}
              placeholder={"Select"}
              errors={errors}
              disabled
            />
          </div>
          <div className="pp-form-item">
            <FormInput
              control={control}
              prefix={"$"}
              label={"Amount"}
              {...register("amount")}
              placeholder={"Enter Amount"}
              errors={errors}
              number={true}
              disabled
            />
          </div>

          <div className="pp-form-item">
            <FormInput
              control={control}
              label={"Copy Link"}
              {...register("link", {
                value: "zenus.com/pay/h8dw76b9s8bfcbdj",
              })}
              placeholder={"Copy link"}
              errors={errors}
              disabled
              suffix={
                <span
                  className="link cursor"
                  style={{
                    position: "relative",
                    zIndex: 10,
                  }}
                  onClick={() => {
                    handleCopy(mainData?.link);
                  }}
                >
                  Copy
                </span>
              }
            />
            <p className="helper-text">
              Expires on{" "}
              {mainData?.due_date
                ? moment(mainData?.due_date)?.format("MMMM DD, YYYY")
                : ""}
            </p>
          </div>
        </div>
        <div className="action-btns">
          <button type="button" className="back-btn" onClick={back}>
            Create Another Request
          </button>
          <button type="button" className="send-btn" onClick={close}>
            View All Payment Requests
          </button>
        </div>
      </form>
    </div>
  );
};

export default CopyLink;
