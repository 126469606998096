import { ActionTypesFactory } from "../../utils";

export const GetDisputes = ActionTypesFactory("Disputes", "GetDisputes");
export const CreateDisputes = ActionTypesFactory("Disputes", "CreateDisputes");
export const UpdateDisputes = ActionTypesFactory("Disputes", "UpdateDisputes");
export const DisputeReasonList = ActionTypesFactory(
  "Disputes",
  "DisputeReasonList"
);

export const get_disputes = (payload) => {
  return {
    type: GetDisputes.REQUEST,
    payload,
  };
};

export const create_disputes = (payload) => {
  return {
    type: CreateDisputes.REQUEST,
    payload,
  };
};

export const update_disputes = (payload) => {
  return {
    type: UpdateDisputes.REQUEST,
    payload,
  };
};

export const dispute_reason_list = (payload) => {
  return {
    type: DisputeReasonList.REQUEST,
    payload,
  };
};
