import {
  CloseCircleOutlined,
  MoreOutlined,
  PlusCircleFilled,
} from "@ant-design/icons";
import { Button, Col, Dropdown, Menu, Modal, Row, Space, message } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { headerTitles } from "../../actions/masterdata/masterdata";
import {
  DeletePaymentLink,
  deletePaymentLink,
  fetchPaymentLinks,
} from "../../actions/transfers";
import DeleteIcon from "../../assets/icons/delete.png";
import ViewImage from "../../assets/icons/eye.svg";
import { amountFormat, handleCopy } from "../../utils";
import BaseCurrency from "../Common/BaseCurrency";
import TableComponent from "../Common/TableComp/TableComp";
import RequestMoney from "../Payments/RequestMoney/RequestMoney";
import { formatDate } from "../Sales/Common";
import TableHeader from "../Sales/TableHeader";
import { useLocation, useHistory } from "react-router-dom";
import ViewRequest from "./ViewRequest";

const PaymentLinks = ({ hasWriteAccess }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [openAddNew, setOpenAddNew] = useState(false);
  const [record, setRecord] = useState({});
  const [editres, setEditRes] = useState({});
  const [txnDetail, setTxnDetail] = useState({});
  const [txnOpen, setTxnOpen] = useState(false);
  const [filters, setFilters] = React.useState({});
  const [deletePaymentLinkModal, setDeletePaymentLinkModal] = useState(false);
  const paymentData = useSelector(
    (state) => state?.transfers?.fetch_payment_links?.data?.data || []
  );
  const deletePaymentLinkResponse = useSelector(
    (state) => state?.transfers?.delete_payment_link
  );

  useEffect(() => {
    if (deletePaymentLinkResponse?.data?.error === true) {
      message.error(
        <span className="messageText">
          {deletePaymentLinkResponse?.data?.message}
        </span>
      );
      dispatch({
        type: DeletePaymentLink.RESET,
      });
    } else if (deletePaymentLinkResponse?.data?.error === false) {
      message.success(
        <span className="messageText">
          {"Payment Link Deleted Successfully" ||
            deletePaymentLinkResponse?.data?.message}
        </span>
      );
      setDeletePaymentLinkModal(false);
      dispatch({
        type: DeletePaymentLink.RESET,
      });
      dispatch(fetchPaymentLinks());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletePaymentLinkResponse]);
  const { t } = useTranslation();
  const options = [
    {
      label: (
        <div className="table-menu-item">
          <span className="menu-label">{t("add_new_vendor")}</span>
        </div>
      ),
      key: "1",
      icon: (
        <div>
          <PlusCircleFilled className="pp-icon-18" />
        </div>
      ),
      onAction: () => setOpenAddNew(true),
    },
  ];
  useEffect(() => {
    dispatch(
      headerTitles({
        title: "Request Money",
        description: "Receive funds to your accounts.",
      })
    );
    dispatch(fetchPaymentLinks());
    if (location?.state?.send) {
      setOpenAddNew(true);
      history.replace(location.pathname);
    }
    // eslint-disable-next-line
  }, []);

  const handleConfirm = () => {
    dispatch(
      deletePaymentLink({ id: record?.id, module_type: "COMPANY_PAYMENTS" })
    );
  };

  const handleCancel = () => {
    setDeletePaymentLinkModal(false);
  };

  // const onClickEdit = (record) => {
  //   // setOpenAddNew(true);
  //   setEditRes(record);
  // };

  const handleSort = (value) => {
    // if (location?.state?.status) {
    //   history.replace(location?.pathname);
    // }
    if (value) {
      dispatch(
        fetchPaymentLinks({
          ...filters,
          sort: {
            sort_key: value,
            sort_order: "1",
          },
          isFilter: true,
        })
      );
      setFilters({
        ...filters,
        sort: {
          sort_key: value,
          sort_order: "1",
        },
      });
    }
  };
  const columns = [
    {
      title: t("create_date"),
      dataIndex: "created_at",
      render: (text) => <div>{formatDate(text)}</div>,
    },
    // {
    //   title: t("link_id"),
    //   dataIndex: "link_id",
    // },
    {
      title: t("customer_name"),
      dataIndex: "counterparty_name",
      render: (text) => {
        return text;
      },
    },
    {
      title: t("Amount"),
      dataIndex: "amount",
      render: (text) => (
        <div>
          <BaseCurrency /> {amountFormat(text)}
        </div>
      ),
    },
    {
      title: t("status"),
      dataIndex: "status",
      render: (text) => (
        <div className={`status status_${text}`}>{text.replace(/_/g, " ")}</div>
      ),
    },
    {
      title: "",
      key: "action",
      render: (_, record) => (
        <Space
          size="middle"
          className="myspace"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item
                  onClick={(e) => {
                    e?.stoyPropagation?.();
                    handleCopy(
                      `${window.location.origin}/payments-select/${record?.link_id}`
                    );
                  }}
                >
                  {t("Copy Link")}
                </Menu.Item>
                <Menu.Item
                  onClick={(e) => {
                    e?.stopPropagation?.();
                    window.open(
                      `${window.location.origin}/payments-select/${record?.link_id}`,
                      "_blank"
                    );
                  }}
                >
                  <img src={ViewImage} alt={""} className="mr1" />
                  {t("View")}
                </Menu.Item>
                <Menu.Item
                  onClick={(e) => {
                    e?.stopPropagation?.();
                    setDeletePaymentLinkModal(true);
                    setRecord(record);
                  }}
                >
                  <img src={DeleteIcon} alt={"logo"} className="action-logo" />{" "}
                  Delete
                </Menu.Item>
              </Menu>
            }
          >
            <Button
              shape="default"
              icon={<MoreOutlined />}
              style={{ border: "none", color: "#5B87F9" }}
            />
          </Dropdown>
        </Space>
      ),
    },
  ];
  const filterOptions = [{ label: "Paid" }];
  const sortOptions = [
    { label: t("create_date_time"), filter: "created_at" },
    { label: t("customer_name"), filter: "beneficiary_name" },
    { label: t("mobile_no"), filter: "primary_contact" },
    { label: t("Amount"), filter: "amount" },
    { label: t("status"), filter: "status" },
  ];

  return (
    <>
      <ViewRequest
        open={txnOpen}
        txnDetail={txnDetail}
        onClose={() => {
          setTxnDetail({});
          setTxnOpen(false);
        }}
      />
      {deletePaymentLinkModal && (
        <Modal
          title="Delete"
          className="disconnect-modal"
          width={470}
          open={deletePaymentLinkModal}
          onCancel={handleCancel}
          maskClosable={false}
          closeIcon={<CloseCircleOutlined style={{ fontSize: "1.5rem" }} />}
        >
          <>
            <p className="modal-text">{t("deletePaymentLink")}</p>
            <div
              className="form-btm mt3"
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Button
                type="primary"
                className="pp-main-button cursor"
                onClick={handleConfirm}
                disabled={deletePaymentLinkResponse?.loading}
              >
                <span>{t("confirm")}</span>
              </Button>
              <Button
                className="pp-secondary-button cursor"
                style={{ marginLeft: "2rem" }}
                onClick={handleCancel}
              >
                <span>{t("cancel")}</span>
              </Button>
            </div>
          </>
        </Modal>
      )}
      {openAddNew ? (
        <RequestMoney setOpenAddNew={setOpenAddNew} />
      ) : (
        <Row wrap={false} className="table-wrapper">
          <Col flex="auto" className="table-wrapper-col">
            <div className="flex-grow">
              <TableHeader
                noDD={true}
                filterMenu={[]}
                noFilter={true}
                //   handleFilter={handleFilter}
                setOpenAddNew={setOpenAddNew}
                noAddNewBtn={false}
                sortOptions={sortOptions}
                handleSort={handleSort}
                hasWriteAccess={hasWriteAccess}
                //   onSearch={handleSearch}
                menu={options}
                onSearch={(val) => {
                  dispatch(
                    fetchPaymentLinks({
                      ...filters,
                      search_key: val,
                    })
                  );
                  setFilters({
                    ...filters,
                    search_key: val,
                  });
                }}
              />
              <div className="" style={{ paddingTop: "22px" }}>
                <TableComponent
                  columns={columns}
                  loading=""
                  data={Array.isArray(paymentData) ? paymentData : []}
                  hasCheckbox={false}
                  handleRowClick={(_e, rec) => {
                    setTxnDetail(rec);
                    setTxnOpen(true);
                  }}
                  rowKey="id"
                />
              </div>
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};

export default PaymentLinks;
