export const countries = [
  { label: "Afghanistan", value: "Afghanistan", code: "AF" },
  { label: "Åland Islands", value: "Åland Islands", code: "AX" },
  { label: "Albania", value: "Albania", code: "AL" },
  { label: "Algeria", value: "Algeria", code: "DZ" },
  { label: "American Samoa", value: "American Samoa", code: "AS" },
  { label: "AndorrA", value: "AndorrA", code: "AD" },
  { label: "Angola", value: "Angola", code: "AO" },
  { label: "Anguilla", value: "Anguilla", code: "AI" },
  { label: "Antarctica", value: "Antarctica", code: "AQ" },
  { label: "Antigua and Barbuda", value: "Antigua and Barbuda", code: "AG" },
  { label: "Argentina", value: "Argentina", code: "AR" },
  { label: "Armenia", value: "Armenia", code: "AM" },
  { label: "Aruba", value: "Aruba", code: "AW" },
  { label: "Australia", value: "Australia", code: "AU" },
  { label: "Austria", value: "Austria", code: "AT" },
  { label: "Azerbaijan", value: "Azerbaijan", code: "AZ" },
  { label: "Bahamas", value: "Bahamas", code: "BS" },
  { label: "Bahrain", value: "Bahrain", code: "BH" },
  { label: "Bangladesh", value: "Bangladesh", code: "BD" },
  { label: "Barbados", value: "Barbados", code: "BB" },
  { label: "Belarus", value: "Belarus", code: "BY" },
  { label: "Belgium", value: "Belgium", code: "BE" },
  { label: "Belize", value: "Belize", code: "BZ" },
  { label: "Benin", value: "Benin", code: "BJ" },
  { label: "Bermuda", value: "Bermuda", code: "BM" },
  { label: "Bhutan", value: "Bhutan", code: "BT" },
  { label: "Bolivia", value: "Bolivia", code: "BO" },
  {
    label: "Bosnia and Herzegovina",
    value: "Bosnia and Herzegovina",
    code: "BA",
  },
  { label: "Botswana", value: "Botswana", code: "BW" },
  { label: "Bouvet Island", value: "Bouvet Island", code: "BV" },
  { label: "Brazil", value: "Brazil", code: "BR" },
  {
    label: "British Indian Ocean Territory",
    value: "British Indian Ocean Territory",
    code: "IO",
  },
  { label: "Brunei Darussalam", value: "Brunei Darussalam", code: "BN" },
  { label: "Bulgaria", value: "Bulgaria", code: "BG" },
  { label: "Burkina Faso", value: "Burkina Faso", code: "BF" },
  { label: "Burundi", value: "Burundi", code: "BI" },
  { label: "Cambodia", value: "Cambodia", code: "KH" },
  { label: "Cameroon", value: "Cameroon", code: "CM" },
  { label: "Canada", value: "Canada", code: "CA" },
  { label: "Cape Verde", value: "Cape Verde", code: "CV" },
  { label: "Cayman Islands", value: "Cayman Islands", code: "KY" },
  {
    label: "Central African Republic",
    value: "Central African Republic",
    code: "CF",
  },
  { label: "Chad", value: "Chad", code: "TD" },
  { label: "Chile", value: "Chile", code: "CL" },
  { label: "China", value: "China", code: "CN" },
  { label: "Christmas Island", value: "Christmas Island", code: "CX" },
  {
    label: "Cocos (Keeling) Islands",
    value: "Cocos (Keeling) Islands",
    code: "CC",
  },
  { label: "Colombia", value: "Colombia", code: "CO" },
  { label: "Comoros", value: "Comoros", code: "KM" },
  { label: "Congo", value: "Congo", code: "CG" },
  {
    label: "Congo, The Democratic Republic of the",
    value: "Congo, The Democratic Republic of the",
    code: "CD",
  },
  { label: "Cook Islands", value: "Cook Islands", code: "CK" },
  { label: "Costa Rica", value: "Costa Rica", code: "CR" },
  { label: "Cote D'Ivoire", value: "Cote D'Ivoire", code: "CI" },
  { label: "Croatia", value: "Croatia", code: "HR" },
  { label: "Cuba", value: "Cuba", code: "CU" },
  { label: "Cyprus", value: "Cyprus", code: "CY" },
  { label: "Czech Republic", value: "Czech Republic", code: "CZ" },
  { label: "Denmark", value: "Denmark", code: "DK" },
  { label: "Djibouti", value: "Djibouti", code: "DJ" },
  { label: "Dominica", value: "Dominica", code: "DM" },
  { label: "Dominican Republic", value: "Dominican Republic", code: "DO" },
  { label: "Ecuador", value: "Ecuador", code: "EC" },
  { label: "Egypt", value: "Egypt", code: "EG" },
  { label: "El Salvador", value: "El Salvador", code: "SV" },
  { label: "Equatorial Guinea", value: "Equatorial Guinea", code: "GQ" },
  { label: "Eritrea", value: "Eritrea", code: "ER" },
  { label: "Estonia", value: "Estonia", code: "EE" },
  { label: "Ethiopia", value: "Ethiopia", code: "ET" },
  {
    label: "Falkland Islands (Malvinas)",
    value: "Falkland Islands (Malvinas)",
    code: "FK",
  },
  { label: "Faroe Islands", value: "Faroe Islands", code: "FO" },
  { label: "Fiji", value: "Fiji", code: "FJ" },
  { label: "Finland", value: "Finland", code: "FI" },
  { label: "France", value: "France", code: "FR" },
  { label: "French Guiana", value: "French Guiana", code: "GF" },
  { label: "French Polynesia", value: "French Polynesia", code: "PF" },
  {
    label: "French Southern Territories",
    value: "French Southern Territories",
    code: "TF",
  },
  { label: "Gabon", value: "Gabon", code: "GA" },
  { label: "Gambia", value: "Gambia", code: "GM" },
  { label: "Georgia", value: "Georgia", code: "GE" },
  { label: "Germany", value: "Germany", code: "DE" },
  { label: "Ghana", value: "Ghana", code: "GH" },
  { label: "Gibraltar", value: "Gibraltar", code: "GI" },
  { label: "Greece", value: "Greece", code: "GR" },
  { label: "Greenland", value: "Greenland", code: "GL" },
  { label: "Grenada", value: "Grenada", code: "GD" },
  { label: "Guadeloupe", value: "Guadeloupe", code: "GP" },
  { label: "Guam", value: "Guam", code: "GU" },
  { label: "Guatemala", value: "Guatemala", code: "GT" },
  { label: "Guernsey", value: "Guernsey", code: "GG" },
  { label: "Guinea", value: "Guinea", code: "GN" },
  { label: "Guinea-Bissau", value: "Guinea-Bissau", code: "GW" },
  { label: "Guyana", value: "Guyana", code: "GY" },
  { label: "Haiti", value: "Haiti", code: "HT" },
  {
    label: "Heard Island and Mcdonald Islands",
    value: "Heard Island and Mcdonald Islands",
    code: "HM",
  },
  {
    label: "Holy See (Vatican City State)",
    value: "Holy See (Vatican City State)",
    code: "VA",
  },
  { label: "Honduras", value: "Honduras", code: "HN" },
  { label: "Hong Kong", value: "Hong Kong", code: "HK" },
  { label: "Hungary", value: "Hungary", code: "HU" },
  { label: "Iceland", value: "Iceland", code: "IS" },
  { label: "India", value: "India", code: "IN" },
  { label: "Indonesia", value: "Indonesia", code: "ID" },
  {
    label: "Iran, Islamic Republic Of",
    value: "Iran, Islamic Republic Of",
    code: "IR",
  },
  { label: "Iraq", value: "Iraq", code: "IQ" },
  { label: "Ireland", value: "Ireland", code: "IE" },
  { label: "Isle of Man", value: "Isle of Man", code: "IM" },
  { label: "Israel", value: "Israel", code: "IL" },
  { label: "Italy", value: "Italy", code: "IT" },
  { label: "Jamaica", value: "Jamaica", code: "JM" },
  { label: "Japan", value: "Japan", code: "JP" },
  { label: "Jersey", value: "Jersey", code: "JE" },
  { label: "Jordan", value: "Jordan", code: "JO" },
  { label: "Kazakhstan", value: "Kazakhstan", code: "KZ" },
  { label: "Kenya", value: "Kenya", code: "KE" },
  { label: "Kiribati", value: "Kiribati", code: "KI" },
  {
    label: "Korea, Democratic People'S Republic of",
    value: "Korea, Democratic People'S Republic of",
    code: "KP",
  },
  { label: "Korea, Republic of", value: "Korea, Republic of", code: "KR" },
  { label: "Kuwait", value: "Kuwait", code: "KW" },
  { label: "Kyrgyzstan", value: "Kyrgyzstan", code: "KG" },
  {
    label: "Lao People'S Democratic Republic",
    value: "Lao People'S Democratic Republic",
    code: "LA",
  },
  { label: "Latvia", value: "Latvia", code: "LV" },
  { label: "Lebanon", value: "Lebanon", code: "LB" },
  { label: "Lesotho", value: "Lesotho", code: "LS" },
  { label: "Liberia", value: "Liberia", code: "LR" },
  {
    label: "Libyan Arab Jamahiriya",
    value: "Libyan Arab Jamahiriya",
    code: "LY",
  },
  { label: "Liechtenstein", value: "Liechtenstein", code: "LI" },
  { label: "Lithuania", value: "Lithuania", code: "LT" },
  { label: "Luxembourg", value: "Luxembourg", code: "LU" },
  { label: "Macao", value: "Macao", code: "MO" },
  {
    label: "Macedonia, The Former Yugoslav Republic of",
    value: "Macedonia, The Former Yugoslav Republic of",
    code: "MK",
  },
  { label: "Madagascar", value: "Madagascar", code: "MG" },
  { label: "Malawi", value: "Malawi", code: "MW" },
  { label: "Malaysia", value: "Malaysia", code: "MY" },
  { label: "Maldives", value: "Maldives", code: "MV" },
  { label: "Mali", value: "Mali", code: "ML" },
  { label: "Malta", value: "Malta", code: "MT" },
  { label: "Marshall Islands", value: "Marshall Islands", code: "MH" },
  { label: "Martinique", value: "Martinique", code: "MQ" },
  { label: "Mauritania", value: "Mauritania", code: "MR" },
  { label: "Mauritius", value: "Mauritius", code: "MU" },
  { label: "Mayotte", value: "Mayotte", code: "YT" },
  { label: "Mexico", value: "Mexico", code: "MX" },
  {
    label: "Micronesia, Federated States of",
    value: "Micronesia, Federated States of",
    code: "FM",
  },
  { label: "Moldova, Republic of", value: "Moldova, Republic of", code: "MD" },
  { label: "Monaco", value: "Monaco", code: "MC" },
  { label: "Mongolia", value: "Mongolia", code: "MN" },
  { label: "Montserrat", value: "Montserrat", code: "MS" },
  { label: "Morocco", value: "Morocco", code: "MA" },
  { label: "Mozambique", value: "Mozambique", code: "MZ" },
  { label: "Myanmar", value: "Myanmar", code: "MM" },
  { label: "Namibia", value: "Namibia", code: "NA" },
  { label: "Nauru", value: "Nauru", code: "NR" },
  { label: "Nepal", value: "Nepal", code: "NP" },
  { label: "Netherlands", value: "Netherlands", code: "NL" },
  { label: "Netherlands Antilles", value: "Netherlands Antilles", code: "AN" },
  { label: "New Caledonia", value: "New Caledonia", code: "NC" },
  { label: "New Zealand", value: "New Zealand", code: "NZ" },
  { label: "Nicaragua", value: "Nicaragua", code: "NI" },
  { label: "Niger", value: "Niger", code: "NE" },
  { label: "Nigeria", value: "Nigeria", code: "NG" },
  { label: "Niue", value: "Niue", code: "NU" },
  { label: "Norfolk Island", value: "Norfolk Island", code: "NF" },
  {
    label: "Northern Mariana Islands",
    value: "Northern Mariana Islands",
    code: "MP",
  },
  { label: "Norway", value: "Norway", code: "NO" },
  { label: "Oman", value: "Oman", code: "OM" },
  { label: "Pakistan", value: "Pakistan", code: "PK" },
  { label: "Palau", value: "Palau", code: "PW" },
  {
    label: "Palestinian Territory, Occupied",
    value: "Palestinian Territory, Occupied",
    code: "PS",
  },
  { label: "Panama", value: "Panama", code: "PA" },
  { label: "Papua New Guinea", value: "Papua New Guinea", code: "PG" },
  { label: "Paraguay", value: "Paraguay", code: "PY" },
  { label: "Peru", value: "Peru", code: "PE" },
  { label: "Philippines", value: "Philippines", code: "PH" },
  { label: "Pitcairn", value: "Pitcairn", code: "PN" },
  { label: "Poland", value: "Poland", code: "PL" },
  { label: "Portugal", value: "Portugal", code: "PT" },
  { label: "Puerto Rico", value: "Puerto Rico", code: "PR" },
  { label: "Qatar", value: "Qatar", code: "QA" },
  { label: "Reunion", value: "Reunion", code: "RE" },
  { label: "Romania", value: "Romania", code: "RO" },
  { label: "Russian Federation", value: "Russian Federation", code: "RU" },
  { label: "RWANDA", value: "RWANDA", code: "RW" },
  { label: "Saint Helena", value: "Saint Helena", code: "SH" },
  {
    label: "Saint Kitts and Nevis",
    value: "Saint Kitts and Nevis",
    code: "KN",
  },
  { label: "Saint Lucia", value: "Saint Lucia", code: "LC" },
  {
    label: "Saint Pierre and Miquelon",
    value: "Saint Pierre and Miquelon",
    code: "PM",
  },
  {
    label: "Saint Vincent and the Grenadines",
    value: "Saint Vincent and the Grenadines",
    code: "VC",
  },
  { label: "Samoa", value: "Samoa", code: "WS" },
  { label: "San Marino", value: "San Marino", code: "SM" },
  {
    label: "Sao Tome and Principe",
    value: "Sao Tome and Principe",
    code: "ST",
  },
  { label: "Saudi Arabia", value: "Saudi Arabia", code: "SA" },
  { label: "Senegal", value: "Senegal", code: "SN" },
  {
    label: "Serbia and Montenegro",
    value: "Serbia and Montenegro",
    code: "CS",
  },
  { label: "Seychelles", value: "Seychelles", code: "SC" },
  { label: "Sierra Leone", value: "Sierra Leone", code: "SL" },
  { label: "Singapore", value: "Singapore", code: "SG" },
  { label: "Slovakia", value: "Slovakia", code: "SK" },
  { label: "Slovenia", value: "Slovenia", code: "SI" },
  { label: "Solomon Islands", value: "Solomon Islands", code: "SB" },
  { label: "Somalia", value: "Somalia", code: "SO" },
  { label: "South Africa", value: "South Africa", code: "ZA" },
  {
    label: "South Georgia and the South Sandwich Islands",
    value: "South Georgia and the South Sandwich Islands",
    code: "GS",
  },
  { label: "Spain", value: "Spain", code: "ES" },
  { label: "Sri Lanka", value: "Sri Lanka", code: "LK" },
  { label: "Sudan", value: "Sudan", code: "SD" },
  { label: "Suriname", value: "Suriname", code: "SR" },
  {
    label: "Svalbard and Jan Mayen",
    value: "Svalbard and Jan Mayen",
    code: "SJ",
  },
  { label: "Swaziland", value: "Swaziland", code: "SZ" },
  { label: "Sweden", value: "Sweden", code: "SE" },
  { label: "Switzerland", value: "Switzerland", code: "CH" },
  { label: "Syrian Arab Republic", value: "Syrian Arab Republic", code: "SY" },
  {
    label: "Taiwan, Province of China",
    value: "Taiwan, Province of China",
    code: "TW",
  },
  { label: "Tajikistan", value: "Tajikistan", code: "TJ" },
  {
    label: "Tanzania, United Republic of",
    value: "Tanzania, United Republic of",
    code: "TZ",
  },
  { label: "Thailand", value: "Thailand", code: "TH" },
  { label: "Timor-Leste", value: "Timor-Leste", code: "TL" },
  { label: "Togo", value: "Togo", code: "TG" },
  { label: "Tokelau", value: "Tokelau", code: "TK" },
  { label: "Tonga", value: "Tonga", code: "TO" },
  { label: "Trinidad and Tobago", value: "Trinidad and Tobago", code: "TT" },
  { label: "Tunisia", value: "Tunisia", code: "TN" },
  { label: "Turkey", value: "Turkey", code: "TR" },
  { label: "Turkmenistan", value: "Turkmenistan", code: "TM" },
  {
    label: "Turks and Caicos Islands",
    value: "Turks and Caicos Islands",
    code: "TC",
  },
  { label: "Tuvalu", value: "Tuvalu", code: "TV" },
  { label: "Uganda", value: "Uganda", code: "UG" },
  { label: "Ukraine", value: "Ukraine", code: "UA" },
  { label: "United Arab Emirates", value: "United Arab Emirates", code: "AE" },
  { label: "United Kingdom", value: "United Kingdom", code: "GB" },
  { label: "United States", value: "United States", code: "US" },
  {
    label: "United States Minor Outlying Islands",
    value: "United States Minor Outlying Islands",
    code: "UM",
  },
  { label: "Uruguay", value: "Uruguay", code: "UY" },
  { label: "Uzbekistan", value: "Uzbekistan", code: "UZ" },
  { label: "Vanuatu", value: "Vanuatu", code: "VU" },
  { label: "Venezuela", value: "Venezuela", code: "VE" },
  { label: "Viet Nam", value: "Viet Nam", code: "VN" },
  {
    label: "Virgin Islands, British",
    value: "Virgin Islands, British",
    code: "VG",
  },
  { label: "Virgin Islands, U.S.", value: "Virgin Islands, U.S.", code: "VI" },
  { label: "Wallis and Futuna", value: "Wallis and Futuna", code: "WF" },
  { label: "Western Sahara", value: "Western Sahara", code: "EH" },
  { label: "Yemen", value: "Yemen", code: "YE" },
  { label: "Zambia", value: "Zambia", code: "ZM" },
  { label: "Zimbabwe", value: "Zimbabwe", code: "ZW" },
];

export const countryValidations = [
  { code: "US", phone_validation: /^[2-9]{1}[0-9]{9}$/i }, // United States
  { code: "IN", phone_validation: /^[6-9]{1}[0-9]{9}$/i }, // India
  { code: "GB", phone_validation: /^7[0-9]{9}$/i }, // United Kingdom
  { code: "CA", phone_validation: /^[2-9][0-9]{9}$/i }, // Canada
  { code: "AU", phone_validation: /^[2-478]{1}[0-9]{8}$/i }, // Australia
  { code: "FR", phone_validation: /^[1-9][0-9]{8}$/i }, // France
  { code: "DE", phone_validation: /^[1-9][0-9]{9}$/i }, // Germany
  { code: "BR", phone_validation: /^[1-9]{2}[0-9]{8,9}$/i }, // Brazil
  { code: "JP", phone_validation: /^[1-9]{1}[0-9]{8,9}$/i }, // Japan
  { code: "RU", phone_validation: /^9[0-9]{9}$/i }, // Russia
  { code: "CN", phone_validation: /^[1][3-9][0-9]{9}$/i }, // China
  { code: "MX", phone_validation: /^[1-9][0-9]{9,10}$/i }, // Mexico
  { code: "IT", phone_validation: /^[3-9][0-9]{8,10}$/i }, // Italy
  { code: "ZA", phone_validation: /^[1-9][0-9]{8}$/i }, // South Africa
  { code: "NG", phone_validation: /^[1-9][0-9]{7,10}$/i }, // Nigeria
  { code: "KR", phone_validation: /^01[0-9]{8,9}$/i }, // South Korea
  { code: "SA", phone_validation: /^5[0-9]{8}$/i }, // Saudi Arabia
  { code: "ES", phone_validation: /^[6-9][0-9]{8}$/i }, // Spain
  { code: "SE", phone_validation: /^[1-9][0-9]{8}$/i }, // Sweden
  { code: "TR", phone_validation: /^[5][0-9]{9}$/i }, // Turkey
  { code: "AR", phone_validation: /^[1-9][0-9]{8,10}$/i }, // Argentina
  { code: "TH", phone_validation: /^[6-9][0-9]{8}$/i }, // Thailand
  { code: "EG", phone_validation: /^1[0-9]{9}$/i }, // Egypt
  { code: "PH", phone_validation: /^[9][0-9]{9}$/i }, // Philippines
  { code: "PK", phone_validation: /^[3][0-9]{9}$/i }, // Pakistan
  { code: "PL", phone_validation: /^[5-9][0-9]{8}$/i }, // Poland
  { code: "CH", phone_validation: /^[7][0-9]{8}$/i }, // Switzerland
  { code: "BE", phone_validation: /^4[0-9]{8}$/i }, // Belgium
  { code: "NL", phone_validation: /^[6][0-9]{8}$/i }, // Netherlands
  { code: "MY", phone_validation: /^[1-9][0-9]{7,9}$/i }, // Malaysia
  { code: "SG", phone_validation: /^[89][0-9]{7}$/i }, // Singapore
  { code: "AT", phone_validation: /^[6][0-9]{9}$/i }, // Austria
  { code: "DK", phone_validation: /^[1-9][0-9]{7}$/i }, // Denmark
  { code: "NO", phone_validation: /^[4-9][0-9]{7}$/i }, // Norway
  { code: "FI", phone_validation: /^[4][0-9]{8}$/i }, // Finland
  { code: "GR", phone_validation: /^[6][0-9]{9}$/i }, // Greece
  { code: "HU", phone_validation: /^[1-9][0-9]{8}$/i }, // Hungary
  { code: "IE", phone_validation: /^[8-9][0-9]{8}$/i }, // Ireland
  { code: "PT", phone_validation: /^[9][0-9]{8}$/i }, // Portugal
  { code: "IL", phone_validation: /^[5][0-9]{8}$/i }, // Israel
  { code: "NZ", phone_validation: /^[2-9][0-9]{7,9}$/i }, // New Zealand
  { code: "AE", phone_validation: /^[5][0-9]{8}$/i }, // United Arab Emirates
  { code: "CO", phone_validation: /^[3][0-9]{9}$/i }, // Colombia
  { code: "CL", phone_validation: /^[2-9][0-9]{8,9}$/i }, // Chile
  { code: "HK", phone_validation: /^[5-9][0-9]{7}$/i }, // Hong Kong
  { code: "ID", phone_validation: /^[8][0-9]{8,10}$/i }, // Indonesia
  { code: "VN", phone_validation: /^[3-9][0-9]{8}$/i }, // Vietnam
  { code: "CZ", phone_validation: /^[6-7][0-9]{8}$/i }, // Czech Republic
  { code: "RO", phone_validation: /^[7][0-9]{8}$/i }, // Romania
  { code: "BG", phone_validation: /^[8][0-9]{8}$/i }, // Bulgaria
  { code: "SK", phone_validation: /^[9][0-9]{8}$/i }, // Slovakia
  { code: "LT", phone_validation: /^[6][0-9]{7,8}$/i }, // Lithuania
  { code: "LV", phone_validation: /^[2][0-9]{7}$/i }, // Latvia
  { code: "EE", phone_validation: /^[5][0-9]{7}$/i }, // Estonia
  { code: "UA", phone_validation: /^[3-9][0-9]{8}$/i }, // Ukraine
  { code: "BY", phone_validation: /^[2-9][0-9]{8}$/i }, // Belarus
];
