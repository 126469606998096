export const dialCodes = [
  { code: "AF", dial_code: "+93", flag: "🇦🇫" },
  { code: "AL", dial_code: "+355", flag: "🇦🇱" },
  { code: "DZ", dial_code: "+213", flag: "🇩🇿" },
  { code: "AS", dial_code: "+1-684", flag: "🇦🇸" },
  { code: "AD", dial_code: "+376", flag: "🇦🇩" },
  { code: "AO", dial_code: "+244", flag: "🇦🇴" },
  { code: "AI", dial_code: "+1-264", flag: "🇦🇮" },
  { code: "AG", dial_code: "+1-268", flag: "🇦🇬" },
  { code: "AR", dial_code: "+54", flag: "🇦🇷" },
  { code: "AM", dial_code: "+374", flag: "🇦🇲" },
  { code: "AW", dial_code: "+297", flag: "🇦🇼" },
  { code: "AU", dial_code: "+61", flag: "🇦🇺" },
  { code: "AT", dial_code: "+43", flag: "🇦🇹" },
  { code: "AZ", dial_code: "+994", flag: "🇦🇿" },
  { code: "BS", dial_code: "+1-242", flag: "🇧🇸" },
  { code: "BH", dial_code: "+973", flag: "🇧🇭" },
  { code: "BD", dial_code: "+880", flag: "🇧🇩" },
  { code: "BB", dial_code: "+1-246", flag: "🇧🇧" },
  { code: "BY", dial_code: "+375", flag: "🇧🇾" },
  { code: "BE", dial_code: "+32", flag: "🇧🇪" },
  { code: "BZ", dial_code: "+501", flag: "🇧🇿" },
  { code: "BJ", dial_code: "+229", flag: "🇧🇯" },
  { code: "BM", dial_code: "+1-441", flag: "🇧🇲" },
  { code: "BT", dial_code: "+975", flag: "🇧🇹" },
  { code: "BO", dial_code: "+591", flag: "🇧🇴" },
  { code: "BA", dial_code: "+387", flag: "🇧🇦" },
  { code: "BW", dial_code: "+267", flag: "🇧🇼" },
  { code: "BR", dial_code: "+55", flag: "🇧🇷" },
  { code: "IO", dial_code: "+246", flag: "🇮🇴" },
  { code: "BG", dial_code: "+359", flag: "🇧🇬" },
  { code: "BF", dial_code: "+226", flag: "🇧🇫" },
  { code: "BI", dial_code: "+257", flag: "🇧🇮" },
  { code: "KH", dial_code: "+855", flag: "🇰🇭" },
  { code: "CM", dial_code: "+237", flag: "🇨🇲" },
  { code: "CA", dial_code: "+1", flag: "🇨🇦" },
  { code: "CV", dial_code: "+238", flag: "🇨🇻" },
  { code: "KY", dial_code: "+1-345", flag: "🇰🇾" },
  { code: "CF", dial_code: "+236", flag: "🇨🇫" },
  { code: "TD", dial_code: "+235", flag: "🇹🇩" },
  { code: "CL", dial_code: "+56", flag: "🇨🇱" },
  { code: "CN", dial_code: "+86", flag: "🇨🇳" },
  { code: "CO", dial_code: "+57", flag: "🇨🇴" },
  { code: "KM", dial_code: "+269", flag: "🇰🇲" },
  { code: "CG", dial_code: "+242", flag: "🇨🇬" },
  { code: "CD", dial_code: "+243", flag: "🇨🇩" },
  { code: "CR", dial_code: "+506", flag: "🇨🇷" },
  { code: "CI", dial_code: "+225", flag: "🇨🇮" },
  { code: "HR", dial_code: "+385", flag: "🇭🇷" },
  { code: "CU", dial_code: "+53", flag: "🇨🇺" },
  { code: "CY", dial_code: "+357", flag: "🇨🇾" },
  { code: "CZ", dial_code: "+420", flag: "🇨🇿" },
  { code: "DK", dial_code: "+45", flag: "🇩🇰" },
  { code: "DJ", dial_code: "+253", flag: "🇩🇯" },
  { code: "DM", dial_code: "+1-767", flag: "🇩🇲" },
  { code: "DO", dial_code: "+1-809", flag: "🇩🇴" },
  { code: "EC", dial_code: "+593", flag: "🇪🇨" },
  { code: "EG", dial_code: "+20", flag: "🇪🇬" },
  { code: "SV", dial_code: "+503", flag: "🇸🇻" },
  { code: "GQ", dial_code: "+240", flag: "🇬🇶" },
  { code: "ER", dial_code: "+291", flag: "🇪🇷" },
  { code: "EE", dial_code: "+372", flag: "🇪🇪" },
  { code: "ET", dial_code: "+251", flag: "🇪🇹" },
  { code: "FJ", dial_code: "+679", flag: "🇫🇯" },
  { code: "FI", dial_code: "+358", flag: "🇫🇮" },
  { code: "FR", dial_code: "+33", flag: "🇫🇷" },
  { code: "GA", dial_code: "+241", flag: "🇬🇦" },
  { code: "GM", dial_code: "+220", flag: "🇬🇲" },
  { code: "GE", dial_code: "+995", flag: "🇬🇪" },
  { code: "DE", dial_code: "+49", flag: "🇩🇪" },
  { code: "GH", dial_code: "+233", flag: "🇬🇭" },
  { code: "GR", dial_code: "+30", flag: "🇬🇷" },
  { code: "GD", dial_code: "+1-473", flag: "🇬🇩" },
  { code: "GT", dial_code: "+502", flag: "🇬🇹" },
  { code: "GN", dial_code: "+224", flag: "🇬🇳" },
  { code: "GW", dial_code: "+245", flag: "🇬🇼" },
  { code: "GY", dial_code: "+592", flag: "🇬🇾" },
  { code: "HT", dial_code: "+509", flag: "🇭🇹" },
  { code: "HN", dial_code: "+504", flag: "🇭🇳" },
  { code: "HK", dial_code: "+852", flag: "🇭🇰" },
  { code: "HU", dial_code: "+36", flag: "🇭🇺" },
  { code: "IS", dial_code: "+354", flag: "🇮🇸" },
  { code: "IN", dial_code: "+91", flag: "🇮🇳" },
  { code: "ID", dial_code: "+62", flag: "🇮🇩" },
  { code: "IR", dial_code: "+98", flag: "🇮🇷" },
  { code: "IQ", dial_code: "+964", flag: "🇮🇶" },
  { code: "IE", dial_code: "+353", flag: "🇮🇪" },
  { code: "IL", dial_code: "+972", flag: "🇮🇱" },
  { code: "IT", dial_code: "+39", flag: "🇮🇹" },
  { code: "JM", dial_code: "+1-876", flag: "🇯🇲" },
  { code: "JP", dial_code: "+81", flag: "🇯🇵" },
  { code: "JO", dial_code: "+962", flag: "🇯🇴" },
  { code: "KZ", dial_code: "+7", flag: "🇰🇿" },
  { code: "KE", dial_code: "+254", flag: "🇰🇪" },
  { code: "KI", dial_code: "+686", flag: "🇰🇮" },
  { code: "KW", dial_code: "+965", flag: "🇰🇼" },
  { code: "KG", dial_code: "+996", flag: "🇰🇬" },
  { code: "LA", dial_code: "+856", flag: "🇱🇦" },
  { code: "LV", dial_code: "+371", flag: "🇱🇻" },
  { code: "LB", dial_code: "+961", flag: "🇱🇧" },
  { code: "LS", dial_code: "+266", flag: "🇱🇸" },
  { code: "LR", dial_code: "+231", flag: "🇱🇷" },
  { code: "LY", dial_code: "+218", flag: "🇱🇾" },
  { code: "LI", dial_code: "+423", flag: "🇱🇮" },
  { code: "LT", dial_code: "+370", flag: "🇱🇹" },
  { code: "LU", dial_code: "+352", flag: "🇱🇺" },
  { code: "MO", dial_code: "+853", flag: "🇲🇴" },
  { code: "MK", dial_code: "+389", flag: "🇲🇰" },
  { code: "MG", dial_code: "+261", flag: "🇲🇬" },
  { code: "MW", dial_code: "+265", flag: "🇲🇼" },
  { code: "MY", dial_code: "+60", flag: "🇲🇾" },
  { code: "MV", dial_code: "+960", flag: "🇲🇻" },
  { code: "ML", dial_code: "+223", flag: "🇲🇱" },
  { code: "MT", dial_code: "+356", flag: "🇲🇹" },
  { code: "MH", dial_code: "+692", flag: "🇲🇭" },
  { code: "MR", dial_code: "+222", flag: "🇲🇷" },
  { code: "MU", dial_code: "+230", flag: "🇲🇺" },
  { code: "MX", dial_code: "+52", flag: "🇲🇽" },
  { code: "FM", dial_code: "+691", flag: "🇫🇲" },
  { code: "MD", dial_code: "+373", flag: "🇲🇩" },
  { code: "MC", dial_code: "+377", flag: "🇲🇨" },
  { code: "MN", dial_code: "+976", flag: "🇲🇳" },
  { code: "ME", dial_code: "+382", flag: "🇲🇪" },
  { code: "MA", dial_code: "+212", flag: "🇲🇦" },
  { code: "MZ", dial_code: "+258", flag: "🇲🇿" },
  { code: "NA", dial_code: "+264", flag: "🇳🇦" },
  { code: "NR", dial_code: "+674", flag: "🇳🇷" },
  { code: "NP", dial_code: "+977", flag: "🇳🇵" },
  { code: "NL", dial_code: "+31", flag: "🇳🇱" },
  { code: "NZ", dial_code: "+64", flag: "🇳🇿" },
  { code: "NI", dial_code: "+505", flag: "🇳🇮" },
  { code: "NE", dial_code: "+227", flag: "🇳🇪" },
  { code: "NG", dial_code: "+234", flag: "🇳🇬" },
  { code: "NU", dial_code: "+683", flag: "🇳🇺" },
  { code: "NO", dial_code: "+47", flag: "🇳🇴" },
  { code: "OM", dial_code: "+968", flag: "🇴🇲" },
  { code: "PK", dial_code: "+92", flag: "🇵🇰" },
  { code: "PW", dial_code: "+680", flag: "🇵🇼" },
  { code: "PA", dial_code: "+507", flag: "🇵🇦" },
  { code: "PG", dial_code: "+675", flag: "🇵🇬" },
  { code: "PY", dial_code: "+595", flag: "🇵🇾" },
  { code: "PE", dial_code: "+51", flag: "🇵🇪" },
  { code: "PH", dial_code: "+63", flag: "🇵🇭" },
  { code: "PL", dial_code: "+48", flag: "🇵🇱" },
  { code: "PT", dial_code: "+351", flag: "🇵🇹" },
  { code: "PR", dial_code: "+1-787", flag: "🇵🇷" },
  { code: "QA", dial_code: "+974", flag: "🇶🇦" },
  { code: "RO", dial_code: "+40", flag: "🇷🇴" },
  { code: "RU", dial_code: "+7", flag: "🇷🇺" },
  { code: "RW", dial_code: "+250", flag: "🇷🇼" },
  { code: "WS", dial_code: "+685", flag: "🇼🇸" },
  { code: "SM", dial_code: "+378", flag: "🇸🇲" },
  { code: "SA", dial_code: "+966", flag: "🇸🇦" },
  { code: "SN", dial_code: "+221", flag: "🇸🇳" },
  { code: "RS", dial_code: "+381", flag: "🇷🇸" },
  { code: "SC", dial_code: "+248", flag: "🇸🇨" },
  { code: "SL", dial_code: "+232", flag: "🇸🇱" },
  { code: "SG", dial_code: "+65", flag: "🇸🇬" },
  { code: "SK", dial_code: "+421", flag: "🇸🇰" },
  { code: "SI", dial_code: "+386", flag: "🇸🇮" },
  { code: "SB", dial_code: "+677", flag: "🇸🇧" },
  { code: "SO", dial_code: "+252", flag: "🇸🇴" },
  { code: "ZA", dial_code: "+27", flag: "🇿🇦" },
  { code: "KR", dial_code: "+82", flag: "🇰🇷" },
  { code: "SS", dial_code: "+211", flag: "🇸🇸" },
  { code: "ES", dial_code: "+34", flag: "🇪🇸" },
  { code: "LK", dial_code: "+94", flag: "🇱🇰" },
  { code: "SD", dial_code: "+249", flag: "🇸🇩" },
  { code: "SR", dial_code: "+597", flag: "🇸🇷" },
  { code: "SZ", dial_code: "+268", flag: "🇸🇿" },
  { code: "SE", dial_code: "+46", flag: "🇸🇪" },
  { code: "CH", dial_code: "+41", flag: "🇨🇭" },
  { code: "SY", dial_code: "+963", flag: "🇸🇾" },
  { code: "TW", dial_code: "+886", flag: "🇹🇼" },
  { code: "TJ", dial_code: "+992", flag: "🇹🇯" },
  { code: "TZ", dial_code: "+255", flag: "🇹🇿" },
  { code: "TH", dial_code: "+66", flag: "🇹🇭" },
  { code: "TL", dial_code: "+670", flag: "🇹🇱" },
  { code: "TG", dial_code: "+228", flag: "🇹🇬" },
  { code: "TO", dial_code: "+676", flag: "🇹🇴" },
  { code: "TT", dial_code: "+1-868", flag: "🇹🇹" },
  { code: "TN", dial_code: "+216", flag: "🇹🇳" },
  { code: "TR", dial_code: "+90", flag: "🇹🇷" },
  { code: "TM", dial_code: "+993", flag: "🇹🇲" },
  { code: "TV", dial_code: "+688", flag: "🇹🇻" },
  { code: "UG", dial_code: "+256", flag: "🇺🇬" },
  { code: "UA", dial_code: "+380", flag: "🇺🇦" },
  { code: "AE", dial_code: "+971", flag: "🇦🇪" },
  { code: "GB", dial_code: "+44", flag: "🇬🇧" },
  { code: "US", dial_code: "+1", flag: "🇺🇸" },
  { code: "UY", dial_code: "+598", flag: "🇺🇾" },
  { code: "UZ", dial_code: "+998", flag: "🇺🇿" },
  { code: "VU", dial_code: "+678", flag: "🇻🇺" },
  { code: "VE", dial_code: "+58", flag: "🇻🇪" },
  { code: "VN", dial_code: "+84", flag: "🇻🇳" },
  { code: "YE", dial_code: "+967", flag: "🇾🇪" },
  { code: "ZM", dial_code: "+260", flag: "🇿🇲" },
  { code: "ZW", dial_code: "+263", flag: "🇿🇼" },
];
