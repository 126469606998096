import React, { useEffect } from "react";
import { useFieldArray } from "react-hook-form";
import { FormInput, FormSelect } from "../inputs";
import { countries } from "../Settings/Organisation/country";

import { useTranslation } from "react-i18next";

const BillingAddress = ({ control, errors, register, billing_address }) => {
  const { t } = useTranslation();
  useEffect(() => {
    if (billing_address?.length === 0) {
      append({
        country: "",
        address: "",
        city: "",
        state: "",
        zipcode: "",
        phone: "",
        address_type: "billing",
      });
    }
  }, []);

  const { append, fields } = useFieldArray({
    control,
    name: "billing_address",
  });

  return (
    <>
      {fields.map((personData, index) => (
        <div key={`billing-${personData.key}`}>
          <div>
            <FormSelect
              control={control}
              {...register(`billing_address.${index}.country`)}
              label={t("country_region")}
              placeholder={t("select")}
              options={countries?.map((ele) => ({
                label: (
                  <>
                    <img
                      src={`https://cdn.jsdelivr.net/npm/svg-country-flags@1.2.10/svg/${ele.code?.toLowerCase()}.svg`}
                      className="mr1"
                      style={{
                        width: "2rem",
                        boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.4)",
                      }}
                    />{" "}
                    {ele.label}
                  </>
                ),
                value: ele.code,
                code: ele.value,
                country: ele.label,
              }))}
              hideCreateOption={true}
              errorMessage={
                errors.billing_address?.[index]?.["country"]?.["message"]
              }
              filterKey={"country"}
            />
          </div>

          <div className="pp-form-item">
            <FormInput
              control={control}
              {...register(`billing_address.${index}.address`)}
              label={t("address")}
              placeholder={t("street")}
              errorMessage={
                errors.billing_address?.[index]?.["address"]?.["message"]
              }
            />
          </div>

          <div className="pp-form-item">
            <FormInput
              control={control}
              {...register(`billing_address.${index}.city`)}
              label={t("city")}
              errorMessage={
                errors.billing_address?.[index]?.["city"]?.["message"]
              }
            />
          </div>

          <div className="pp-form-item">
            <FormInput
              control={control}
              {...register(`billing_address.${index}.state`)}
              label={t("state")}
              errorMessage={
                errors.billing_address?.[index]?.["state"]?.["message"]
              }
            />
          </div>

          <div className="pp-form-item">
            <FormInput
              control={control}
              {...register(`billing_address.${index}.zipcode`)}
              label="Zip"
              errorMessage={
                errors.billing_address?.[index]?.["zipcode"]?.["message"]
              }
            />
          </div>

          <div className="pp-form-item">
            <FormInput
              control={control}
              {...register(`billing_address.${index}.phone`)}
              label={t("phone")}
              errorMessage={
                errors.billing_address?.[index]?.["phone"]?.["message"]
              }
              number={true}
            />
          </div>

          {/* <div className="pp-form-item">
            <FormInput
              control={control}
              {...register(`billing_address.${index}.fax`)}
              label="Fax"
              errorMessage={errors.billing_address?.[index]?.['fax']?.['message']}
            />
          </div> */}
        </div>
      ))}
    </>
  );
};

export default BillingAddress;
