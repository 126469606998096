import { Col, message, Row, Typography } from "antd";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { app_loading } from "../../actions/auth";
import { get_currencies } from "../../actions/masterdata/masterdata";
import { apis } from "../../config/APIs";
import { gridApis } from "../../config/GridApis";
import usePayAxios from "../../config/useAxios";
import { amountFormat } from "../../utils";
import { FormInput, FormSelect } from "../inputs";

const { Title, Text } = Typography;

const ReviewDetails = () => {
  return (
    <div className="payment-details-container">
      <div className="payment-details-card">
        <Title level={4} className="header">
          Review payment details
        </Title>
        <Text className="sub-header">Transfer from Lokesh Kumar</Text>

        <Title level={2} className="amount">
          $24,484.00
        </Title>
        <Text className="info-text">
          Transfers to Treasury should arrive between September 10-11, 2024.
        </Text>

        <div className="details-card" bordered={false}>
          <Row className="detail-row">
            <Col span={12}>
              <Text className="label">From</Text>
            </Col>
            <Col span={12}>
              <Text>Zenus</Text>
            </Col>
          </Row>

          <Row className="detail-row">
            <Col span={12}>
              <Text className="label">Account</Text>
            </Col>
            <Col span={12}>
              <Text>Lokesh Kumar (Checking ****4535)</Text>
            </Col>
          </Row>

          <Row className="detail-row">
            <Col span={12}>
              <Text className="label">Initiated on</Text>
            </Col>
            <Col span={12}>
              <Text>September 10, 2024</Text>
            </Col>
          </Row>

          <Row className="detail-row">
            <Col span={12}>
              <Text className="label">Transfer to</Text>
            </Col>
            <Col span={12}>
              <Text>Zenus (Ruby Tuesday)</Text>
            </Col>
          </Row>

          <Row className="detail-row">
            <Col span={12}>
              <Text className="label">Account number</Text>
            </Col>
            <Col span={12}>
              <Text>Treasury — 2938744</Text>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

const Transfer = ({ setOpenAddNew, cb, from }) => {
  const dispatch = useDispatch();
  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
    watch,
    setValue,
    reset,
  } = useForm({
    account_type: "SAVINGS",
    currency_code: "",
    residency_country_code: "US",
    tax_residency_country_code: "US",
    jurisdiction_country_code: "US",
    given_name: "",
  });
  const location = useLocation();
  const history = useHistory();
  const { onCall } = usePayAxios({
    api: gridApis.fetchAccountTypes,
    method: "get",
  });
  const { onCall: addAcc, loading } = usePayAxios({
    api: apis.transferAmount,
    method: "post",
  });
  const bankAcc = useSelector(
    (state) => state?.z_accs?.accounts?.data?.data || []
  );
  const onSubmit = (data) => {
    data.currency_code = bankAcc?.find(
      (ele) => ele.id === data.sender_account_id
    )?.currency_code;
    data.recipient_amount = data.amount;
    data.sender_amount = data.amount;
    data.exchange_fee = 0;
    data.exchange_rate = 0;
    dispatch(app_loading(true));
    addAcc({ data })
      .then((res) => {
        if (res?.error === false) {
          message.success(<span className="messageText">{res?.message}</span>);
          dispatch(app_loading(false));
          setOpenAddNew(false);
          reset();
          cb();
        }
        if (res?.error) {
          message.error(<span className="messageText">{res?.message}</span>);
        }
      })
      .catch((err) => {
        dispatch(app_loading(false));
        message.error(
          <span className="messageText">{err?.response?.data?.message}</span>
        );
      });
  };

  useEffect(() => {
    onCall({});
    dispatch(get_currencies());
    if (location?.state?.from || from) {
      setValue("sender_account_id", location?.state?.from ?? from);
    }
    if (location?.state?.to) {
      setValue("recipient_account_id", location?.state?.to);
    }
    return () => {
      reset();
    };
  }, []);

  const from_acc = watch("sender_account_id");
  const amt = watch("amount");
  const to_acc = watch("recipient_account_id");
  const from_acnt = bankAcc?.find((ele) => ele.id === from_acc);
  const to_acnt = bankAcc?.find((ele) => ele.id === to_acc);
  const isInsufficient = from_acnt?.balance?.balance < amt;
  return (
    <div>
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="pp-form-item">
            <FormInput
              control={control}
              required
              {...register("amount", {
                required: `Amount is required`,
                validate: (value) =>
                  parseFloat(value) > 0 || "Amount must be greater than zero",
              })}
              errors={errors}
              label={"Amount"}
              prefix={"$"}
            />
          </div>
          <div className="pp-form-item">
            <FormSelect
              inline
              label={"From Account"}
              control={control}
              options={bankAcc?.map((ele) => ({
                label: `${ele.account_name} - ${ele.account_number}`,
                value: ele.id,
              }))}
              placeholder={"Select From Account"}
              required={true}
              {...register("sender_account_id", {
                required: "Please select from account.",
              })}
              errors={errors}
              hideCreateOption={true}
            />
            {from_acnt?.id && (
              <p className="mt1">
                Available Balance: $ {amountFormat(from_acnt?.balance?.balance)}
              </p>
            )}
          </div>
          <div className="pp-form-item">
            <FormSelect
              inline
              label={"To Account"}
              control={control}
              options={bankAcc
                ?.filter((ele) => ele.id !== from_acc)
                ?.map((ele) => ({
                  label: `${ele.account_name} - ${ele.account_number}`,
                  value: ele.id,
                }))}
              placeholder={"Select To Account"}
              required={true}
              {...register("recipient_account_id", {
                required: "Please select To account.",
              })}
              errors={errors}
              hideCreateOption={true}
            />
            {to_acnt?.id && (
              <p className="mt1">
                Available Balance: $ {amountFormat(to_acnt?.balance?.balance)}
              </p>
            )}
          </div>
          <div className="pp-form-item">
            <FormInput
              control={control}
              {...register("detail_name")}
              errors={errors}
              label={"Notes"}
            />
          </div>
          <div>
            <button
              type="button"
              className="back-btn mr2"
              onClick={() => {
                setOpenAddNew(false);
                history.replace(location.pathname);
              }}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="send-btn mt3"
              disabled={loading || isInsufficient}
            >
              Send
            </button>
          </div>
          {isInsufficient && (
            <div className="errorMsg">Insufficient Balance</div>
          )}
        </form>
      </div>
    </div>
  );
};

export default Transfer;
