import { ActionTypesFactory } from "../../utils";

export const Local_Variables = "LOCAL_VARIABLES";
export const ChangeLanguage = "CHANGE_LANGUAGE";
export const Is_Login = "IS_LOGIN";
export const LOADING = "Loading";
export const Collapsed = "Collapsed";
export const Login = ActionTypesFactory("Login", "Auth");
export const Logout = ActionTypesFactory("Logout", "Auth");
export const ForgotPassword = ActionTypesFactory("ForgotPassword", "Auth");
export const ResetPassword = ActionTypesFactory("ResetPassword", "Auth");
export const ChangePassword = ActionTypesFactory("ChangePassword", "Auth");
export const Notifications = ActionTypesFactory("Notifications", "Auth");
export const RequestDemo = ActionTypesFactory("RequestDemo", "Auth");

export const notifications = (payload) => {
  return {
    type: Notifications.REQUEST,
    payload,
  };
};
export const changeLanguage = (payload) => {
  return {
    type: ChangeLanguage,
    payload,
  };
};

export const requestDemo = (payload) => {
  return {
    type: RequestDemo.REQUEST,
    payload,
  };
};

export const login = (payload) => {
  return {
    type: Login.REQUEST,
    payload,
  };
};

export const collapsed = (payload) => {
  return {
    type: Collapsed,
    payload,
  };
};

export const app_loading = (payload) => {
  return {
    type: LOADING,
    payload,
  };
};

export const logout = (payload) => {
  return {
    type: Logout.REQUEST,
    payload,
  };
};

export const resetPassword = (payload) => {
  return {
    type: ResetPassword.REQUEST,
    payload,
  };
};

export const forgotPassword = (payload) => {
  return {
    type: ForgotPassword.REQUEST,
    payload,
  };
};

export const changePassword = (payload) => {
  return {
    type: ChangePassword.REQUEST,
    payload,
  };
};

// export const commits = (payload) => {
//   let str = "";
//   Object.keys(payload).forEach((item) => {
//     str = str + `${item}:${payload[item]} `;
//   });
//   payload = encodeURIComponent(str);
//   return {
//     type: Commits.REQUEST,
//     payload,
//   };
// };
