import { message } from "antd";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { gridApis } from "../../config/GridApis";
import usePayAxios from "../../config/useAxios";
import { FormInput, FormSelect } from "../inputs";

const AddAccount = ({ setOpenAddNew, cb }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      account_type: "VIRTUAL",
      currency_code: "USD",
      residency_country_code: "US",
      tax_residency_country_code: "US",
      jurisdiction_country_code: "US",
      given_name: "",
    },
  });
  const { onCall } = usePayAxios({
    api: gridApis.fetchAccountTypes,
    method: "get",
  });
  const { onCall: addAcc, loading } = usePayAxios({
    api: gridApis.createAccount,
    method: "post",
  });
  const currenciesRes = useSelector((state) =>
    state?.currencies?.data?.data?.length
      ? state?.currencies?.data?.data?.map((ele) => ({
          label: (
            <span
              style={{
                color: "#212121",
                fontSize: 15,
              }}
            >
              <img
                src={`https://cdn.jsdelivr.net/npm/svg-country-flags@1.2.10/svg/${ele?.country_code?.toLowerCase()}.svg`}
                className="mr1"
                width={25}
                style={{
                  boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.4)",
                }}
              />
              {`${ele?.iso_code}`}
            </span>
          ),
          value: ele.id,
          iso_code: ele.iso_code,
          country_code: ele.country_code,
        }))
      : []
  );
  const onSubmit = (data) => {
    addAcc({
      data,
      params: {
        application_type: "LEGAL",
      },
    }).then((res) => {
      if (res?.error === false) {
        cb?.();
        message.success(<span className="messageText">{res.message}</span>);
        setOpenAddNew(false);
      }
    });
  };

  useEffect(() => {
    onCall({});
    setValue("account_type", "VIRTUAL");
    setValue("currency_code", "USD");
  }, []);
  useEffect(() => {
    if (currenciesRes?.length)
      setValue(
        "iso_code",
        currenciesRes?.find((ele) => ele.country_code === "US")?.value
      );
  }, [currenciesRes]);
  const account_type = watch("account_type");

  return (
    <div>
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="pp-form-item">
            <FormSelect
              inline
              label={"Account Type"}
              control={control}
              options={[{ label: "Virtual", value: "VIRTUAL" }]}
              placeholder={"Select Account Type"}
              required={true}
              {...register("account_type", {
                required: "Please select account type.",
              })}
              errors={errors}
              hideCreateOption={true}
              value={account_type}
            />
          </div>
          <div className="pp-form-item">
            <FormSelect
              inline
              label={"Currency"}
              control={control}
              placeholder={"Select Currency"}
              required={true}
              options={currenciesRes?.filter(
                (ele) => ele.country_code === "US"
              )}
              {...register("iso_code", {
                required: "Please select the method.",
                onChange: (e) => {
                  const curr = currenciesRes?.find(
                    (ele) => ele.value === e.target.value
                  );
                  setValue("iso_code", e.target.value);
                  setValue("currency_code", curr?.iso_code);
                },
              })}
              errors={errors}
              hideCreateOption={true}
              filterKey={"iso_code"}
            />
            {/* <p>1 business day</p> */}
          </div>
          <div className="pp-form-item">
            <FormInput
              control={control}
              required
              {...register("given_name", {
                required: `Account Name is required`,
              })}
              errors={errors}
              label={"Account Name"}
            />
          </div>
          <div>
            <button
              type="button"
              className="back-btn mr2"
              onClick={() => {
                setOpenAddNew(false);
              }}
            >
              Cancel
            </button>
            <button type="submit" disabled={loading} className="send-btn mt3">
              {loading ? "Creating" : "Create"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddAccount;
