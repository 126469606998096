import React, { useEffect, useState } from "react";
import inv from "./assets/inv_icon.png";
import u_edit from "./assets/u_edit.png";
import clock from "./assets/clock.png";
import up from "./assets/up.png";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { FormInput, FormSelect } from "../../inputs";
import { Col, message, Row, Switch, Upload } from "antd";
import DateInput from "../../inputs/DateInput";
import { useSelector } from "react-redux";
import moment from "moment";
import CreateManageBeneficiary from "../../Common/CreateManageBeneficiary";
import { apis } from "../../../config/APIs";
import useDocumentAxios from "../../../config/useDocument";
import { v4 as uuidv4 } from "uuid";
import { getFileExtension } from "../../../utils";
import ButtonUpload from "../../inputs/ButtonUpload";
import rclose from "../SendMoney/assets/close.png";
import pdfIcon from "../../../assets/icons/pdf_file_icon.png";

const CreateRequest = ({
  next,
  register,
  handleSubmit,
  setDBAcc,
  control,
  errors,
  watch,
  setValue,
  setMainData,
  mainData,
  setCAcc,
  cancel,
  step,
}) => {
  const [openAddNew, setOpenAddNew] = useState(false);
  const [recpientId, setRecipientId] = useState("");
  const [listFiles, setListFiles] = useState([]);
  const { t } = useTranslation();
  const bnkDetails = useSelector((state) => state?.customers?.all?.data?.data);
  const BeneficiaryBankDetails = useSelector((state) =>
    state?.customers?.all?.data?.data?.map((ele) => ({
      ...ele,
      label:
        ele?.beneficiary_name + "-" + ele?.bank_accounts?.[0]?.account_number,
      value: ele?.bank_accounts?.[0]?.beneficiary_id,
    }))
  );
  const data1 = useSelector(
    (state) => state?.z_accs?.accounts?.data?.data || []
  );

  const onSubmit = (data) => {
    setMainData({ ...data, due_date: data?.due_date?.format("YYYY-MM-DD") });
    next();
  };

  const { onCall: DocumentDelete } = useDocumentAxios({
    api: apis.deleteDocument,
    method: "delete",
    baseURL: "document",
  });

  const {
    onCall: DocumentCreate,
    reset: DocumentCreateReset,
    loading: createLoading,
  } = useDocumentAxios({
    api: apis.createDocument,
    method: "post",
    baseURL: "document",
  });

  const getBase64 = async (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const handleFileUpload = async (fieldName, uploadFile) => {
    let uniqueNo = uuidv4();

    const formData = new FormData();
    formData.append("files", uploadFile.file);
    formData.append("name", fieldName);
    formData.append("record_type", "Document");
    formData.append("record_id", uniqueNo);

    const attachId = await DocumentCreate({
      data: formData,
      headers: {
        "Content-Type": "multipart/formdata",
      },
    });
    if (attachId?.error == false) {
      await getBase64(uploadFile.file, (url) => {
        setListFiles((prevData) => {
          const newData = { ...prevData };
          const currentArray = newData[fieldName] || [];

          // Add new item
          currentArray.push({
            fieldName: fieldName,
            attachment_id: attachId?.data[0],
            uuid: uniqueNo,
            url: url,
            uploadFileName: uploadFile?.file?.name,
          });

          newData[fieldName] = currentArray?.filter(
            (items) => items?.attachment_id
          );
          setValue(
            fieldName,
            newData[fieldName]?.map((ele) => ele.attachment_id)
          );
          return newData;
        });
      });
      DocumentCreateReset();
    }
  };

  const handleFileDelete = async (targetKey, targetValue, isRemove = false) => {
    const deleteData = await DocumentDelete({
      data: {
        ids: [targetValue],
      },
      params: {
        record_id: getObjectByFieldNameAndValue(targetKey, targetValue)?.uuid,
      },
      headers: {
        "Content-Type": "multipart/formdata",
      },
    });

    if (deleteData?.error == false) {
      if (!isRemove) {
        setValue(targetKey, "");
      }
      setListFiles((prevData) => {
        return {
          ...prevData,
          [targetKey]: prevData[targetKey]?.filter(
            (ele) => ele.attachment_id !== targetValue
          ),
        };
      });
    }
  };

  const getObjectByFieldNameAndValue = (targetKey, targetValue) => {
    return listFiles[targetKey]?.find(
      (ele) => ele.attachment_id === targetValue
    );
  };

  const handleBeforeUpload = async (file) => {
    const fileName = getFileExtension(file?.name);
    if (["png", "jpg", "jpeg", "pdf"]?.includes(fileName)) {
    } else {
      message.error(
        <span className="messageText">{`Please Upload ${[
          "png",
          "jpg",
          "jpeg",
          "pdf",
        ]?.join(" or ")} files`}</span>
      );
      return Upload.LIST_IGNORE;
    }
    return false;
  };

  useEffect(() => {
    if (mainData?.beneficiary_id) {
      setValue("beneficiary_id", mainData?.beneficiary_id);
      setValue("amount", mainData?.amount);
      setValue("due_date", moment(mainData?.due_date));
      setValue("ourparty_account_id", mainData?.ourparty_account_id);
      setValue("ourparty_display_name", mainData?.ourparty_display_name);
      setValue("enable_card_payments", mainData?.enable_card_payments);
      setValue("description", mainData?.description);
    }
  }, []);

  useEffect(() => {
    if (recpientId) {
      const benAcc = bnkDetails?.find((ele) => ele?.id === recpientId);
      if (benAcc) {
        setValue("beneficiary_id", benAcc?.bank_accounts?.[0]?.beneficiary_id);
        setRecipientId("");
      }
    }
  }, [recpientId, bnkDetails]);

  const due_date = watch("due_date");
  const enable_card_payments = watch("enable_card_payments");
  return (
    <div>
      <CreateManageBeneficiary
        isVisible={openAddNew}
        onClose={() => {
          setOpenAddNew(false);
        }}
        createSet={(data) => {
          setRecipientId(data?.id ? data?.id : data);
        }}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <h2>Create a payment request</h2>

        {/* <div className="sending-inv">
          <img src={inv} width={40} />
          <p>Sending an invoice? Try Zenus Invoicing</p>
        </div> */}

        <div className="contact-details">
          <h4>Contact details</h4>
          <p>
            Select an existing contact or create one by entering their name and
            email below.
          </p>
        </div>

        <div className="form-body">
          <div className="pp-form-item">
            <FormSelect
              inline
              {...register("beneficiary_id", {
                onChange: (e) => {
                  const benAcc = BeneficiaryBankDetails?.find(
                    (ele) =>
                      ele?.bank_accounts?.[0]?.beneficiary_id === e.target.value
                  );
                  setCAcc(benAcc);
                },
              })}
              label={"Send payment request to"}
              control={control}
              options={BeneficiaryBankDetails}
              hideCreateOption={true}
              placeholder={"Select"}
              required
              errors={errors}
            />
          </div>
          <span
            className="edit-link"
            onClick={() => {
              setOpenAddNew(true);
            }}
          >
            <img src={u_edit} width={18} /> Add Recipient
          </span>
          <div className="contact-details mb2">
            <h4>Request details</h4>
          </div>
          <Row gutter={[16]}>
            <Col span={12}>
              <FormInput
                control={control}
                prefix={"$"}
                label={"Amount"}
                {...register("amount")}
                placeholder={"Enter Amount"}
                errors={errors}
                number={true}
              />
            </Col>
            <Col span={12}>
              <DateInput
                control={control}
                {...register("due_date")}
                errors={errors}
                required={true}
                label={"Due Date"}
                isTime={false}
                dateFormat={["DD/MM/YYYY"]}
              />
              {/* <p className="helper-text text-right">30 days from now</p> */}
            </Col>
          </Row>
          <div className="pp-form-item">
            <FormInput
              inline
              {...register("ourparty_display_name", {})}
              label={"Name to show on the request"}
              control={control}
              hideCreateOption={true}
              placeholder={"Enter"}
              errors={errors}
            />
          </div>
          <div className="pp-form-item">
            <FormSelect
              inline
              {...register("ourparty_account_id", {
                onChange: (e) => {
                  setDBAcc(data1?.find((ele) => ele.id === e.target.value));
                },
              })}
              label={"Destination Account"}
              control={control}
              options={data1?.map((ele) => ({
                label: `${ele?.account_name} - ${ele?.account_number}`,
                value: ele?.id,
              }))}
              hideCreateOption={true}
              placeholder={"Select"}
              errors={errors}
            />
            <p className="helper-text">
              Incoming payments are routed through a secure receiving account so
              your account details remain private.
            </p>
          </div>
          {/* <div className="expires">
            <img src={clock} width={15} />
            Payment link expires on{" "}
            {due_date ? due_date?.format("MMMM DD, YYYY") : ""}
          </div> */}

          <div className="contact-details ">
            <h4>Payment methods</h4>
            <p>
              Payments made via manual ACH, wire, or Pay with Zenus are accepted
              by default and always free.
            </p>
          </div>
          <div className="pp-form-item  p-method">
            <Switch
              checked={enable_card_payments}
              onChange={(e) => {
                console.log({ e });

                setValue("enable_card_payments", e);
              }}
            />{" "}
            <span className="title">Accept card payments.</span>
            <span className="helper-text">
              {" "}
              You’ll pay a<span className="link">Stripe fee.</span>
            </span>
          </div>
          <div className="contact-details">
            <h4>Notes / attachments (optional)</h4>
            <p>These will be visible to the sender via the payment link.</p>
          </div>
          <div className="pp-form-item notes">
            <FormInput
              control={control}
              prefix={"$"}
              label={"Notes"}
              {...register("description")}
              placeholder={"Notes"}
              errors={errors}
              type={"textarea"}
            />
          </div>
          <div className="pp-form-item attachments">
            <label>Attachments</label>
            <div className="additional" style={{ marginTop: "40px" }}>
              <ButtonUpload
                control={control}
                {...register(`documents`, {
                  onChange: (e) => {
                    handleFileUpload("documents", e.target.value);
                  },
                })}
                beforeUpload={handleBeforeUpload}
                errors={errors}
                label={t("attach_payments")}
                multiple={false}
                loading={createLoading}
              />
              <div className="imgs-wrap">
                {listFiles["documents"]?.map((ele) => {
                  return (
                    <div
                      style={{
                        position: "relative",
                      }}
                    >
                      <div className="img">
                        <img
                          src={
                            getFileExtension(ele.uploadFileName) === "pdf"
                              ? pdfIcon
                              : ele.url
                          }
                        />
                        {/* <img src={eye} alt="" className="file-eye" /> */}
                      </div>
                      <img
                        src={rclose}
                        alt=""
                        className="file-close"
                        onClick={() => {
                          handleFileDelete("documents", ele.attachment_id);
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="action-btns">
          <button type="button" className="back-btn" onClick={cancel}>
            Cancel
          </button>
          <button className="send-btn">Next</button>
        </div>
      </form>
    </div>
  );
};

export default CreateRequest;
