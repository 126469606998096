import { QuestionCircleOutlined } from "@ant-design/icons";
import { Select, Tooltip } from "antd";
import { useEffect } from "react";
import { customers } from "../../actions/customers/customers";
import { useDispatch } from "react-redux";
import { vendorsByName } from "../../actions/purchases";
import { useState } from "react";

const CardDashboard = ({
  isCustomers,
  isVendor,
  selectPlaceholder,
  children,
  title,
  tooltipText,
  style,
  className,
  showDD,
  options = [],
  onChange,
  hasWriteAccess,
  pStyle
}) => {
  const dispatch = useDispatch();
  const [ddVal, setDdVal] = useState("");
  useEffect(() => {
    if (isCustomers) {
      dispatch(
        customers({
          company_id: localStorage.getItem("company_id"),
          type: "customer",
          status: "active",
        })
      );
    } else if (isVendor) {
      dispatch(
        vendorsByName({
          type: "vendor",
          status: "active",
        })
      );
    }
  }, [isCustomers, isVendor]);
  return (
    <div className={`dashboard-box ${className}`} style={pStyle}>
      {!title ? null : (
        <div className="dashboard-title">
          <span>
            <span className="card-title">{title}</span>

            {tooltipText && (
              <span className="help-icon">
                <Tooltip placement="top" title={tooltipText}>
                  <QuestionCircleOutlined />
                </Tooltip>
              </span>
            )}
          </span>
          {showDD && (
            <Select
              placeholder={selectPlaceholder}
              disabled={!hasWriteAccess}
              value={ddVal}
              onSearch={(val) => {
                if (isCustomers) {
                  dispatch(
                    customers({
                      company_id: localStorage.getItem("company_id"),
                      type: "customer",
                      beneficiary_name: val,
                      status: "active",
                    })
                  );
                } else if (isVendor) {
                  dispatch(
                    vendorsByName({
                      company_id: localStorage.getItem("company_id"),
                      beneficiary_name: val,
                      type: "vendor",
                      status: "active",
                    })
                  );
                }
              }}
              onChange={(val) => {
                setDdVal(val);
                onChange(val);
              }}
              options={[{ label: "All", value: "" }, ...options]}
              style={{
                width: "10rem",
                textAlign: "right",
              }}
            />
          )}
        </div>
      )}

      <div className="dashboard-content" style={style}>
        {children}
      </div>
    </div>
  );
};

export default CardDashboard;
