import axios from "axios";
const APP_BASE_URL = process.env.REACT_APP_APP_BASE_URL;

const endpointPayExpenseBeta = axios.create({
  baseURL: APP_BASE_URL,
});

endpointPayExpenseBeta.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    config.headers.Authorization =
      "Bearer " + localStorage.getItem("tokenPayApi");
    config.headers.device_type = "web";
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

endpointPayExpenseBeta.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data

    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error?.response?.status === 401) {
      localStorage.clear();
      // window.location.href = "/";
    }
    return Promise.reject(error);
  }
);

export default endpointPayExpenseBeta;
